import React, { useEffect, useState } from "react";
import "../Login.css";
import newcareerlogo from "../../../img/newcareerlogo.png";
import BusinessCenterOutlinedIcon from "@mui/icons-material/BusinessCenterOutlined";
import BusinessOutlinedIcon from "@mui/icons-material/BusinessOutlined";
import newforget from "../../../img/newforget.png";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  Button,
  FormHelperText,
  IconButton,
  TextField,
  Typography,
  Tooltip,
  Card,
  useMediaQuery,
} from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Link, useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import useAxios from "../../../api/useAxios";
import ErrorSharpIcon from "@mui/icons-material/ErrorSharp";
import { ROUTINGDATA } from "../../ROUTINGDATA";

function ForgotPassword() {
  const isMobile = useMediaQuery("(max-width:600px)");
  const axiosData = useAxios();
  const [open, setOpen] = useState(false);
  const [Errormessage, setErrormessage] = useState({
    heading: "Invalid Email ID !",
    message: "Please provide a Registered Email ID.",
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      email_id: "",
    },
    validationSchema: Yup.object({
      email_id: Yup.string().required("Required"),
    }),
    onSubmit: async (values) => {
      const email_id = values.email_id;
      try {
        const res = await axiosData.post(`users/email`, { email_id: email_id });
        if (res.data === "") {
          setErrormessage({
            ...Errormessage,
            heading: "Invalid Email ID !",
            message: "Please provide a Registered Email ID.",
          });
          handleClickOpen();
        } else if (res.data.status === "InActive") {
          setErrormessage({
            ...Errormessage,
            heading: "InActive Profile",
            message: "Your Profile is InActive Please contact Administrative",
          });
          handleClickOpen();
        } else if (res.data.status === "Active") {
          await axiosData.post("password", { email_id });
          navigate(`${ROUTINGDATA.LAND}${ROUTINGDATA.OtpforPassword}`, {
            state: { email_id: values.email_id },
          });
        } else if (res.data.message === "EmailID not Matched") {
          setErrormessage({
            ...Errormessage,
            heading: "Invalid Email ID !",
            message: "Please provide a Registered Email ID.",
          });
          handleClickOpen();
        }
      } catch (err) {
        if (err.message === "Request failed with status code 204") {
          console.log("err", err);
          setErrormessage({
            ...Errormessage,
            heading: "Invalid Email ID !",
            message: "Please provide a Registered Email ID.",
          });
          handleClickOpen();
        } else {
          console.log(err);
        }
      }
    },
  });
  useEffect(() => {
    window.history.forward();
  }, []);
  return (
    <>
      {/* <div id="forgotpasswordpage" className="forgotpasswordpage">
        <div className="forgotpasswordpage-logo-top">
          <div className="forgotpasswordimg">
            <img src={logo}  onClick={() => navigate(`${ROUTINGDATA.LAND}${ROUTINGDATA.LOGIN}`)} />
          </div>
        </div>
        <div className="forgotpassword-input-box">
          <form onSubmit={formik.handleSubmit}>
            <div className="login-reg">
              <p>
                Welcome to{" "}
                <span>
                <text>{process.env.REACT_APP_WEBSITE_NAME_LOGIN1}</text>{process.env.REACT_APP_WEBSITE_NAME_LOGIN2}
                </span>
              </p>
            </div>
            <Divider />
            <div className="login-space">
              <div className="login-title">
                <h2>Forgot Password</h2>
              </div>
              <div className="forgotpassword-contect">
                <p>
                  Enter your email and we'll send you otp to your Registered
                  email to verify
                </p>
              </div>
              <div className="space-forgotpassword">
                <div className="login-textfield-1">
                  <label>Email ID</label>
                  <TextField
                    id="email_id"
                    variant="outlined"
                    size="small"
                    name="email_id"
                    onChange={formik.handleChange}
                    value={formik.values.email_id}
                    onBlur={formik.handleBlur}
                    fullWidth
                  />
                  <FormHelperText>
                    {formik.touched.email_id && formik.errors.email_id ? (
                      <Typography sx={{ color: "red", fontSize: "13px" }}>
                        {formik.errors.email_id}
                      </Typography>
                    ) : (
                      <Typography
                        sx={{ fontSize: "13px", visibility: "hidden" }}
                      >
                        none
                      </Typography>
                    )}
                  </FormHelperText>
                </div>

                <div className="loginbtn">
                  <Button variant="contained" fullWidth type="submit">
                    next
                  </Button>
                </div>
                <div className="back-to-loginpage">
                  <ArrowBackIosIcon fontSize="13px" sx={{ color: "#2b7695" }} />{" "}
                  <text style={{cursor:"pointer"}}
                   onClick={() => navigate(`${ROUTINGDATA.LAND}${ROUTINGDATA.LOGIN}`)}
                  >
                    Back to Login
                  </text>
                </div>
              </div>
            </div>
          </form>
        </div>
        <Dialog
          open={open}
          sx={{
            "& .MuiPaper-root": {
              backgroundColor: "#339fcd",
              borderRadius: "10px",
              padding: "10px 10px",
            },
          }}
        >
          <Box>
            <DialogTitle sx={{ fontFamily: "Poppins-SemiBold" }}>
              <div
                style={{ display: "flex", gap: "3px", alignItems: "center" }}
              >
                <ErrorSharpIcon
                  sx={{ mt: -0.3, color: "crimson" }}
                  fontSize="large"
                />
                <Typography variant="h5" sx={{ ml: 0.3 }}>
                  {Errormessage.heading}
                </Typography>
              </div>
            </DialogTitle>
            <DialogContent>
              <DialogContentText
                sx={{ fontFamily: "Poppins-Medium", color: "crimson" }}
              >
                {Errormessage.message}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                variant="outlined"
                sx={{
                  textTransform: "lowercase",
                  color: "#ed1067",
                  borderColor: "#ed1067",
                  fontFamily: "Poppins-Medium",
                  "&:hover": {
                    textTransform: "lowercase",
                    color: "white",
                    backgroundColor: "#ed1067",
                    borderColor: "#ed1067",
                    fontFamily: "Poppins-Medium",
                  },
                }}
                onClick={() => {
                  handleClose();
                }}
              >
                Ok
              </Button>
            </DialogActions>
          </Box>
        </Dialog>
      </div> */}
      <div
        style={{
          height: "100vh",
          width: "100%",
          display: "flex",
          flexDirection: "row",
          overflow: "hidden",
        }}
      >
        <div
          className="first-div"
          style={{
            height: "100vh",
            width: "50%",
            padding: "80px",
            paddingTop: "40px",
          }}
        >
          <Link to="/login">
            <IconButton>
              <ArrowBackIcon />
            </IconButton>
          </Link>
          <div
            style={{ paddingTop: "60px", paddingLeft: isMobile ? "" : "42px" }}
          >
            <form onSubmit={formik.handleSubmit}>
              <img src={newcareerlogo} alt="logo" />
              <Typography
                style={{
                  font: "Inter",
                  fontSize: "27.78px",
                  fontWeight: "500",
                  lineHeight: "34.72px",
                  paddingTop: "30px",
                }}
              >
                Forget Password
              </Typography>
              <Typography
                style={{
                  font: "Inter",
                  fontSize: "13.89px",
                  fontWeight: "400",
                  lineHeight: "20.83px",
                  color: " #5E6670",
                  paddingTop: "10px",
                }}
              >
                Go back to
                <Link
                  to="/login"
                  style={{
                    color: "#0A65CC",
                    font: "Inter",
                    fontWeight: "500",
                    paddingLeft: "3px",
                  }}
                >
                  Sign In
                </Link>
              </Typography>
              <Typography
                style={{
                  font: "Inter",
                  fontSize: "13.89px",
                  fontWeight: "400",
                  lineHeight: "20.83px",
                  color: " #5E6670",
                  paddingTop: "10px",
                }}
              >
                Don’t have an account ?
                <Link
                  to="/JobSeekerSignUp"
                  style={{
                    color: "#0A65CC",
                    font: "Inter",
                    fontWeight: "500",
                    paddingLeft: "5px",
                  }}
                >
                  Create Account
                </Link>
              </Typography>
              <div>
                <div className="login-textfield">
                  <div className="login-textfield-1">
                    <Tooltip title="Enter your Email" arrow>
                      <TextField
                        style={{ paddingTop: "50px", width: "85%" }}
                        id="email_id"
                        placeholder="Email address"
                        variant="outlined"
                        name="email_id"
                        onChange={formik.handleChange}
                        value={formik.values.email_id}
                        onBlur={formik.handleBlur}
                        fullWidth
                      />
                    </Tooltip>
                    <FormHelperText>
                      {formik.touched.email_id && formik.errors.email_id ? (
                        <Typography sx={{ color: "red", fontSize: "13px" }}>
                          {formik.errors.email_id}
                        </Typography>
                      ) : (
                        <Typography
                          sx={{ fontSize: "13px", visibility: "hidden" }}
                        >
                          none
                        </Typography>
                      )}
                    </FormHelperText>
                  </div>
                </div>
                <div className="loginbtn">
                  <Button
                    style={{
                      backgroundColor: "#7C00DE",
                      color: "white",
                      textTransform: "capitalize",
                      height: "48.78px",
                      width: "85%",
                    }}
                    fullWidth
                    type="submit"
                  >
                    Reset Password →
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div
          className="second-div"
          style={{
            height: "100vh",
            width: "50%",
            position: "relative",
            overflow: "hidden",
          }}
        >
          <div
            style={{
              height: "100%",
              width: "100%",
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              overflow: "hidden",
            }}
          >
            <img
              src={newforget}
              alt="forgot"
              style={{
                height: "110%",
                width: "110%",
                objectFit: "cover",
                transform: "rotate(4deg)",
                position: "absolute",
                top: "-5%",
                right: "-12%",
                zIndex: 1,
              }}
            />
            <div
              style={{
                height: "100%",
                width: "100%",
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                background:
                  "linear-gradient(180deg, rgba(122, 96, 142, 0.345) 0%, rgba(59, 7, 100, 0.69) 100%)",
                zIndex: 2,
                pointerEvents: "none",
              }}
            />
          </div>
          <div
            style={{
              position: "absolute",
              bottom: "20%",
              left: "10%",
              color: "white",
              fontSize: "1.5em",
              fontWeight: "bold",
              textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)",
              zIndex: 3,
            }}
          >
            <Typography
              style={{
                font: "Inter",
                fontSize: "30px",
                fontWeight: "500",
                lineHeight: "36px",
              }}
            >
              Over 1,75,324 candidates
            </Typography>
            <Typography
              style={{
                font: "Inter",
                fontSize: "30px",
                fontWeight: "500",
                lineHeight: "36px",
              }}
            >
              waiting for good employers.
            </Typography>
            <div
              style={{
                display: "flex",
                gap: "50px", // Space between the cards
                marginTop: "20px", // Space between text and cards
                alignItems: "center",
              }}
            >
              <div style={{ textAlign: "center" }}>
                <Card
                  style={{
                    backgroundColor: "rgba(255, 255, 255, 0.2)",
                    color: "white",
                    width: "60px",
                    height: "48px",
                    padding: "12px 0px 0px 0px",
                    borderRadius: "6px",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <BusinessCenterOutlinedIcon style={{ fontSize: "40px" }} />
                </Card>
                <Typography
                  style={{
                    font: "Inter",
                    fontSize: "15px",
                    fontWeight: "500",
                    lineHeight: "16px",
                    marginTop: "8px",
                    color: "white",
                  }}
                >
                  1,75,324
                </Typography>
                <Typography
                  style={{
                    font: "Inter",
                    fontSize: "15px",
                    fontWeight: "500",
                    lineHeight: "16px",
                    marginTop: "8px",
                    color: "white",
                  }}
                >
                  Live Jobs
                </Typography>
              </div>
              <div style={{ textAlign: "center" }}>
                <Card
                  style={{
                    backgroundColor: "rgba(255, 255, 255, 0.2)",
                    color: "white",
                    width: "60px",
                    height: "48px",
                    padding: "12px 0px 0px 0px",
                    borderRadius: "6px",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <BusinessOutlinedIcon style={{ fontSize: "40px" }} />
                </Card>
                <Typography
                  style={{
                    font: "Inter",
                    fontSize: "12px",
                    fontWeight: "500",
                    lineHeight: "16px",
                    marginTop: "8px",
                    color: "white",
                  }}
                >
                  97,354
                </Typography>
                <Typography
                  style={{
                    font: "Inter",
                    fontSize: "15px",
                    fontWeight: "500",
                    lineHeight: "16px",
                    marginTop: "8px",
                    color: "white",
                  }}
                >
                  Companies
                </Typography>
              </div>
              <div style={{ textAlign: "center" }}>
                <Card
                  style={{
                    backgroundColor: "rgba(255, 255, 255, 0.2)",
                    color: "white",
                    width: "60px",
                    height: "48px",
                    padding: "12px 0px 0px 0px",
                    borderRadius: "6px",

                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <BusinessCenterOutlinedIcon style={{ fontSize: "40px" }} />
                </Card>
                <Typography
                  style={{
                    font: "Inter",
                    fontSize: "12px",
                    fontWeight: "500",
                    lineHeight: "16px",
                    marginTop: "8px",
                    color: "white",
                  }}
                >
                  7,532
                </Typography>
                <Typography
                  style={{
                    font: "Inter",
                    fontSize: "15px",
                    fontWeight: "500",
                    lineHeight: "16px",
                    marginTop: "8px",
                    color: "white",
                  }}
                >
                  New Jobs
                </Typography>
              </div>
            </div>
          </div>
        </div>

        <Dialog
          open={open}
          sx={{
            "& .MuiPaper-root": {
              borderRadius: "10px",
              padding: "10px 10px",
            },
          }}
        >
          <Box>
            <DialogTitle sx={{ fontFamily: "Poppins-SemiBold" }}>
              <div
                style={{ display: "flex", gap: "3px", alignItems: "center" }}
              >
                <ErrorSharpIcon
                  sx={{ mt: -0.3, color: "crimson" }}
                  fontSize="large"
                />
                <Typography variant="h5" sx={{ ml: 0.3 }}>
                  {Errormessage.heading}
                </Typography>
              </div>
            </DialogTitle>
            <DialogContent>
              <DialogContentText
                sx={{ fontFamily: "Poppins-Medium", color: "crimson" }}
              >
                {Errormessage.message}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                variant="outlined"
                sx={{
                  textTransform: "capitalize",
                  color: "white",
                  backgroundColor: "rgb(124, 0, 222)",
                  borderColor: "rgb(124, 0, 222)",
                  fontFamily: "Poppins-Medium",
                  "&:hover": {
                    color: "rgb(124, 0, 222)",
                    borderColor: "rgb(124, 0, 222)",
                    fontFamily: "Poppins-Medium",
                  },
                }}
                onClick={() => {
                  handleClose();
                }}
              >
                Ok
              </Button>
            </DialogActions>
          </Box>
        </Dialog>
      </div>
    </>
  );
}

export default ForgotPassword;
