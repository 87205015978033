import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import { ROUTINGDATA } from "../ROUTINGDATA";
import useAxios from "../../api/useAxios";
import Container from "@mui/material/Container";
import { useTranslation } from "react-i18next";
import { useContext } from "react";
import { CurrentStateContext } from "../../App";
import "./TermsAndCondition.css";
import { ReactComponent as TwitterX } from "../../img/twitter-x-seeklogo.com-4.svg";

const PurchasePolicy = () => {
  const axiosData = useAxios();
  const { languagetranslator } = useContext(CurrentStateContext);
  const { t, i18n, ready } = useTranslation();

  const navigate = useNavigate();

  const changeLanguage = (languagetranslator) => {
    i18n.changeLanguage(languagetranslator);
  };
  useEffect(() => {
    changeLanguage(languagetranslator);
  }, [languagetranslator]);

  const lang = sessionStorage.getItem("lang");

  if (ready) {
    return (
      <div style={{ padding: "5rem 0" }}>
       
        <Container>
          <div className="TermsAndConditionBackpagefonts">
            <span
              className="Termsbackbtn"
              onClick={() => navigate(ROUTINGDATA.LAND)}
              style={{ color: "#7C00DE" }}
            >
              {t("Home")}
            </span>
            / <span style={{ color: "black" }}>Purchase Policy</span>
          </div>
          <div>
            <h2>Purchase Policy</h2>
            <p>
              This Purchase Policy governs the use of the payment gateway
              services provided by US Global Solutions Company on the job portal
              website. By using the Services, you agree to adhere to this
              Policy. This Policy is in addition to the general Terms and
              Conditions of the Website.
            </p>
          </div>
          <div>
            <h3>1. Payment Processing</h3>
            <p>
              a. When you make a payment through our payment gateway, you agree
              to provide accurate and complete payment information. You
              authorize us to charge the specified amount to the payment method
              provided.
            </p>
            <p>
              b. Payments are processed in accordance with the terms and
              conditions of our chosen payment gateway provider. We do not store
              your payment information on our servers.
            </p>
          </div>
          <div>
            <h3>2. Payment Confirmation</h3>
            <p>
              a. Upon successful payment, you will receive a confirmation email
              or notification indicating the details of your transaction.
            </p>
            <p>
              b. It is your responsibility to ensure the accuracy of the payment
              details and contact us promptly in case of any discrepancies.
            </p>
          </div>
          <div>
            <h3>3. Refund Policy</h3>
            <p>
              a. Our refund policy varies depending on the type of services or
              products offered through the Website. Please refer to the specific
              service or product description for refund details.
            </p>
            <p>
              b. Refund requests must be submitted within the timeframe
              specified in the service or product description. We reserve the
              right to refuse or approve refund requests based on our
              discretion.
            </p>
          </div>
          <div>
            <h3>4. Disputes and Chargebacks</h3>
            <p>
              a. If you believe a payment was processed in error or if you wish
              to dispute a charge, please contact us immediately.
            </p>
            <p>
              b. Chargebacks initiated without proper communication or valid
              reason may result in the suspension or termination of your account
              on the Website.
            </p>
          </div>
          <div>
            <h3>5. Payment Gateway Service Provider</h3>
            <p>
              a. The payment gateway services are provided by a third-party
              service provider. We do not guarantee the availability, accuracy,
              security, or performance of the payment gateway.
            </p>
            <p>
              b. You acknowledge that the payment gateway provider may have its
              own terms and conditions, and you agree to adhere to those terms
              in addition to this Policy.
            </p>
          </div>
          <div>
            <h3>6. Changes to Purchase Policy</h3>
            <p>
              We reserve the right to modify or update this Purchase Policy at
              any time. By continuing to use the Services after changes are
              posted, you agree to the updated terms.
            </p>
          </div>
          <div>
            <h3>7. Governing Law</h3>
            <p>
              This Purchase Policy shall be governed by and construed in
              accordance with the laws of [jurisdiction]. Any disputes arising
              from this Policy shall be subject to the exclusive jurisdiction of
              the courts in [jurisdiction].
            </p>
            <p>
              Please note that this is a general template and should be reviewed
              and customized to fit the specific details of your payment
              gateway, business, and jurisdiction. It's highly recommended to
              consult with legal professionals to ensure the policy aligns with
              legal requirements and provides appropriate protection for your
              business and users.
            </p>
          </div>
        </Container>
      </div>
    );
  } else {
    return (
      <div
        style={{
          height: "100vh",
          display: "grid",
          placeItems: "center",
          // transform: "translate(0,50vh)",
        }}
      >
        <div class="dots"></div>
      </div>
    );
  }
};

export default PurchasePolicy;
