import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Box,
  Button,
  Card,
  CardContent,
  Avatar,
  IconButton,
  TextField,
  FormControl,
} from "@mui/material";
import sideImage from "../../../img/New Design Images/—Pngtree—girl holding laptop_7649384 1.png";
import sideImage2 from "../../../img/New Design Images/Frame 1279.png";
import EastIcon from "@mui/icons-material/East";
import SearchIcon from "@mui/icons-material/Search";
import FmdGoodOutlinedIcon from "@mui/icons-material/FmdGoodOutlined";
import DesignServicesIcon from "@mui/icons-material/DesignServices";
import PaymentsIcon from "@mui/icons-material/Payments";
import ComputerIcon from "@mui/icons-material/Computer";
import EngineeringIcon from "@mui/icons-material/Engineering";
import WorkIcon from "@mui/icons-material/Work";
import Groups2Icon from "@mui/icons-material/Groups2";
import StackedBarChartIcon from "@mui/icons-material/StackedBarChart";
import CampaignIcon from "@mui/icons-material/Campaign";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useTheme, useMediaQuery } from "@mui/material";
import "../NewLandingPage.css";
import { ROUTINGDATA } from "../../ROUTINGDATA";
import { useNavigate } from "react-router-dom";
import { Chip } from "@mui/material-next";
import useAxios from "../../../api/useAxios";
import Skeleton from "@mui/material/Skeleton";
import PlaceOutlinedIcon from "@mui/icons-material/PlaceOutlined";
import WorkOutlineOutlinedIcon from "@mui/icons-material/WorkOutlineOutlined";
import ArrowBackIosOutlinedIcon from "@mui/icons-material/ArrowBackIosOutlined";
function NewContentPage() {
  const navigate = useNavigate();
  const [selected, setSelected] = useState(0);
  const axiosData = useAxios();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [categorie, setcategorie] = useState([]);
  function convertToLakhs(amount) {
    // Check if the amount is a number
    if (isNaN(amount)) {
      return "Invalid number";
    }

    // Convert the amount to lakhs
    const lakhs = amount / 100000;

    // Format the number with commas and two decimal places
    return (
      lakhs.toLocaleString("en-IN", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }) + " lakhs"
    );
  }

  // Example usage
  let converted = convertToLakhs("600000");
  //console.log(converted); // Output: 0-6 lakhs

  useEffect(() => {
    const getjobs = async () => {
      try {
        const response = await axiosData.get(`jobs/get/cat`, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        if (response.data) {
          setcategorie(response.data);
        }
        if (response.status === 204) {
        }
      } catch (err) {
        // alert("No jobs for your search");

        console.log(err);
      }
    };
    getjobs();
  }, []);
  const categories = [
    {
      name: "Design",
      jobs: categorie["Design"],
      icon: <DesignServicesIcon sx={{ fontSize: 35 }} />,
      link: "NewFindJobsPage?Categories=Design&page=1",
    },
    {
      name: "Sales",
      jobs: categorie["Sales"],
      icon: <StackedBarChartIcon sx={{ fontSize: 35 }} />,
      link: "NewFindJobsPage?Categories=Sales&page=1",
    },
    {
      name: "Marketing",
      jobs: categorie["Marketing"],
      icon: <CampaignIcon sx={{ fontSize: 35 }} />,
      link: "NewFindJobsPage?Categories=Marketing&page=1",
    },
    {
      name: "Finance",
      jobs: categorie["Finance"],
      icon: <PaymentsIcon sx={{ fontSize: 35 }} />,
      link: "NewFindJobsPage?Categories=Finance&page=1",
    },
    {
      name: "Technology",
      jobs: categorie["Technology"],
      icon: <ComputerIcon sx={{ fontSize: 35 }} />,
      link: "NewFindJobsPage?Categories=Technology&page=1",
    },
    {
      name: "Engineering",
      jobs: categorie["Engineering"],
      icon: <EngineeringIcon sx={{ fontSize: 35 }} />,
      link: "NewFindJobsPage?Categories=Engineering&page=1",
    },
    {
      name: "Business",
      jobs: categorie["Business"],
      icon: <WorkIcon sx={{ fontSize: 35 }} />,
      link: "NewFindJobsPage?Categories=Business&page=1",
    },
    {
      name: "Human Resource",
      jobs: categorie["Human Resource"],
      icon: <Groups2Icon sx={{ fontSize: 35 }} />,
      link: "NewFindJobsPage?Categories=Human Resource&page=1",
    },
  ];

  const avatars = [
    {
      src: "path/to/avatar1.png",
      alt: "User 1",
      name: "Tom",
      review: "It was a very good experience",
    },
    {
      src: "path/to/avatar2.png",
      alt: "User 2",
      name: "Alice",
      review: "Great support and service",
    },
    {
      src: "path/to/avatar3.png",
      alt: "User 3",
      name: "Bob",
      review: "I found my dream job",
    },
    {
      src: "path/to/avatar4.png",
      alt: "User 4",
      name: "Eve",
      review: "Very helpful and professional",
    },
    {
      src: "path/to/avatar5.png",
      alt: "User 5",
      name: "John",
      review: "Highly recommend this platform",
    },
  ];

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleClick = (index) => {
    setSelected(index);
  };

  const handlePrevious = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? avatars.length - 1 : prevIndex - 1
    );
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === avatars.length - 1 ? 0 : prevIndex + 1
    );
  };

  const getAvatarIndex = (offset) => {
    const index = currentIndex + offset;
    if (index < 0) return avatars.length + index;
    if (index >= avatars.length) return index - avatars.length;
    return index;
  };
  const [jobListings, setjobListings] = useState([]);
  useEffect(() => {
    const getjobs = async () => {
      // const array = obj.salaryRange.split("-")

      try {
        const response = await axiosData.post(
          `jobs/search2`,
          {
            page_number: 1,
            limit: 4,
          },
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        if (response.data) {
          setjobListings([...response.data.data]);
        }
        if (response.status === 204) {
        }
      } catch (err) {
        // alert("No jobs for your search");

        console.log(err);
      }
    };
    getjobs();
  }, []);
  // const jobListings = [
  //   // Existing job listings
  //   {
  //     title: "UI/UX Designer",
  //     company: "Google",
  //     location: "Bangalore, Karnataka, India",
  //     salary: "$1000 - $2000",
  //     logo: "https://via.placeholder.com/50",
  //   },
  //   {
  //     title: "UI/UX Designer",
  //     company: "TATA",
  //     location: "Bangalore, Karnataka, India",
  //     salary: "$1000 - $2000",
  //     logo: "https://via.placeholder.com/50",
  //   },
  //   {
  //     title: "UI/UX Designer",
  //     company: "Airtel",
  //     location: "Bangalore, Karnataka, India",
  //     salary: "$1000 - $2000",
  //     logo: "https://via.placeholder.com/50",
  //   },

  //   // Additional job listings
  //   {
  //     title: "Front-End Developer",
  //     company: "Facebook",
  //     location: "Bangalore, Karnataka, India",
  //     salary: "$1200 - $2500",
  //     logo: "https://via.placeholder.com/50",
  //   },
  //   {
  //     title: "Back-End Developer",
  //     company: "Amazon",
  //     location: "Bangalore, Karnataka, India",
  //     salary: "$1300 - $2700",
  //     logo: "https://via.placeholder.com/50",
  //   },
  //   {
  //     title: "Full Stack Developer",
  //     company: "Microsoft",
  //     location: "Bangalore, Karnataka, India",
  //     salary: "$1500 - $3000",
  //     logo: "https://via.placeholder.com/50",
  //   },
  //   {
  //     title: "Graphic Designer",
  //     company: "Adobe",
  //     location: "Bangalore, Karnataka, India",
  //     salary: "$1100 - $2300",
  //     logo: "https://via.placeholder.com/50",
  //   },
  //   {
  //     title: "Product Manager",
  //     company: "IBM",
  //     location: "Bangalore, Karnataka, India",
  //     salary: "$1800 - $3500",
  //     logo: "https://via.placeholder.com/50",
  //   },
  //   {
  //     title: "Data Scientist",
  //     company: "Oracle",
  //     location: "Bangalore, Karnataka, India",
  //     salary: "$1600 - $3200",
  //     logo: "https://via.placeholder.com/50",
  //   },
  //   {
  //     title: "DevOps Engineer",
  //     company: "Cisco",
  //     location: "Bangalore, Karnataka, India",
  //     salary: "$1400 - $2900",
  //     logo: "https://via.placeholder.com/50",
  //   },
  //   {
  //     title: "Software Engineer",
  //     company: "SAP",
  //     location: "Bangalore, Karnataka, India",
  //     salary: "$1200 - $2500",
  //     logo: "https://via.placeholder.com/50",
  //   },
  //   {
  //     title: "Digital Marketer",
  //     company: "HubSpot",
  //     location: "Bangalore, Karnataka, India",
  //     salary: "$1000 - $2200",
  //     logo: "https://via.placeholder.com/50",
  //   },
  //   {
  //     title: "Business Analyst",
  //     company: "Salesforce",
  //     location: "Bangalore, Karnataka, India",
  //     salary: "$1300 - $2700",
  //     logo: "https://via.placeholder.com/50",
  //   },
  //   {
  //     title: "Database Administrator",
  //     company: "Intel",
  //     location: "Bangalore, Karnataka, India",
  //     salary: "$1400 - $2800",
  //     logo: "https://via.placeholder.com/50",
  //   },
  //   {
  //     title: "Network Engineer",
  //     company: "Dell",
  //     location: "Bangalore, Karnataka, India",
  //     salary: "$1200 - $2600",
  //     logo: "https://via.placeholder.com/50",
  //   },
  //   {
  //     title: "Cybersecurity Analyst",
  //     company: "VMware",
  //     location: "Bangalore, Karnataka, India",
  //     salary: "$1500 - $3100",
  //     logo: "https://via.placeholder.com/50",
  //   },
  //   {
  //     title: "Systems Analyst",
  //     company: "Red Hat",
  //     location: "Bangalore, Karnataka, India",
  //     salary: "$1300 - $2700",
  //     logo: "https://via.placeholder.com/50",
  //   },
  //   {
  //     title: "Content Writer",
  //     company: "Contentful",
  //     location: "Bangalore, Karnataka, India",
  //     salary: "$1000 - $2200",
  //     logo: "https://via.placeholder.com/50",
  //   },
  //   {
  //     title: "Sales Executive",
  //     company: "Zoho",
  //     location: "Bangalore, Karnataka, India",
  //     salary: "$1100 - $2400",
  //     logo: "https://via.placeholder.com/50",
  //   },
  //   {
  //     title: "UI/UX Designer",
  //     company: "Google",
  //     location: "Bangalore, Karnataka, India",
  //     salary: "$1000 - $2000",
  //     logo: "https://via.placeholder.com/50",
  //   },
  //   {
  //     title: "UI/UX Designer",
  //     company: "TATA",
  //     location: "Bangalore, Karnataka, India",
  //     salary: "$1000 - $2000",
  //     logo: "https://via.placeholder.com/50",
  //   },
  //   {
  //     title: "UI/UX Designer",
  //     company: "Airtel",
  //     location: "Bangalore, Karnataka, India",
  //     salary: "$1000 - $2000",
  //     logo: "https://via.placeholder.com/50",
  //   },
  //   {
  //     title: "UI/UX Designer",
  //     company: "Google",
  //     location: "Bangalore, Karnataka, India",
  //     salary: "$1000 - $2000",
  //     logo: "https://via.placeholder.com/50",
  //   },
  //   {
  //     title: "UI/UX Designer",
  //     company: "TATA",
  //     location: "Bangalore, Karnataka, India",
  //     salary: "$1000 - $2000",
  //     logo: "https://via.placeholder.com/50",
  //   },
  //   {
  //     title: "UI/UX Designer",
  //     company: "Airtel",
  //     location: "Bangalore, Karnataka, India",
  //     salary: "$1000 - $2000",
  //     logo: "https://via.placeholder.com/50",
  //   },
  // ];

  const currentJobs = jobListings.slice(0, 4);

  const [searchKeyword, setSearchKeyword] = useState("");
  const [searchLocation, setSearchLocation] = useState("");
  const [localKeyword, setLocalKeyword] = useState("");
  const [localLocation, setLocalLocation] = useState("");

  const handleSearchChange = (event) => {
    const { name, value } = event.target;
    if (name === "keyword") {
      setLocalKeyword(value);
    } else if (name === "location") {
      setLocalLocation(value);
    }
  };

  const generateQueryString = (keyword, location) => {
    const query = new URLSearchParams();

    // Add search values to query
    if (keyword) {
      query.set("keyword", keyword);
    }
    if (location) {
      query.set("location", location);
    }

    return query.toString();
  };

  return (
    <div>
      <Box sx={{ px: isMobile ? 2 : 10, pt: isMobile ? 14 : 16 }}>
        <Grid container spacing={2} alignItems="center" justifyContent="center">
          <Grid
            item
            md={6}
            xs={12}
            container
            justifyContent="center"
            alignItems="center"
          >
            <Typography
              variant={isMobile ? "h5" : "h3"}
              sx={{
                fontFamily: "Exo2Italic",
                color: "#434343",
              }}
            >
              Your next career move starts here
              <span style={{ color: "#7C00DE" }}> More than 10,000+ jobs</span>
            </Typography>
          </Grid>
          <Grid
            item
            md={6}
            xs={12}
            container
            justifyContent="center"
            alignItems="center"
          >
            <img src={sideImage} alt="sideImage" style={{ maxWidth: "100%" }} />
          </Grid>
        </Grid>
        <div
          style={{
            fontFamily: "Exo2Italic",
            color: "#434343",
            textAlign: "center",
            marginTop: "1rem",
          }}
        >
          <text
            style={{ fontSize: isMobile ? "24px" : "42px", fontWeight: "bold" }}
          >
            Find your <span style={{ color: "#7C00DE" }}>Dream Job</span>
          </text>
          <p style={{ color: "#515B6F" }}>
            Find your next career at companies like HubSpot, Nike, and Dropbox
          </p>
        </div>
        <Card sx={{ mb: 4, p: 3 }}>
          <Grid container spacing={4}>
            <Grid item md={5.2} xs={12}>
              <FormControl fullWidth>
                <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                  <SearchIcon sx={{ color: "#25324B", my: 0.5 }} />
                  <TextField
                    fullWidth
                    name="keyword"
                    value={localKeyword}
                    onChange={handleSearchChange}
                    placeholder="Job title or keyword"
                    variant="standard"
                    sx={{
                      mr: 2,
                      pl: 2,
                      "& .MuiInput-underline:before": {
                        borderBottomColor: "#D6DDEB", // color when input is not focused
                      },
                      "& .MuiInput-underline:hover:before": {
                        borderBottomColor: "#7C00DE", // color when input is hovered
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: "#7C00DE", // color when input is focused
                      },
                    }}
                  />
                </Box>
              </FormControl>
            </Grid>
            <Grid item md={5.2} xs={12}>
              <FormControl fullWidth>
                <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                  <FmdGoodOutlinedIcon sx={{ color: "#25324B", my: 0.5 }} />
                  <TextField
                    fullWidth
                    name="location"
                    value={localLocation}
                    onChange={handleSearchChange}
                    placeholder="Location"
                    variant="standard"
                    sx={{
                      mr: 2,
                      pl: 2,
                      "& .MuiInput-underline:before": {
                        borderBottomColor: "#D6DDEB", // color when input is not focused
                      },
                      "& .MuiInput-underline:hover:before": {
                        borderBottomColor: "#7C00DE", // color when input is hovered
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: "#7C00DE", // color when input is focused
                      },
                    }}
                  />
                </Box>
              </FormControl>
            </Grid>
            <Grid item md={1.6} xs={12}>
              <Button
                variant="contained"
                fullWidth
                onClick={() => {
                  setSearchKeyword(localKeyword);
                  setSearchLocation(localLocation);
                  const queryString = generateQueryString(
                    localKeyword,
                    localLocation
                  );
                  navigate(`/${ROUTINGDATA.NewFindJobsPage}?${queryString}`);
                  window.scrollTo(0, 0);
                }}
                sx={{
                  mt: -1.6,
                  py: 1.2,
                  fontWeight: "bold",
                  fontSize: "13px",
                  backgroundColor: "#7C00DE",
                  textTransform: "capitalize",
                  color: "white",
                  borderColor: "#7C00DE",
                  borderRadius: "6px",
                  "&:hover": {
                    fontWeight: "bold",
                    textTransform: "capitalize",
                    color: "white",
                    backgroundColor: "#7C00DE",
                    borderColor: "#7C00DE",
                    fontSize: "13px",
                    borderRadius: "6px",
                  },
                }}
              >
                Search
              </Button>
            </Grid>
          </Grid>
        </Card>

        <Box sx={{ my: 4 }}>
          <div
            style={{
              display: isMobile ? "block" : "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "1rem",
            }}
          >
            <Typography
              variant={isMobile ? "h6" : "h5"}
              sx={{ fontFamily: "Exo2Italic", color: "#434343" }}
            >
              All
              <span style={{ color: "#7C00DE" }}> Jobs</span>
            </Typography>

            <Typography
              sx={{
                color: "#4640DE",
                textAlign: "right",
                mt: isMobile ? 2 : 0,
                cursor: "pointer",
              }}
              onClick={() => {
                navigate(`${"/"}${ROUTINGDATA.NewFindJobsPage}`);
                window.scrollTo(0, 0);
              }}
            >
              Show all jobs <EastIcon sx={{ mb: -0.6, fontSize: 20 }} />
            </Typography>
          </div>
          {currentJobs.length !== 0 ? (
            <Grid container xs={12} md={12}>
              <Grid
                container
                spacing={4}
                sx={{
                  overflowX: isMobile ? "scroll" : "unset",
                  flexWrap: isMobile ? "nowrap" : "wrap",
                }}
              >
                {currentJobs.map((job, index) => (
                  <Grid
                    item
                    xs={12}
                    sm={3}
                    key={index}
                    sx={{
                      cursor: "pointer",
                      minWidth: { xs: "300px", sm: "auto" },
                      width: { xs: "auto", sm: "auto" },
                    }}
                  >
                    <Box
                      sx={{
                        border: "1px solid #969696",
                        p: 2,
                        cursor: "pointer",
                        borderRadius: "5px",
                      }}
                    >
                      <Box
                        component="img"
                        src={job.logo}
                        alt="company logo"
                        sx={{ mr: 2, height: 30 }}
                      />
                      <Box>
                        <div
                          style={{
                            gap: "10px",
                          }}
                        >
                          <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                            {job.title}
                          </Typography>
                          <Chip
                            label={job.job_type}
                            color="primary"
                            sx={{
                              bgcolor: "#C7FF8F",
                              color: "#000000",
                              padding: 0,
                              "& .MuiChip-label": {
                                fontSize: "11px",
                              },
                            }}
                          />
                        </div>
                        <Typography
                          variant="body2"
                          sx={{
                            color: "#454545",
                            fontWeight: "bold",
                            mt: 1,
                            width: "250px",
                            wordWrap: "break-word",
                            lineHeight: "2em",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                          }}
                        >
                          <WorkOutlineOutlinedIcon sx={{ mb: -0.7 }} />{" "}
                          {job.job_title}
                        </Typography>
                        <Typography
                          variant="body2"
                          // sx={{ color: "#454545", }}
                          sx={{
                            color: "#454545",
                            fontWeight: "bold",
                            mt: 0.7,
                            width: "250px",
                            wordWrap: "break-word",
                            lineHeight: "2em",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                          }}
                        >
                          <PlaceOutlinedIcon sx={{ mb: -0.7 }} />
                          {job.location ? job.location : job.location_type}
                        </Typography>
                        <Typography sx={{ fontWeight: "bold", mt: 0.7 }}>
                          {`${convertToLakhs(job.min_charge)}-${convertToLakhs(
                            job.max_charge
                          )}`}
                        </Typography>
                      </Box>
                      <Button
                        variant="contained"
                        fullWidth
                        sx={{
                          mt: 0.7,
                          fontWeight: "bold",
                          fontSize: "13px",
                          backgroundColor: "#D7A4FF",
                          textTransform: "capitalize",
                          color: "#4D0089",
                          borderColor: "#D7A4FF",
                          borderRadius: "6px",
                          "&:hover": {
                            fontWeight: "bold",
                            textTransform: "capitalize",
                            color: "white",
                            backgroundColor: "#7C00DE",
                            borderColor: "#7C00DE",
                            fontSize: "13px",
                            borderRadius: "6px",
                          },
                        }}
                        onClick={() => {
                          navigate(
                            `/${ROUTINGDATA.NewJobDetailsPage}?${
                              "job_id=" + job.job_id
                            } `
                          );
                          window.scrollTo(0, 0);
                        }}
                      >
                        Apply
                      </Button>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          ) : (
            <Grid container xs={12} md={12}>
              <Grid
                container
                spacing={4}
                sx={{
                  overflowX: isMobile ? "scroll" : "unset",
                  flexWrap: isMobile ? "nowrap" : "wrap",
                }}
              >
                {Array.from(new Array(4)).map((_, index) => (
                  <Grid
                    item
                    xs={12}
                    sm={3}
                    key={index}
                    sx={{
                      cursor: "pointer",
                      minWidth: { xs: "300px", sm: "auto" },
                      width: { xs: "auto", sm: "auto" },
                    }}
                  >
                    <Box
                      sx={{
                        border: "1px solid #969696",
                        p: 2,
                        borderRadius: "5px",
                      }}
                    >
                      <Skeleton
                        variant="rectangular"
                        width="100%"
                        height={40}
                        sx={{ mb: 2 }}
                      />
                      <Box>
                        <Skeleton
                          variant="text"
                          sx={{ fontSize: "1rem", mb: 1, width: "70%" }}
                        />
                        <Skeleton
                          variant="rectangular"
                          width="50px"
                          height={24}
                          sx={{ mb: 1 }}
                        />
                        <Skeleton
                          variant="text"
                          sx={{ fontSize: "0.875rem", mb: 1, width: "60%" }}
                        />
                        <Skeleton
                          variant="text"
                          sx={{ fontSize: "0.875rem", mb: 1, width: "50%" }}
                        />
                        <Skeleton
                          variant="text"
                          sx={{ fontSize: "0.875rem", width: "40%" }}
                        />
                      </Box>
                      <Skeleton
                        variant="rectangular"
                        width="100%"
                        height={36}
                        sx={{ mt: 2 }}
                      />
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          )}
        </Box>

        <Box p={3}>
          <div
            style={{
              display: isMobile ? "block" : "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              variant={isMobile ? "h6" : "h5"}
              sx={{ fontFamily: "Exo2Italic", color: "#434343" }}
            >
              Explore by
              <span style={{ color: "#7C00DE" }}> Category</span>
            </Typography>

            <Typography
              sx={{
                color: "#4640DE",
                textAlign: "right",
                mt: isMobile ? 2 : 0,
                cursor: "pointer",
              }}
              onClick={() => {
                navigate(`${"/"}${ROUTINGDATA.NewFindJobsPage}`);
                window.scrollTo(0, 0);
              }}
            >
              Show all jobs <EastIcon sx={{ mb: -0.6, fontSize: 20 }} />
            </Typography>
          </div>
          <Grid container xs={12} md={12}>
            <Grid
              container
              sx={{
                overflowX: isMobile ? "scroll" : "unset",
                flexWrap: isMobile ? "nowrap" : "wrap",
              }}
            >
              {categories.map((category, index) => (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={3}
                  key={index}
                  p={2}
                  onClick={() => handleClick(index)}
                  sx={{
                    cursor: "pointer",
                    minWidth: { xs: "250px", sm: "auto" },
                    width: { xs: "auto", sm: "auto" },
                  }}
                >
                  <span
                    onClick={() => {
                      navigate(category.link);
                    }}
                  >
                    <Box
                      p={2}
                      border={1}
                      borderRadius={2}
                      sx={{
                        borderColor: "#D6DDEB",
                        // backgroundColor: "white",
                        // color: "#7C00DE",
                        transition: "background-color 0.3s",
                        "&:hover": {
                          backgroundColor: "#7C00DE",
                          color: "white",
                          "& .category-name, & .category-jobs": {
                            color: "white",
                          },
                        },
                      }}
                    >
                      {category.icon}
                      <Typography
                        className="category-name"
                        sx={{ color: "#25324B" }}
                        variant="h6"
                      >
                        {category.name}
                      </Typography>
                      <Typography
                        className="category-jobs"
                        sx={{ color: "#7C8493" }}
                        variant="body1"
                      >
                        {category.jobs} jobs available{" "}
                        <EastIcon sx={{ mb: -0.6, fontSize: 20 }} />
                      </Typography>
                    </Box>
                  </span>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Box>
        <Grid
          container
          spacing={2}
          sx={{
            mt: 2,
            mb: 4,
            backgroundColor: "#7C00DE",
            borderRadius: "8rem 4px 8rem 4px",
            p: 2,
            alignItems: "center",
            justifyContent: "center",
            flexDirection: isMobile ? "column" : "row",
          }}
        >
          <Grid
            item
            xs={12}
            md={6}
            container
            justifyContent="center"
            alignItems="center"
          >
            <div
              style={{
                padding: "2rem",
                textAlign: isMobile ? "center" : "center",
              }}
            >
              <Typography
                variant={isMobile ? "50px" : "h4"}
                sx={{ fontFamily: "Exo2Italic", color: "white" }}
              >
                Get Your Dream Job
              </Typography>
              {/* <Typography
                variant={isMobile ? "body1" : "body2"}
                sx={{
                  fontFamily: "Exo2Italic",
                  color: "white",
                  pt: "4px",
                  pb: "4px",
                }}
              >
                Sign up as Jobseeker
              </Typography> */}
              <br />
              <Button
                onClick={() => {
                  navigate(ROUTINGDATA.NewFindJobsPage);
                  window.scrollTo(0, 0);
                }}
                variant="contained"
                sx={{
                  fontWeight: "bold",
                  fontSize: "13px",
                  textTransform: "capitalize",
                  color: "#7C00DE",
                  backgroundColor: "white",
                  borderColor: "white",
                  borderRadius: "0",
                  "&:hover": {
                    fontWeight: "bold",
                    textTransform: "capitalize",
                    color: "#7C00DE",
                    backgroundColor: "white",
                    borderColor: "white",
                  },
                }}
              >
                Find Jobs
              </Button>
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            container
            justifyContent="center"
            alignItems="center"
          >
            <img
              src={sideImage2}
              alt="sideImage2"
              style={{ maxWidth: "100%", marginTop: isMobile ? "1rem" : "0" }}
            />
          </Grid>
        </Grid>
      </Box>
      <div className={isMobile ? "reviewBackgroundMobile" : "reviewBackground"}>
        <Grid xs={12} sx={{ textAlign: "center", py: 4 }}>
          <Typography variant={isMobile ? "h5" : "h4"} gutterBottom>
            Here is what our job seekers are saying About us
          </Typography>
          <div
            style={{
              marginTop: "2rem",
              display: "flex",
              justifyContent: isMobile ? "normal" : "space-evenly",
              alignItems: "center",
              overflowX: "auto",
            }}
          >
            <Box
              sx={{
                boxShadow: 3,
                borderRadius: "50%",
                width: isMobile ? "50px" : "80px",
                height: isMobile ? "50px" : "80px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <IconButton
                onClick={handlePrevious}
                sx={{
                  padding: 0,
                }}
              >
                <ArrowBackIosOutlinedIcon
                  sx={{
                    color: "#7C00DE",
                    fontSize: isMobile ? 30 : 40,
                  }}
                />
              </IconButton>
            </Box>
            <Card
              sx={{
                borderRadius: 5,
                padding: isMobile ? "10px" : "20px",
                textAlign: "center",
                maxWidth: isMobile ? "90vw" : "500px",
                overflow: "hidden",
              }}
            >
              <CardContent>
                <Typography variant={isMobile ? "h6" : "h5"} gutterBottom>
                  {avatars[currentIndex].review}
                </Typography>
                <Typography variant="body1" paragraph>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Cursus nibh mauris, nec turpis orci lectus maecenas.
                  Suspendisse sed magna eget nibh in turpis. Consequat duis diam
                  lacus arcu. Faucibus venenatis felis id augue sit cursus
                  pellentesque enim arcu. Elementum felis magna pretium in
                  tincidunt. Suspendisse sed magna eget nibh in turpis.
                </Typography>
              </CardContent>
            </Card>
            <Box
              sx={{
                boxShadow: 3,
                borderRadius: "50%",
                width: isMobile ? "50px" : "80px",
                height: isMobile ? "50px" : "80px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <IconButton
                onClick={handleNext}
                sx={{
                  padding: 0,
                }}
              >
                <ArrowForwardIosIcon
                  sx={{ color: "#7C00DE", fontSize: isMobile ? 30 : 40 }}
                />
              </IconButton>
            </Box>
          </div>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              gap: 2,
              mt: 4,
              overflowX: "auto",
            }}
          >
            {[-2, -1, 0, 1, 2].map((offset) => {
              const avatarIndex = getAvatarIndex(offset);
              return (
                <Avatar
                  key={avatarIndex}
                  src={avatars[avatarIndex].src}
                  alt={avatars[avatarIndex].alt}
                  sx={{
                    width: offset === 0 ? 56 : 40,
                    height: offset === 0 ? 56 : 40,
                    transition: "width 0.3s, height 0.3s",
                  }}
                >
                  {avatars[avatarIndex].name.charAt(0)}
                </Avatar>
              );
            })}
          </Box>
          <Typography sx={{ fontSize: "18px", fontWeight: "bold", mt: 2 }}>
            {avatars[currentIndex].name}
          </Typography>
        </Grid>
      </div>
    </div>
  );
}

export default NewContentPage;
