import { createClient } from "@supabase/supabase-js";
// Create a single supabase client for interacting with your database
export const supabase = createClient(
  "https://upcfzyipxeizgcbevetr.supabase.co",
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InVwY2Z6eWlweGVpemdjYmV2ZXRyIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MTM4NTg1OTksImV4cCI6MjAyOTQzNDU5OX0.je1wgA1tILAiViSrPpEMSuuY3o7YruSluHZcy1q2gos"
);

const useSupabase = () => {
  return supabase;
};

export default useSupabase;
