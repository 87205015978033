import React, { useEffect } from "react";
import "../Landingpage/Landingpage.css";

import Landingheaderpage from "./landingpagecontext/Landingheaderpage";
import Landingpagecontext from "./landingpagecontext/Landingpagecontext";
import { Outlet } from "react-router-dom";
import NewHeader from "./landingpagecontext/NewHeader";
import NewFooter from "./landingpagecontext/NewFooter";

const Landingpage = (props) => {
  const currentScrollPosition =
    document.documentElement.scrollTop || document.body.scrollTop;

  return (
    <div className="landingcss">
      <div className="headerlandingpage">
        <NewHeader setlanguagetranslator={props.setlanguagetranslator} />
        {/* <Landingheaderpage setlanguagetranslator={props.setlanguagetranslator}/> */}
      </div>
      <div>
        <Outlet />
      </div>
      <NewFooter />
    </div>
  );
};

export default Landingpage;
