import React, { useState } from "react";
import newcareerlogo from "../../../img/newcareerlogo.png";
import {
  Typography,
  Button,
  TextField,
  Tooltip,
  InputAdornment,
  IconButton,
  Stack,
  FormControl,
  FormHelperText,
  useMediaQuery,
} from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import useAxios from "../../../api/useAxios";
import { useLocation, useNavigate } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import TaskAltIcon from "@mui/icons-material/TaskAlt";

const ResetPassword = () => {
  const axiosData = useAxios();
  const location = useLocation();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [showConPassword, setShowConPassword] = useState(false);
  const [open, setOpen] = useState(false);
  const isMobile = useMediaQuery("(max-width:600px)");

  const handleClickShowPassword = () => {
    setShowPassword((prev) => !prev);
  };

  const handleClickShowConPassword = () => {
    setShowConPassword((prev) => !prev);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const validationSchema = Yup.object({
    password: Yup.string()
      .min(8, "Password should be of minimum 8 characters length")
      .required("Password is required"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Confirm Password is required"),
  });

  const formik = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        await axiosData.put(`users/password/${location.state?.email_id}`, {
          password: values.confirmPassword,
        });
        setOpen(true);
      } catch (err) {
        console.log(err);
      }
    },
  });
  const handleClose = () => {
    setOpen(false);
    navigate("/");
  };

  return (
    <>
      <Dialog
        open={open}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",

              position: "relative",
              borderRadius: "16px",
              textAlign: "center",
              alignItems: "center",
            },
          },
        }}
      >
        <TaskAltIcon
          sx={{ fontSize: "70px", paddingTop: "25px", color: "#27AE60" }}
        />
        <DialogContent>
          <Typography
            style={{
              font0: "Roboto",
              fontSize: "30.43px",
              fontWeight: "700",
              lineHeight: "50.9px",
              color: "rgb(124, 0, 222)",
            }}
          >
            Password reset successfully!
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              handleClose();
            }}
            style={{
              backgroundColor: "rgb(124, 0, 222)",
              color: "white",
              textTransform: "capitalize",
              height: "48.78px",
              paddingRight: "40px",
              paddingLeft: "40px",
              font: "Roboto",
              fontWeight: "600",
              fontSize: "23px",
            }}
          >
            Continue
          </Button>
        </DialogActions>
        <br />
      </Dialog>
      <div
        style={{
          textAlign: "center",
          paddingTop: "70px",
          paddingRight: isMobile ? "20px" : "",
          paddingLeft: isMobile ? "20px" : "",
        }}
      >
        <img src={newcareerlogo} alt="logo" />
        <Typography
          style={{
            paddingTop: "30px",
            fontFamily: "Inter",
            fontSize: "28.64px",
            fontWeight: "500",
            lineHeight: "35.8px",
          }}
        >
          Reset Password
        </Typography>
        <Typography
          style={{
            paddingTop: "4px",
            fontFamily: "Inter",
            fontSize: "14.32px",
            fontWeight: "400",
            lineHeight: "21.48px",
          }}
        >
          Create a strong new password for your account.
        </Typography>
        <Typography
          style={{
            fontFamily: "Inter",
            fontSize: "14.32px",
            fontWeight: "400",
            lineHeight: "21.48px",
          }}
        >
          Please confirm your new password to ensure it matches correctly.
        </Typography>
        <form onSubmit={formik.handleSubmit}>
          <Stack
            spacing={2}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              paddingTop: "20px",
              width: "100%",
            }}
          >
            <FormControl
              style={{
                width: "100%",
                maxWidth: "500px",
                textAlign: "left",
              }}
            >
              <Tooltip title="Enter your Password" arrow>
                <TextField
                  type={showPassword ? "text" : "password"}
                  id="password"
                  placeholder="Password"
                  variant="outlined"
                  name="password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Tooltip>
              <FormHelperText
                style={{
                  margin: "0",
                  color: "red",
                  fontSize: "13px",
                }}
              >
                {formik.touched.password && formik.errors.password ? (
                  <Typography>{formik.errors.password}</Typography>
                ) : null}
              </FormHelperText>
            </FormControl>

            <FormControl
              style={{
                width: "100%",
                maxWidth: "500px",
                textAlign: "left",
              }}
            >
              <Tooltip title="Re-enter your Password" arrow>
                <TextField
                  type={showConPassword ? "text" : "password"}
                  id="confirmPassword"
                  placeholder="Confirm Password"
                  variant="outlined"
                  name="confirmPassword"
                  value={formik.values.confirmPassword}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleClickShowConPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showConPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Tooltip>
              <FormHelperText
                style={{
                  margin: "0",
                  color: "red",
                  fontSize: "13px",
                }}
              >
                {formik.touched.confirmPassword &&
                formik.errors.confirmPassword ? (
                  <Typography>{formik.errors.confirmPassword}</Typography>
                ) : null}
              </FormHelperText>
            </FormControl>
            <br />
            <Tooltip title="Enter your details after then Create account Successfully">
              <Button
                style={{
                  backgroundColor: "#7C00DE",
                  color: "white",
                  textTransform: "capitalize",
                  height: "48.78px",
                  maxWidth: "500px",
                  width: "100%",
                }}
                fullWidth
                type="submit"
              >
                Reset Password →
              </Button>
            </Tooltip>
          </Stack>
        </form>
      </div>
    </>
  );
};

export default ResetPassword;
