import React, { useRef, useState } from "react";
import newcareerlogo from "../../../img/newcareerlogo.png";
import newemployer from "../../../img/newemployer.png";
import BusinessCenterOutlinedIcon from "@mui/icons-material/BusinessCenterOutlined";
import BusinessOutlinedIcon from "@mui/icons-material/BusinessOutlined";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import "../../login/Login.css";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import {
  Button,
  FormControl,
  TextField,
  Tooltip,
  Typography,
  Card,
  MenuItem,
  Select,
  InputAdornment,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
} from "@mui/material";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import { Link } from "react-router-dom";
import { Stack } from "@mui/system";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useFormik } from "formik";
import * as Yup from "yup";
import { FormHelperText } from "@mui/material-next";
import { useNavigate } from "react-router-dom";
import useAxios from "../../../api/useAxios";
import { uploadImage, removeImage } from "../../../utility/Helperfunction";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CloseIcon from "@mui/icons-material/Close";
import { ROUTINGDATA } from "../../ROUTINGDATA";
import LoadingButton from "../../LoadingButton";

const exceptThisSymbols = ["e", "E", "+", "-", ".", "ArrowUp", "ArrowDown"];

const JobSeekerSignup = ({ locationdata }) => {
  const axiosData = useAxios();
  const [getStatusCode, setGetStatusCode] = useState(0);
  const navigate = useNavigate();
  const [selectValue, setSelectValue] = React.useState("JobSeeker");
  const [showPassword, setShowPassword] = useState(false);
  const [showConPassword, setShowConPassword] = useState(false);
  const [captureBlur, setCaptureBlur] = useState({
    email_id: false,
    mobileNumber: false,
  });
  const [buttonLoading, setButtonLoading] = useState(false);
  const [valid, setvalid] = useState(true);
  const [error, setalert] = useState({});
  // const [alerte, setalerte] = useState(false);
  const validate = async (values) => {
    let errors = error;
    if (!values.mobile_number) {
      errors.mobile_number = "Mobile Number must be Required";
      setvalid(true);
      return errors;
    } else if (/^\d{9}$/.test(values.mobile_number)) {
      setvalid(true);
    } else if (/^\d{10}$/.test(values.mobile_number)) {
      if (valid) {
        const controller = new AbortController();
        try {
          const response = await axiosData.get(
            `users/Mobile/${values.mobile_number}`
          );

          if (response.status === 200) {
            errors.mobile_number = "Mobile Number exist";
            setalert(errors);
            setvalid(false);
            console.log(error, errors);
            return errors;
          } else if (response.status === 204) {
            setalert({});
            setvalid(false);
            return errors;
          }

          setvalid(false);

          return () => controller.abort();
        } catch (error) {}
      }
    }
    console.log(errors, "error");
    return errors;
  };
  const [open, setOpen] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword((prev) => !prev);
  };

  const handleClickShowConPassword = () => {
    setShowConPassword((prev) => !prev);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleSelectChange = (event) => {
    setSelectValue(event.target.value);
    if (event.target.value === "Employers") {
      navigate("/SignUp");
    }
  };
  // const handleFileChange = (event) => {
  //   const file = event.currentTarget.files;
  //   formik.setFieldValue("file", file);
  // };
  const validationSchema = Yup.object({
    firstName: Yup.string().required("First Name is required"),
    lastName: Yup.string().required("Last Name is required"),
    mobile_number: Yup.string()
      .matches(/^\d{10}$/, "Mobile Number must be exactly 10 digits")
      .required("Mobile Number is required"),
    email_id: Yup.string()
      .email("Invalid email address")
      .required("Email must be Required")
      .matches(
        /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
        "Invalid email address"
      )
      .test("is-valid-email", "Invalid Email Address", function (value) {
        const isValidEmail = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
          value
        );
        if (!value) {
          return false;
        } else if (!isValidEmail) {
          return false;
        } else if (isValidEmail) {
          if (getStatusCode === 200) {
            return this.createError({
              message: "Email already exists in Career365.in",
              path: "email_id",
            });
          } else if (getStatusCode === 204) {
            return true;
          }
        } else {
          return true;
        }
        return true;
      }),
    password: Yup.string()
      .min(8, "Password should be of minimum 8 characters length")
      .required("Password is required"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Confirm Password is required"),
    terms: Yup.boolean()
      .required("You must accept the terms and conditions")
      .oneOf([true], "You must accept the terms and conditions"),
    file: Yup.mixed().required("Resume is required"),
    // file: Yup.mixed().required("A file is required"),
    // .test(
    //   "fileSize",
    //   "File too large",
    //   (value) => value && value.size <= 12 * 1024 * 1024
    // )
    // 12 MB
    // .test(
    //   "fileFormat",
    //   "Unsupported Format",
    //   (value) => value && ["application/pdf"].includes(value.type)
    // ),
  });

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      mobile_number: "",
      email_id: "",
      password: "",
      confirmPassword: "",
      terms: false,
      resumelink: "",
      file: null,
    },
    validate,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setButtonLoading(true);

      let publicUrl = await uploadImage("resume", values.file, false);
      // console.log(publicUrl);
      axiosData
        .post(
          "seekers",
          {
            full_name: values.firstName,
            last_name: values.lastName,
            resumelink: publicUrl,
            email_id: values.email_id,
            mobile_number: values.mobile_number,
            password: values.password,
            country: locationdata?.address.country
              ? locationdata?.address.country
              : "",
            state: locationdata?.address.state
              ? locationdata?.address.state
              : "",
          },
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((res) => {
          axiosData.post(
            "users",
            {
              usertype: "seeker",
              status: "InActive",
              uniqueId: values.mobile_number,
              password: values.password,
              email_id: values.email_id,
              user_id: res.data.user_id,
            },
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          );

          // navigate(
          //   `${ROUTINGDATA.LAND}${ROUTINGDATA.MAIN}${ROUTINGDATA.ThankYou}`
          // );
        })
        .catch((err) => console.log(err));

      setButtonLoading(false);
      setOpen(true);
    },
  });
  const handleEmailChange = async (value, validateField) => {
    const emailValidate = validationSchema.fields.email_id.isValid(value);

    if (emailValidate) {
      try {
        const response = await axiosData.post(`users/email`, {
          email_id: value,
        });
        setGetStatusCode(response.status);
      } catch (error) {
        console.error("error", error);
      }
    }
  };
  const MAX_FILE_SIZE = 1048576 * 2;
  const [data, setData] = useState(false);
  const [file, setFile] = useState("");
  const [resume, setResume] = useState("");

  const fileInputField = useRef(null);

  const handleFileChange = (e) => {
    if (e.target.files[0].size > MAX_FILE_SIZE) {
      setFile("File is too large");
      setResume(false);
    } else {
      console.log(typeof e.currentTarget.files);
      const file = e.currentTarget.files[0];
      formik.setFieldValue("file", e.currentTarget.files[0]);
      setResume(e.target.files[0]);
      setFile(e.target.files[0].name);
    }
    setData(e.target.files[0].size);
  };

  const deleteFile = () => {
    fileInputField.current.value = "";
    setFile(null);
    setData(null);
    setResume(null);
    formik.setFieldValue("file", null);
    formik.setFieldValue("fileUpload", null);
  };
  const handleClose = () => {
    setOpen(false);
    navigate("/"); // Navigate to a different page if needed
  };

  return (
    <>
      <div
        style={{
          height: "100vh",
          width: "100%",
          display: "flex",
          flexDirection: "row",
          overflow: "hidden",
        }}
      >
        <div
          className="first-div"
          style={{
            height: "100vh",
            width: "50%",
            padding: "80px",
            paddingTop: "27px",
            overflowY: "auto",
            // display: "flex",
            // flexDirection: "column",
          }}
        >
          <Link to="/">
            <IconButton>
              <ArrowBackIcon />
            </IconButton>
          </Link>
          <div style={{ paddingTop: "10px", flexDirection: "row" }}>
            <img src={newcareerlogo} alt="logo" />
            <div
              style={{ display: "flex", flexDirection: "row", width: "90%" }}
            >
              <div>
                <Typography
                  style={{
                    fontFamily: "Inter",
                    fontSize: "27.78px",
                    fontWeight: "500",
                    lineHeight: "34.72px",
                    paddingTop: "20px",
                  }}
                >
                  Create account
                </Typography>
                <Typography
                  style={{
                    fontFamily: "Inter",
                    fontSize: "13.89px",
                    fontWeight: "400",
                    lineHeight: "20.83px",
                    color: "#5E6670",
                    paddingTop: "10px",
                  }}
                >
                  Already have an account?
                  <Link
                    to="/Login"
                    style={{
                      color: "#0A65CC",
                      fontFamily: "Inter",
                      fontWeight: "500",
                      paddingLeft: "3px",
                    }}
                  >
                    Log In
                  </Link>
                </Typography>
              </div>

              {/* <div style={{ marginLeft: "auto" }}>
                <FormControl
                  variant="outlined"
                  style={{ width: "160%", paddingTop: "30px" }}
                >
                  <Select
                    size="small"
                    value={selectValue}
                    onChange={handleSelectChange}
                    displayEmpty
                    sx={{
                      fontFamily: "Inter",
                      fontSize: "15.17px",
                      fontWeight: 400,
                      lineHeight: "17.39px",
                      textAlign: "left",
                    }}
                  >
                    <MenuItem value="Employers">Employer</MenuItem>
                    <MenuItem value="JobSeeker">Job Seeker</MenuItem>
                  </Select>
                </FormControl>
              </div> */}
            </div>

            <br />
            <input
              id="file-upload"
              type="file"
              name="file"
              className="file"
              accept=".docx,.doc,.pdf,"
              style={{ display: "none" }}
              onChange={handleFileChange}
              ref={fileInputField}
              required
            />
            <form
              onSubmit={formik.handleSubmit}
              style={{
                flex: 1,
                overflowY: "auto",
              }}
            >
              <Stack direction="row" spacing={4}>
                <FormControl style={{ width: "50%" }}>
                  <Tooltip title="Enter your First Name" arrow>
                    <TextField
                      id="firstName"
                      variant="outlined"
                      placeholder="First Name"
                      name="firstName"
                      value={formik.values.firstName}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      onInput={(e) => {
                        e.target.value = e.target.value.replace(
                          /[^a-zA-Z\s]/g,
                          ""
                        );
                      }}
                    />
                  </Tooltip>
                  <FormHelperText>
                    {formik.touched.firstName && formik.errors.firstName ? (
                      <Typography sx={{ color: "red", fontSize: "13px" }}>
                        {formik.errors.firstName}
                      </Typography>
                    ) : (
                      <Typography
                        sx={{ fontSize: "13px", visibility: "hidden" }}
                      >
                        none
                      </Typography>
                    )}
                  </FormHelperText>
                </FormControl>

                <FormControl style={{ width: "50%" }}>
                  <Tooltip title="Enter your Last Nmae" arrow>
                    <TextField
                      id="lastName"
                      variant="outlined"
                      placeholder="Last Name"
                      name="lastName"
                      value={formik.values.lastName}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      onInput={(e) => {
                        e.target.value = e.target.value.replace(
                          /[^a-zA-Z\s]/g,
                          ""
                        );
                      }}
                    />
                  </Tooltip>
                  <FormHelperText>
                    {formik.touched.lastName && formik.errors.lastName ? (
                      <Typography sx={{ color: "red", fontSize: "13px" }}>
                        {formik.errors.lastName}
                      </Typography>
                    ) : (
                      <Typography
                        sx={{ fontSize: "13px", visibility: "hidden" }}
                      >
                        none
                      </Typography>
                    )}
                  </FormHelperText>
                </FormControl>
              </Stack>
              <Tooltip title="Enter your Mobile Number" arrow>
                <TextField
                  id="mobile_number"
                  variant="outlined"
                  placeholder="Mobile Number"
                  name="mobile_number"
                  type="number"
                  fullWidth
                  value={formik.values.mobile_number}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  onWheel={(e) => {
                    e.preventDefault();
                    e.target.blur();
                  }}
                  onKeyDown={(e) =>
                    exceptThisSymbols.includes(e.key) && e.preventDefault()
                  }
                  onInput={(e) => {
                    const input = e.target.value.replace(/\D/g, "");
                    const trimmedInput = input.slice(0, 10);
                    e.target.value = trimmedInput;
                  }}
                />
              </Tooltip>
              <FormHelperText>
                {formik.touched.mobile_number && formik.errors.mobile_number ? (
                  <Typography sx={{ color: "red", fontSize: "13px" }}>
                    {formik.errors.mobile_number}
                  </Typography>
                ) : (
                  <Typography sx={{ fontSize: "13px", visibility: "hidden" }}>
                    none
                  </Typography>
                )}
              </FormHelperText>
              <Tooltip title="Enter your Email Address" arrow>
                <TextField
                  style={{ paddingTop: "5px" }}
                  id="email_id"
                  placeholder="Email address"
                  variant="outlined"
                  name="email_id"
                  value={formik.values.email_id}
                  onBlur={formik.handleBlur}
                  onBlurCapture={() =>
                    setCaptureBlur({
                      ...captureBlur,
                      email_id: true,
                    })
                  }
                  onChange={(e) => {
                    setCaptureBlur({
                      ...captureBlur,
                      email_id: false,
                    });
                    const { value } = e.target;
                    const shopValue = value.replace(/[^a-z0-9@.]/gi, "");
                    console.log(shopValue, "dwdadad");
                    formik.setFieldValue("email_id", shopValue);

                    handleEmailChange(shopValue, formik.validateField);
                  }}
                  // onBlur={formik.handleBlur}
                  fullWidth
                />
              </Tooltip>
              <FormHelperText>
                {formik.touched.email_id && formik.errors.email_id ? (
                  <Typography sx={{ color: "red", fontSize: "13px" }}>
                    {formik.errors.email_id}
                  </Typography>
                ) : (
                  <Typography sx={{ fontSize: "13px", visibility: "hidden" }}>
                    none
                  </Typography>
                )}
              </FormHelperText>
              <Tooltip title="Enter your Password" arrow>
                <TextField
                  type={showPassword ? "text" : "password"}
                  id="password"
                  placeholder="Password"
                  variant="outlined"
                  name="password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Tooltip>
              <FormHelperText>
                {formik.touched.password && formik.errors.password ? (
                  <Typography sx={{ color: "red", fontSize: "13px" }}>
                    {formik.errors.password}
                  </Typography>
                ) : (
                  <Typography sx={{ fontSize: "13px", visibility: "hidden" }}>
                    none
                  </Typography>
                )}
              </FormHelperText>
              <Tooltip title="Re-enter your Password" arrow>
                <TextField
                  type={showConPassword ? "text" : "password"}
                  id="confirmPassword"
                  placeholder="Confirm Password"
                  variant="outlined"
                  name="confirmPassword"
                  value={formik.values.confirmPassword}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleClickShowConPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showConPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Tooltip>
              <FormHelperText>
                {formik.touched.confirmPassword &&
                formik.errors.confirmPassword ? (
                  <Typography sx={{ color: "red", fontSize: "13px" }}>
                    {formik.errors.confirmPassword}
                  </Typography>
                ) : (
                  <Typography sx={{ fontSize: "13px", visibility: "hidden" }}>
                    none
                  </Typography>
                )}
              </FormHelperText>
              {/* <Tooltip title="Enter your Cv/Resume link" arrow>
                <TextField
                  id="cv"
                  placeholder="Cv/Resume link"
                  variant="outlined"
                  name="cv"
                  value={formik.values.cv}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  fullWidth
                />
              </Tooltip> */}
              {/* <Typography
                style={{
                  paddingTop: "13px",
                  fontFamily: "Inter",
                  fontSize: "14px",
                  fontWeight: "400",
                  lineHeight: "20px",
                  paddingBottom: "10px",
                }}
              >
                Upload your Cv/Resume
              </Typography> */}

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  textAlign: "center",
                  border: "2px dashed #C8CCD1",
                  padding: "1rem",
                }}
              >
                <div>
                  <IconButton component="span">
                    <Button
                      sx={{ color: "#7C00DE" }}
                      disabled={file ? true : false}
                      onClick={() => {
                        fileInputField.current.click();
                      }}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.file}
                    >
                      <CloudUploadOutlinedIcon sx={{ fontSize: 50 }} />
                    </Button>
                  </IconButton>
                  <p>
                    Browse File{" "}
                    <span style={{ color: "#474C54" }}>or drop here</span>
                  </p>
                  <text style={{ color: "#5E6670" }}>
                    Only pdf,docx,doc format available. Max file size 2 MB.
                  </text>
                </div>
              </div>
              {formik.errors.file && formik.touched.file && (
                <FormHelperText sx={{ color: "red", fontSize: "13px" }}>
                  {formik.errors.file}
                </FormHelperText>
              )}
              {file ? (
                <div
                  style={{ marginTop: "1rem" }}
                  className="uploadfilenames-and-removefilebtn"
                >
                  <div
                    className={
                      resume
                        ? "filename-uploadresume"
                        : "filename-uploadresume-error"
                    }
                  >{`${file}`}</div>
                  <div>
                    <Button
                      variant="contained"
                      size="small"
                      className="removefile-btn"
                      onClick={() => {
                        deleteFile();
                      }}
                      endIcon={<CloseIcon sx={{ color: "#FF3434" }} />}
                    >
                      Remove Resume
                    </Button>
                  </div>
                </div>
              ) : null}
              <br />

              <div
                style={{
                  display: "flex",
                  marginTop: "-13px",
                  flexDirection: "column",
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Checkbox
                    name="terms"
                    checked={formik.values.terms}
                    onChange={formik.handleChange}
                  />
                  <p>
                    I've read and agree with your{" "}
                    <span
                      style={{
                        font: "Inter",
                        fontSize: "16px",
                        color: "#0A65CC",
                        cursor: "pointer",
                        fontWeight: "500",
                        lineHeight: "17.36px",
                      }}
                      onClick={() => {
                        navigate(`/${ROUTINGDATA.TERMSANDCONDITION}`);
                        window.scrollTo(0, 0);
                      }}
                    >
                      {" "}
                      Terms and Conditions
                    </span>
                  </p>
                </div>

                <FormHelperText>
                  {formik.touched.terms && formik.errors.terms ? (
                    <Typography
                      sx={{
                        color: "red",
                        fontSize: "13px",
                        marginTop: "-10px",
                      }}
                    >
                      {formik.errors.terms}
                    </Typography>
                  ) : (
                    <Typography sx={{ fontSize: "13px", visibility: "hidden" }}>
                      none
                    </Typography>
                  )}
                </FormHelperText>
              </div>

              <div style={{ paddingBottom: "80px" }}>
                <Tooltip title="Enter your details and then create account successfully">
                  <Button
                    style={{
                      backgroundColor: "#7C00DE",
                      color: "white",
                      textTransform: "capitalize",
                      height: "48.78px",
                      width: "100%",
                    }}
                    fullWidth
                    type="submit"
                    // onClick={() => {
                    //   console.log(formik.values, formik.errors);
                    // }}
                  >
                    Create account → <LoadingButton loading={buttonLoading} />
                  </Button>
                </Tooltip>
              </div>
            </form>
          </div>
        </div>

        <div
          className="second-div"
          style={{
            height: "100vh",
            width: "50%",
            position: "relative",
            overflow: "hidden",
          }}
        >
          <div
            style={{
              height: "100%",
              width: "100%",
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              overflow: "hidden",
            }}
          >
            <img
              src={newemployer}
              alt="login"
              style={{
                height: "110%",
                width: "110%",
                objectFit: "cover",
                transform: "rotate(4deg)",
                position: "absolute",
                top: "-5%",
                right: "-12%",
                zIndex: 1,
              }}
            />
            <div
              style={{
                height: "100%",
                width: "100%",
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                background:
                  "linear-gradient(180deg, rgba(122, 96, 142, 0.345) 0%, rgba(59, 7, 100, 0.69) 100%)",
                zIndex: 2,
                pointerEvents: "none",
              }}
            />
          </div>
          <div
            style={{
              position: "absolute",
              bottom: "20%",
              left: "10%",
              color: "white",
              fontSize: "1.5em",
              fontWeight: "bold",
              textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)",
              zIndex: 3,
            }}
          >
            <Typography
              style={{
                font: "Inter",
                fontSize: "30px",
                fontWeight: "500",
                lineHeight: "36px",
              }}
            >
              Over 1,75,324 candidates
            </Typography>
            <Typography
              style={{
                font: "Inter",
                fontSize: "30px",
                fontWeight: "500",
                lineHeight: "36px",
              }}
            >
              waiting for good employers.
            </Typography>
            <div
              style={{
                display: "flex",
                gap: "50px", // Space between the cards
                marginTop: "20px", // Space between text and cards
                alignItems: "center",
              }}
            >
              <div style={{ textAlign: "center" }}>
                <Card
                  style={{
                    backgroundColor: "rgba(255, 255, 255, 0.2)",
                    color: "white",
                    width: "60px",
                    height: "48px",
                    padding: "12px 0px 0px 0px",
                    borderRadius: "6px",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <BusinessCenterOutlinedIcon style={{ fontSize: "40px" }} />
                </Card>
                <Typography
                  style={{
                    font: "Inter",
                    fontSize: "15px",
                    fontWeight: "500",
                    lineHeight: "16px",
                    marginTop: "8px",
                    color: "white",
                  }}
                >
                  1,75,324
                </Typography>
                <Typography
                  style={{
                    font: "Inter",
                    fontSize: "15px",
                    fontWeight: "500",
                    lineHeight: "16px",
                    marginTop: "8px",
                    color: "white",
                  }}
                >
                  Live Jobs
                </Typography>
              </div>
              <div style={{ textAlign: "center" }}>
                <Card
                  style={{
                    backgroundColor: "rgba(255, 255, 255, 0.2)",
                    color: "white",
                    width: "60px",
                    height: "48px",
                    padding: "12px 0px 0px 0px",
                    borderRadius: "6px",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <BusinessOutlinedIcon style={{ fontSize: "40px" }} />
                </Card>
                <Typography
                  style={{
                    font: "Inter",
                    fontSize: "12px",
                    fontWeight: "500",
                    lineHeight: "16px",
                    marginTop: "8px",
                    color: "white",
                  }}
                >
                  97,354
                </Typography>
                <Typography
                  style={{
                    font: "Inter",
                    fontSize: "15px",
                    fontWeight: "500",
                    lineHeight: "16px",
                    marginTop: "8px",
                    color: "white",
                  }}
                >
                  Companies
                </Typography>
              </div>
              <div style={{ textAlign: "center" }}>
                <Card
                  style={{
                    backgroundColor: "rgba(255, 255, 255, 0.2)",
                    color: "white",
                    width: "60px",
                    height: "48px",
                    padding: "12px 0px 0px 0px",
                    borderRadius: "6px",

                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <BusinessCenterOutlinedIcon style={{ fontSize: "40px" }} />
                </Card>
                <Typography
                  style={{
                    font: "Inter",
                    fontSize: "12px",
                    fontWeight: "500",
                    lineHeight: "16px",
                    marginTop: "8px",
                    color: "white",
                  }}
                >
                  7,532
                </Typography>
                <Typography
                  style={{
                    font: "Inter",
                    fontSize: "15px",
                    fontWeight: "500",
                    lineHeight: "16px",
                    marginTop: "8px",
                    color: "white",
                  }}
                >
                  New Jobs
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",

              position: "relative",
              borderRadius: "16px",
              textAlign: "center",
              alignItems: "center",
            },
          },
        }}
      >
        <TaskAltIcon
          sx={{ fontSize: "70px", paddingTop: "25px", color: "#27AE60" }}
        />
        <DialogContent>
          <Typography
            style={{
              font0: "Roboto",
              fontSize: "30.43px",
              fontWeight: "700",
              lineHeight: "50.9px",
              color: "#27AE60",
            }}
          >
            Account Created Successfully!
          </Typography>
          <Typography
            style={{
              font0: "Roboto",
              fontSize: "25.43px",
              fontWeight: "600",
              lineHeight: "50.9px",
              color: "#4A4A4A",
            }}
          >
            Welcome to our Career365 portal!
          </Typography>
          <Typography
            style={{
              font0: "Roboto",
              fontSize: "22.43px",
              fontWeight: "300",
              color: "#4A4A4A",
            }}
          >
            Start exploring job opportunities and take the next step in your
            career journey.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            style={{
              backgroundColor: "#27AE60",
              color: "white",
              textTransform: "capitalize",
              height: "48.78px",
              paddingRight: "40px",
              paddingLeft: "40px",
              font: "Roboto",
              fontWeight: "600",
              fontSize: "23px",
            }}
            type="submit"
          >
            Continue
          </Button>
        </DialogActions>
        <br />
      </Dialog>
    </>
  );
};

export default JobSeekerSignup;
