import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  Table,
  TableContainer,
  TableCell,
  TableBody,
  TableRow,
  TableHead,
  Card,
  Tooltip,
  FormControlLabel,
  FormGroup,
  Switch,
  Skeleton,
  TablePagination,
  Grid,
  Typography,
  Paper,
  TextField,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

function PartnerCandidates() {
  const [loading, setLoading] = useState(false);
  const [PartnerCandidatesDetails, setPartnerCandidatesDetails] =
    useState(false);
  const [candidates, setCandidates] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(4);
  const [totalRows, setTotalRows] = useState(0);

  const urlc = `https://partnerapi.optimhire.com/v1/partner/get-candidates?`;

 

  useEffect(() => {
    const payload={
      page:page+1,
      size:rowsPerPage
    }
    const options2 = {
      method: "POST",
      headers: {
        Authorization:
          "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwczpcL1wvcGFydG5lcmFwaS5vcHRpbWhpcmUuY29tXC8iLCJhdWQiOiJodHRwczpcL1wvcGFydG5lcmFwaS5vcHRpbWhpcmUuY29tXC8iLCJpYXQiOjE3MjE4OTUwMDMsIm5iZiI6MTcyMTg5NTAwMywiZGF0YSI6eyJyZWZfdXNlcl9pZCI6IjQyMDY1OCIsInJlZl91c2VyX2lwIjoiMTEwLjIyNC44My4yMzYiLCJyZWZfZmlyc3RfbmFtZSI6IlVTIEdMT0JBTCAiLCJyZWZfbGFzdF9uYW1lIjoiU09MVVRJT05TIiwicmVmX3VzZXJfZW1haWwiOiJjZW9AdXNnbG9iYWxzb2x1dGlvbnMuY29tIiwicmVmX2ltYWdlIjpudWxsLCJyZWZfdXNlcl90eXBlIjoib3RoZXIifX0.K2P-lhIGCgcqA2V0CsCfsBXTBco_Vnbrl7xH8fSDtaM",
        Cookie: "ci_session=kat7urco4blrkm8u9p4r7nkeoe1une2v",
      },
      timeout: process.env.FETCH_TIMEOUT,
      body: JSON.stringify(payload)
    };

    setLoading(true);
    fetch(urlc , options2)
      .then((response) => response.json())
      .then(async (data1) => {
        setCandidates(data1.data.candidates || []);
        setTotalRows(data1.data.total_count || 0);
        setLoading(false);
      });
  }, [page, rowsPerPage]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset to first page when rows per page change
  };

  if (!loading) {
    return (
      <div>
        <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
          <Card sx={{ padding: "20px", mb: 8 }}>
            <Paper>
              <Box
                sx={{
                  padding: "20px",
                  display: "flex",
                  flexDirection: "row",
                  gap: "10px",
                  backgroundColor: "#f2f2f2",
                }}
              >
                <Grid container>
                  <Grid item xs={12}>
                    <TextField
                      sx={{ width: "30%" }}
                      id="outlined-basic"
                      label="Search"
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
                <div>
                  <Button
                    variant="contained"
                    style={{ margin: "10px 20px 0px 0px" }}
                    onClick={() => {
                      setPartnerCandidatesDetails(true);
                    }}
                  >
                    ADD
                  </Button>
                </div>
              </Box>
            </Paper>
            <TableContainer
              component={Paper}
              sx={{ marginTop: "10px", boxShadow: 0, maxHeight: 440 }}
            >
              <Table stickyHeader>
                <TableHead>
                  <TableRow className="table-row">
                    <TableCell>Name</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>Mobile</TableCell>
                    <TableCell>Resume URL</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {candidates.length > 0 ? (
                    candidates.map((candidate, index) => (
                      <TableRow key={index}>
                        <TableCell>{candidate.name}</TableCell>
                        <TableCell>{candidate.email}</TableCell>
                        <TableCell>{candidate.mobile}</TableCell>
                        <TableCell>{candidate.dev_resume}</TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell>
                        <Typography
                          variant="body1"
                          sx={{ fontFamily: "Barlow-Bold" }}
                        >
                          No Data Available
                        </Typography>
                      </TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50]}
              component="div"
              count={totalRows}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
            <Dialog
              sx={{
                "& .MuiPaper-root": {
                  width: "400px",
                },
              }}
              open={PartnerCandidatesDetails}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle
                id="alert-dialog-title"
                bgcolor="#055779"
                color="white"
              >
                {"Payment Vendors Details"}
              </DialogTitle>
              <DialogContent>
                <TextField
                  sx={{ marginTop: 3 }}
                  fullWidth
                  id="outlined-basic"
                  label="Name"
                  variant="outlined"
                />{" "}
                <TextField
                  sx={{ marginTop: 3 }}
                  fullWidth
                  id="outlined-basic"
                  label="email"
                  variant="outlined"
                />{" "}
                <TextField
                  sx={{ marginTop: 3 }}
                  fullWidth
                  id="outlined-basic"
                  label="Rederective URL"
                  variant="outlined"
                />
                <FormGroup sx={{ alignItems: "end" }}>
                  <FormControlLabel
                    control={<Switch defaultChecked />}
                    label="Active"
                  />
                </FormGroup>
              </DialogContent>
              <DialogActions>
                <Button
                  variant="outlined"
                  onClick={() => {
                    setPartnerCandidatesDetails(false);
                  }}
                  autoFocus
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  onClick={() => {
                    setPartnerCandidatesDetails(false);
                  }}
                  autoFocus
                >
                  Submit
                </Button>
              </DialogActions>
            </Dialog>
          </Card>
        </Box>
      </div>
    );
  } else {
    return (
      <>
        <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
          <Card sx={{ padding: "20px", borderRadius: "20px" }}>
            <Skeleton
              animation="wave"
              variant="text"
              width={230}
              height={40}
              sx={{ borderRadius: "15px" }}
            />
          </Card>
          <Card
            sx={{
              borderRadius: "20px",
              padding: "20px",
              height: "500px",
              gap: "20px",
            }}
          >
            <Skeleton variant="rounded" />
            <Skeleton variant="rounded" width="100%" height="300px" />
          </Card>
        </Box>
      </>
    );
  }
}

export default PartnerCandidates;
