// import { TableName } from "../Constant";
import { supabase } from "./useSupabase";

export const uploadImage = async (bucketName, images, isProduct) => {
  console.log("----", images);
  const publicUrls = [];

  if (!Array.isArray(images)) {
    images = [images];
  }

  for (const image of images) {
    const currentDateTime = new Date().toISOString().replace(/[-:.]/g, "");

    const uniqueName = `${currentDateTime}_${image.name}`;

    const { data, error } = await supabase.storage
      .from(bucketName)
      .upload(`${uniqueName}`, image);

    if (error) {
      console.error("Error uploading image:", error.message);
    } else {
      const publicUrl = supabase.storage
        .from(bucketName)
        .getPublicUrl(data.path);
      publicUrls.push(publicUrl.data.publicUrl);
    }
  }
  if (isProduct) {
    return publicUrls;
  } else {
    return publicUrls.length === 1 ? publicUrls[0] : null;
  }
};

export const removeImage = async (bucketName, imgUrl) => {
  let imageName = imgUrl?.split("/");
  if (imageName?.length > 0) {
    imageName = imageName[imageName.length - 1];
    const decodedStringImageName = decodeURIComponent(imageName);
    await supabase.storage.from(bucketName).remove([decodedStringImageName]);
  }
};

// export const getListData = async (listName) => {
//   const { data } = await supabase
//     .from(TableName.LIST)
//     .select("listItem, listDescription")
//     .eq("listName", listName)
//     .eq("IsActive", "Yes");

//   return data;
// };
