import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import { ROUTINGDATA } from "../ROUTINGDATA";
import useAxios from "../../api/useAxios";
import Container from "@mui/material/Container";
import { useTranslation } from "react-i18next";
import { useContext } from "react";
import { CurrentStateContext } from "../../App";
import "./TermsAndCondition.css";
import { ReactComponent as TwitterX } from "../../img/twitter-x-seeklogo.com-4.svg";

const PricingPolicy = () => {
  const axiosData = useAxios();
  const { languagetranslator } = useContext(CurrentStateContext);
  const { t, i18n, ready } = useTranslation();

  const navigate = useNavigate();

  const changeLanguage = (languagetranslator) => {
    i18n.changeLanguage(languagetranslator);
  };
  useEffect(() => {
    changeLanguage(languagetranslator);
  }, [languagetranslator]);

  const lang = sessionStorage.getItem("lang");

  if (ready) {
    return (
      <div style={{ padding: "5rem 0" }}>
        <Container>
          <div className="TermsAndConditionBackpagefonts">
            <span
              className="Termsbackbtn"
              onClick={() => navigate(ROUTINGDATA.LAND)}
              style={{ color: "#7C00DE" }}
            >
              {t("Home")}
            </span>
            / <span style={{ color: "black" }}>Pricing Policy</span>
          </div>
          <div>
            <h2>Pricing Policy</h2>
            <h3>Job Posting Fees:</h3>
            <p>
              Charge employers a fee for posting job listings on{" "}
              {process.env.REACT_APP_WEBSITE_NAME}
              {process.env.REACT_APP_WEBSITE_NAME_TYPE}. This fee can vary
              depending on factors like the job's duration, visibility, or
              location. Consider offering different pricing tiers for job
              postings, such as standard, and premium with varying levels of
              visibility and features.
            </p>
            <h3>Subscription Plans for Employers:</h3>
            <p>
              Offer subscription plans for employers that allow them to post
              multiple job listings within a certain period for a fixed monthly.
              Differentiate subscription plans based on the number of job
              postings allowed, the level of support.
            </p>
          </div>
          <div>
            <h3>Bundle Packages:</h3>
            <p>
              Create bundled packages that combine various services like job
              postings, urgent jobs showing frequently and advertising at a
              discounted rate.
            </p>
          </div>
          <div>
            <h3>Discounts and Promotions:</h3>
            <p>
              Run occasional promotions or discounts to attract new users or
              retain existing ones.
            </p>
          </div>
          <div>
            <h3>Pay-as-You-Go Models:</h3>
            <p>
              Allow users to pay only for the services they use without
              subscribing to long-term plans.
            </p>
          </div>
          <div>
            <h3>Ad Size Categories:</h3>
            <p>
              Different ad size categories based on standard dimensions. Common
              ad sizes include Popup Image(670px X 500px), Side Image
              Right(120px X 600px), Side Image Left(120px X 600px), Center
              Image(1092px X 275px), Flash Image(250px X 250px).
            </p>
            <h3>Time Duration Options:</h3>
            <p>
              1 week, 2 weeks, 3 weeks, 4 weeks, 15 days or 30 days time
              durations for advertisers can purchase ad slots.
            </p>
          </div>
        </Container>
      </div>
    );
  } else {
    return (
      <div
        style={{
          height: "100vh",
          display: "grid",
          placeItems: "center",
          // transform: "translate(0,50vh)",
        }}
      >
        <div class="dots"></div>
      </div>
    );
  }
};

export default PricingPolicy;
