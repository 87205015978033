import axios from "axios";

const getAxiosInstance = () => {
  const getAxios = axios.create({
    baseURL: "https://career-services-1.onrender.com/p-services/",
  });

  return getAxios;
};
const useAxios = () => {
  const useAxiosData = getAxiosInstance();
  return useAxiosData;
};

export default useAxios;
