import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "../Login.css";
import Box from "@mui/material/Box";
import MailLockRoundedIcon from "@mui/icons-material/MailLockRounded";
import useAxios from "../../../api/useAxios";
import ErrorSharpIcon from "@mui/icons-material/ErrorSharp";
import { ROUTINGDATA } from "../../ROUTINGDATA";
import bgImg from "../../../img/login image/job-search.jpg";
import newcareerlogo from "../../../img/newcareerlogo.png";
import BusinessCenterOutlinedIcon from "@mui/icons-material/BusinessCenterOutlined";
import BusinessOutlinedIcon from "@mui/icons-material/BusinessOutlined";
import { Link } from "react-router-dom";
import newforget from "../../../img/newforget.png";
import CancelIcon from "@mui/icons-material/Cancel";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  Button,
  IconButton,
  TextField,
  Grid,
  Typography,
  FormHelperText,
  Tooltip,
  Card,
  useMediaQuery,
} from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import Divider from "@mui/material/Divider";
import logo from "../../../img/logo_for-landingpage-3.png";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

function OtpforPassword() {
  const isMobile = useMediaQuery("(max-width:600px)");
  const axiosData = useAxios();
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const [openWrongOTP, setOpenWrongOTP] = React.useState(false);
  const handleClickOpenWrongOTP = () => {
    setOpenWrongOTP(true);
  };
  const handleCloseWrongOTP = () => {
    setOpenWrongOTP(false);
  };
  const [otp, setOtp] = useState();
  const [minutes, setMinutes] = useState(1);
  const [seconds, setSeconds] = useState(30);
  let location = useLocation();
  const currentUrl = location.pathname;
  const formik = useFormik({
    initialValues: {},
    validationSchema: Yup.object({
      otp: Yup.string()
        .matches(/^[0-9]+$/, "Must be only digits")
        .required("Required"),
    }),
    onSubmit: async (values) => {
      try {
        const dataaxios = await axiosData.get(
          `password/${
            location.state.email_id === process.env.REACT_APP_EMAILID_FOR_ADMIN
              ? process.env.REACT_APP_OTP_EMAIL_FOR_ADMIN
              : location.state.email_id
          }`,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        const otpvalue = dataaxios.data.otp;
        if (otpvalue == values.otp) {
          setOtp(otp);
          navigate(`${ROUTINGDATA.LAND}${ROUTINGDATA.ForgotPwOtp}`, {
            state: { email_id: location.state.email_id },
          });
          try {
            await axiosData.delete(
              `password/${
                location.state.email_id ===
                process.env.REACT_APP_EMAILID_FOR_ADMIN
                  ? process.env.REACT_APP_OTP_EMAIL_FOR_ADMIN
                  : location.state.email_id
              }`,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }
            );
          } catch (err) {
            console.log(err);
          }
        } else if (
          dataaxios.data.otp === undefined ||
          dataaxios.data.otp === null
        ) {
          handleClickOpen();
        } else {
          handleClickOpenWrongOTP();
        }
      } catch (err) {
        console.log(err);
      }
    },
  });
  useEffect(() => {
    const interval = setInterval(async () => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          try {
            await axiosData.delete(
              `password/${
                location.state.email_id ===
                process.env.REACT_APP_EMAILID_FOR_ADMIN
                  ? process.env.REACT_APP_OTP_EMAIL_FOR_ADMIN
                  : location.state.email_id
              }`,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }
            );
          } catch (err) {
            console.log(err);
          }
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [seconds]);

  useEffect(() => {
    window.history.forward();
    const url = currentUrl;
    window.onbeforeunload = function () {
      try {
        axiosData.delete(
          `password/${
            location.state.email_id === process.env.REACT_APP_EMAILID_FOR_ADMIN
              ? process.env.REACT_APP_OTP_EMAIL_FOR_ADMIN
              : location.state.email_id
          }`,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
      } catch (err) {
        console.log(err);
      }
      return "Are you sure?";
    };
  }, []);
  const resendOTP = async () => {
    setMinutes(1);
    setSeconds(30);
    const email_id = `${
      location.state.email_id === process.env.REACT_APP_EMAILID_FOR_ADMIN
        ? process.env.REACT_APP_OTP_EMAIL_FOR_ADMIN
        : location.state.email_id
    }`;
    try {
      await axiosData.post("password", { email_id });
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <React.Fragment>
      {/* <div className="forgotpassword-input-box">
          <form onSubmit={formik.handleSubmit}>
            <div className="login-reg">
              <p>
                Welcome to{" "}
                <span>
                  <text>{process.env.REACT_APP_WEBSITE_NAME_LOGIN1}</text>
                  {process.env.REACT_APP_WEBSITE_NAME_LOGIN2}
                </span>
              </p>
            </div>
            <Divider />
            <div className="login-space">
              <div className="login-title">
                <h2>Forgot Password</h2>
              </div>
              <div className="forgotpassword-contect">
                <p>
                  Enter your correct Otp and then only you can change your
                  password
                </p>
              </div>
              <div className="space-forgotpassword">
                <div className="login-textfield-1">
                  <label>Enter Otp</label>
                  <TextField
                    id="arrow-hide-otp-page"
                    variant="outlined"
                    size="small"
                    type="number"
                    className="arrow-hide-otp-page"
                    placeholder="- - - -"
                    inputProps={{
                      maxLength: 4,
                    }}
                    name="otp"
                    onInput={(e) => {
                      e.target.value = Math.max(0, parseInt(e.target.value))
                        .toString()
                        .slice(0, 4);
                    }}
                    onChange={formik.handleChange}
                    value={formik.values.otp}
                    onBlur={formik.handleBlur}
                    fullWidth
                  />
                  <FormHelperText>
                    {seconds > 0 || minutes > 0 ? (
                      <Typography
                        sx={{
                          fontSize: "12px",
                          fontFamily: " Poppins-Regular",
                        }}
                      >
                        Time Remaining: {minutes < 10 ? `0${minutes}` : minutes}
                        :{seconds < 10 ? `0${seconds}` : seconds}
                      </Typography>
                    ) : (
                      <Typography
                        sx={{
                          fontSize: "12px",
                          fontFamily: " Poppins-Regular",
                        }}
                      >
                        "Didn't receive OTP?"
                        <span
                          onClick={resendOTP}
                          style={{
                            color: "red",
                            marginLeft: "5px",
                            cursor: "pointer",
                          }}
                        >
                          Resend OTP
                        </span>
                      </Typography>
                    )}
                  </FormHelperText>
                </div>
                <div className="loginbtn">
                  <Button
                    variant="contained"
                    disabled={formik.isValid && formik.dirty ? false : true}
                    fullWidth
                    type="submit"
                  >
                    Submit
                  </Button>
                </div>
                <div className="back-to-loginpage">
                  <ArrowBackIosIcon
                    fontSize="13px"
                    sx={{ color: "#2b7695", visibility: "hidden" }}
                  />
                  <text style={{ visibility: "hidden" }}>Back to Login</text>
                </div>
              </div>
            </div>
          </form>
        </div> */}
      <div
        style={{
          height: "100vh",
          width: "100%",
          display: "flex",
          flexDirection: "row",
          overflow: "hidden",
        }}
      >
        <div
          className="first-div"
          style={{
            height: "100vh",
            width: "50%",
            padding: "80px",
            paddingTop: "40px",
          }}
        >
          <Link to="/forgotpassword">
            <IconButton>
              <ArrowBackIcon />
            </IconButton>
          </Link>
          <div
            style={{ paddingTop: "60px", paddingLeft: isMobile ? "" : "42px" }}
          >
            <form onSubmit={formik.handleSubmit}>
              <img src={newcareerlogo} alt="logo" />
              <Typography
                style={{
                  font: "Inter",
                  fontSize: "27.78px",
                  fontWeight: "500",
                  lineHeight: "34.72px",
                  paddingTop: "30px",
                }}
              >
                Forget Password
              </Typography>
              <Typography
                style={{
                  font: "Inter",
                  fontSize: "13.89px",
                  fontWeight: "400",
                  lineHeight: "20.83px",
                  color: " #5E6670",
                  paddingTop: "10px",
                }}
              >
                Go back to
                <Link
                  to="/login"
                  style={{
                    color: "#0A65CC",
                    font: "Inter",
                    fontWeight: "500",
                    paddingLeft: "3px",
                  }}
                >
                  Sign In
                </Link>
              </Typography>
              <Typography
                style={{
                  font: "Inter",
                  fontSize: "13.89px",
                  fontWeight: "400",
                  lineHeight: "20.83px",
                  color: " #5E6670",
                  paddingTop: "10px",
                }}
              >
                Don’t have an account ?
                <Link
                  to="/JobSeekerSignUp"
                  style={{
                    color: "#0A65CC",
                    font: "Inter",
                    fontWeight: "500",
                    paddingLeft: "5px",
                  }}
                >
                  Create Account
                </Link>
              </Typography>
              <div>
                <div className="login-textfield">
                  <div className="login-textfield-1">
                    <Tooltip title="Enter your otp" arrow>
                      <TextField
                        style={{ paddingTop: "50px", width: "85%" }}
                        id="arrow-hide-otp-page"
                        variant="outlined"
                        type="number"
                        className="arrow-hide-otp-page"
                        placeholder="OTP"
                        inputProps={{
                          maxLength: 4,
                        }}
                        name="otp"
                        onInput={(e) => {
                          e.target.value = Math.max(0, parseInt(e.target.value))
                            .toString()
                            .slice(0, 4);
                        }}
                        onChange={formik.handleChange}
                        value={formik.values.otp}
                        onBlur={formik.handleBlur}
                        fullWidth
                      />
                    </Tooltip>
                    <FormHelperText>
                      {seconds > 0 || minutes > 0 ? (
                        <Typography
                          sx={{
                            fontSize: "12px",
                            fontFamily: " Poppins-Regular",
                          }}
                        >
                          Time Remaining:{" "}
                          {minutes < 10 ? `0${minutes}` : minutes}:
                          {seconds < 10 ? `0${seconds}` : seconds}
                        </Typography>
                      ) : (
                        <Typography
                          sx={{
                            fontSize: "12px",
                            fontFamily: " Poppins-Regular",
                          }}
                        >
                          "Didn't receive OTP?"
                          <span
                            onClick={resendOTP}
                            style={{
                              color: "red",
                              marginLeft: "5px",
                              cursor: "pointer",
                            }}
                          >
                            Resend OTP
                          </span>
                        </Typography>
                      )}
                    </FormHelperText>
                  </div>
                  <div className="loginbtn">
                    <Button
                      sx={{
                        backgroundColor: "#7C00DE",
                        color: "white",
                        textTransform: "capitalize",
                        height: "48.78px",
                        width: "85%",
                        "&:disabled": {
                          backgroundColor: "F5F5F5",
                          color: "F5F5F5",
                        },
                      }}
                      variant="contained"
                      disabled={!(formik.isValid && formik.dirty)}
                      fullWidth
                      type="submit"
                    >
                      Submit →
                    </Button>
                  </div>
                  <div className="back-to-loginpage">
                    <ArrowBackIosIcon
                      fontSize="13px"
                      sx={{ color: "#2b7695", visibility: "hidden" }}
                    />
                    <text style={{ visibility: "hidden" }}>Back to Login</text>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div
          className="second-div"
          style={{
            height: "100vh",
            width: "50%",
            position: "relative",
            overflow: "hidden",
          }}
        >
          <div
            style={{
              height: "100%",
              width: "100%",
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              overflow: "hidden",
            }}
          >
            <img
              src={newforget}
              alt="forgot"
              style={{
                height: "110%",
                width: "110%",
                objectFit: "cover",
                transform: "rotate(4deg)",
                position: "absolute",
                top: "-5%",
                right: "-12%",
                zIndex: 1,
              }}
            />
            <div
              style={{
                height: "100%",
                width: "100%",
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                background:
                  "linear-gradient(180deg, rgba(122, 96, 142, 0.345) 0%, rgba(59, 7, 100, 0.69) 100%)",
                zIndex: 2,
                pointerEvents: "none",
              }}
            />
          </div>
          <div
            style={{
              position: "absolute",
              bottom: "20%",
              left: "10%",
              color: "white",
              fontSize: "1.5em",
              fontWeight: "bold",
              textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)",
              zIndex: 3,
            }}
          >
            <Typography
              style={{
                font: "Inter",
                fontSize: "30px",
                fontWeight: "500",
                lineHeight: "36px",
              }}
            >
              Over 1,75,324 candidates
            </Typography>
            <Typography
              style={{
                font: "Inter",
                fontSize: "30px",
                fontWeight: "500",
                lineHeight: "36px",
              }}
            >
              waiting for good employers.
            </Typography>
            <div
              style={{
                display: "flex",
                gap: "50px", // Space between the cards
                marginTop: "20px", // Space between text and cards
                alignItems: "center",
              }}
            >
              <div style={{ textAlign: "center" }}>
                <Card
                  style={{
                    backgroundColor: "rgba(255, 255, 255, 0.2)",
                    color: "white",
                    width: "60px",
                    height: "48px",
                    padding: "12px 0px 0px 0px",
                    borderRadius: "6px",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <BusinessCenterOutlinedIcon style={{ fontSize: "40px" }} />
                </Card>
                <Typography
                  style={{
                    font: "Inter",
                    fontSize: "15px",
                    fontWeight: "500",
                    lineHeight: "16px",
                    marginTop: "8px",
                    color: "white",
                  }}
                >
                  1,75,324
                </Typography>
                <Typography
                  style={{
                    font: "Inter",
                    fontSize: "15px",
                    fontWeight: "500",
                    lineHeight: "16px",
                    marginTop: "8px",
                    color: "white",
                  }}
                >
                  Live Jobs
                </Typography>
              </div>
              <div style={{ textAlign: "center" }}>
                <Card
                  style={{
                    backgroundColor: "rgba(255, 255, 255, 0.2)",
                    color: "white",
                    width: "60px",
                    height: "48px",
                    padding: "12px 0px 0px 0px",
                    borderRadius: "6px",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <BusinessOutlinedIcon style={{ fontSize: "40px" }} />
                </Card>
                <Typography
                  style={{
                    font: "Inter",
                    fontSize: "12px",
                    fontWeight: "500",
                    lineHeight: "16px",
                    marginTop: "8px",
                    color: "white",
                  }}
                >
                  97,354
                </Typography>
                <Typography
                  style={{
                    font: "Inter",
                    fontSize: "15px",
                    fontWeight: "500",
                    lineHeight: "16px",
                    marginTop: "8px",
                    color: "white",
                  }}
                >
                  Companies
                </Typography>
              </div>
              <div style={{ textAlign: "center" }}>
                <Card
                  style={{
                    backgroundColor: "rgba(255, 255, 255, 0.2)",
                    color: "white",
                    width: "60px",
                    height: "48px",
                    padding: "12px 0px 0px 0px",
                    borderRadius: "6px",

                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <BusinessCenterOutlinedIcon style={{ fontSize: "40px" }} />
                </Card>
                <Typography
                  style={{
                    font: "Inter",
                    fontSize: "12px",
                    fontWeight: "500",
                    lineHeight: "16px",
                    marginTop: "8px",
                    color: "white",
                  }}
                >
                  7,532
                </Typography>
                <Typography
                  style={{
                    font: "Inter",
                    fontSize: "15px",
                    fontWeight: "500",
                    lineHeight: "16px",
                    marginTop: "8px",
                    color: "white",
                  }}
                >
                  New Jobs
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Dialog
        open={open}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",

              position: "relative",
              borderRadius: "16px",
              textAlign: "center",
              alignItems: "center",
            },
          },
        }}
      >
        <CancelIcon
          sx={{ fontSize: "70px", paddingTop: "25px", color: "red" }}
        />
        <DialogContent>
          <Typography
            style={{
              font0: "Roboto",
              fontSize: "30.43px",
              fontWeight: "700",
              lineHeight: "50.9px",
              color: "#27AE60",
            }}
          >
            User has entered expired OTP!
          </Typography>
          <Typography
            style={{
              font0: "Roboto",
              fontSize: "22.43px",
              fontWeight: "300",
              color: "#4A4A4A",
            }}
          >
            Please click Resend OTP and enter OTP again.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            style={{
              backgroundColor: "#7C00DE",
              color: "white",
              textTransform: "capitalize",
              height: "48.78px",
              paddingRight: "40px",
              paddingLeft: "40px",
              font: "Roboto",
              fontWeight: "600",
              fontSize: "23px",
            }}
            onClick={() => {
              formik.handleReset("");
              handleClose();
            }}
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openWrongOTP}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              position: "relative",
              borderRadius: "16px",
              textAlign: "center",
              alignItems: "center",
            },
          },
        }}
      >
        <CancelIcon
          sx={{ fontSize: "70px", paddingTop: "25px", color: "red" }}
        />
        <DialogContent>
          <Typography
            style={{
              font0: "Roboto",
              fontSize: "30.43px",
              fontWeight: "700",
              lineHeight: "50.9px",
              color: "#27AE60",
            }}
          >
            User has entered Wrong OTP!
          </Typography>
          <Typography
            style={{
              font0: "Roboto",
              fontSize: "22.43px",
              fontWeight: "300",
              color: "#4A4A4A",
            }}
          >
            Please enter correct OTP.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            style={{
              backgroundColor: "#7C00DE",
              color: "white",
              textTransform: "capitalize",
              height: "48.78px",
              paddingRight: "40px",
              paddingLeft: "40px",
              font: "Roboto",
              fontWeight: "600",
              fontSize: "23px",
            }}
            onClick={() => {
              formik.handleReset("");
              handleCloseWrongOTP();
            }}
          >
            Ok
          </Button>
        </DialogActions>
        {/* <Box>
          <DialogTitle sx={{ fontFamily: "Poppins-SemiBold" }}>
            <div style={{ display: "flex", gap: "3px", alignItems: "center" }}>
              <ErrorSharpIcon
                sx={{ mt: -0.3, color: "crimson" }}
                fontSize="large"
              />
              <Typography variant="h5" sx={{ ml: 0.3 }}>
                User has entered Wrong OTP!
              </Typography>
            </div>
          </DialogTitle>
          <DialogContent>
            <DialogContentText
              sx={{ fontFamily: "Poppins-Medium", color: "crimson" }}
            >
              Please enter correct OTP.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              variant="outlined"
              sx={{
                textTransform: "lowercase",
                color: "#ed1067",
                borderColor: "#ed1067",
                fontFamily: "Poppins-Medium",
                "&:hover": {
                  textTransform: "lowercase",
                  color: "white",
                  backgroundColor: "#ed1067",
                  borderColor: "#ed1067",
                  fontFamily: "Poppins-Medium",
                },
              }}
              onClick={() => {
                formik.handleReset("");
                handleCloseWrongOTP();
              }}
            >
              Ok
            </Button>
          </DialogActions>
        </Box> */}
      </Dialog>
    </React.Fragment>
  );
}

export default OtpforPassword;
