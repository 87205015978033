import React from "react";
import CircularProgress from "@mui/material/CircularProgress";

const LoadingButton = ({ loading, children }) => {
  return (
    <>
      {loading ? (
        <CircularProgress size={20} sx={{ color: "white", ml: 1 }} />
      ) : (
        children
      )}
    </>
  );
};

export default LoadingButton;
