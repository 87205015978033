import React from "react";
import { Grid, Typography, Divider, IconButton } from "@mui/material";
import logo from "../../../img/New Design Images/career365 logo1.png";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import YouTubeIcon from "@mui/icons-material/YouTube";
import InstagramIcon from "@mui/icons-material/Instagram";
import { useTheme, useMediaQuery } from "@mui/material";
import { ROUTINGDATA } from "../../ROUTINGDATA";
import { useNavigate } from "react-router-dom";

function NewFooter() {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const iconStyle = {
    color: "white",
    backgroundColor: "rgba(255, 255, 255, 0.1)",
    borderRadius: "50%",
    padding: "10px",
    margin: "0 5px",
  };
  const currentYear = new Date().getFullYear();
  const iconSize = { fontSize: "15px" };
  return (
    <div style={{ textAlign: isMobile ? "center" : "left" }}>
      <Grid
        container
        spacing={2}
        sx={{
          backgroundColor: "#240041",
          color: "white",
          padding: isMobile ? "1rem" : "3rem 5rem 2rem 5rem",
        }}
      >
        <Grid container item xs={12} spacing={3}>
          <Grid
            item
            md={5}
            xs={12}
            sx={{ paddingRight: isMobile ? 0 : "3rem" }}
          >
            <img src={logo} alt="logo" />
            <Typography
              variant="body2"
              sx={{ paddingRight: isMobile ? 0 : "3rem" }}
            >
              Great platform for job seekers who are passionate about work. Find
              your dream job more easily.
            </Typography>
          </Grid>
          <Grid item md={4} xs={12}>
            <p style={{ fontWeight: "bold" }}>Quick links</p>
            <button
              style={{
                cursor: "pointer",
                color: "white",
                background: "none",
                border: "none",
                padding: 0,
                font: "inherit",
              }}
              onClick={() => {
                navigate(ROUTINGDATA.NewContentPage);
                window.scroll(0, 0);
              }}
            >
              Home
            </button>
            <br />
            <button
              style={{
                marginTop: "15px",
                cursor: "pointer",
                color: "white",
                background: "none",
                border: "none",
                padding: 0,
                font: "inherit",
              }}
              onClick={() => {
                navigate(ROUTINGDATA.NewFindJobsPage);
                window.scroll(0, 0);
              }}
            >
              Find Jobs
            </button>
            <br />
            <button
              style={{
                marginTop: "15px",
                cursor: "pointer",
                color: "white",
                background: "none",
                border: "none",
                padding: 0,
                font: "inherit",
              }}
              onClick={() => {
                navigate(ROUTINGDATA.LANDPAGECONTACTUS);
                window.scroll(0, 0);
              }}
            >
              Contact Us
            </button>
          </Grid>
          <Grid item md={3} xs={12}>
            <p style={{ fontWeight: "bold" }}>Policies</p>
            <p
              style={{ cursor: "pointer" }}
              onClick={() => {
                navigate(ROUTINGDATA.TERMSANDCONDITION);
                window.scrollTo(0, 0);
              }}
            >
              Terms and conditions
            </p>
            <p
              style={{ cursor: "pointer" }}
              onClick={() => {
                navigate(ROUTINGDATA.PrivacyPolicy);
                window.scrollTo(0, 0);
              }}
            >
              Privacy Policy
            </p>
            <p
              style={{ cursor: "pointer" }}
              onClick={() => {
                navigate(ROUTINGDATA.PurchasePolicy);
                window.scrollTo(0, 0);
              }}
            >
              Purchase Policy
            </p>
            <p
              style={{ cursor: "pointer" }}
              onClick={() => {
                navigate(ROUTINGDATA.PricingPolicy);
                window.scrollTo(0, 0);
              }}
            >
              Pricing Policy
            </p>
            <p
              style={{ cursor: "pointer" }}
              onClick={() => {
                navigate(ROUTINGDATA.RefundPolicy);
                window.scrollTo(0, 0);
              }}
            >
              Refund Policy
            </p>
          </Grid>
          <Grid item md={3}></Grid>
        </Grid>

        <Grid item xs={12}>
          <Divider
            sx={{ borderColor: "rgba(255, 255, 255, 0.3)", margin: "1rem 0" }}
          />
          <div
            style={
              isMobile
                ? { display: "block" }
                : { display: "flex", justifyContent: "space-between" }
            }
          >
            <Typography variant="body2">
              {currentYear} @career365. All rights reserved.
            </Typography>
            <div style={{ marginTop: isMobile ? "1rem" : 0 }}>
              <IconButton style={iconStyle}>
                <FacebookIcon style={iconSize} />
              </IconButton>
              <IconButton style={iconStyle}>
                <TwitterIcon style={iconSize} />
              </IconButton>
              <IconButton style={iconStyle}>
                <LinkedInIcon style={iconSize} />
              </IconButton>
              <IconButton style={iconStyle}>
                <YouTubeIcon style={iconSize} />
              </IconButton>
              <IconButton style={iconStyle}>
                <InstagramIcon style={iconSize} />
              </IconButton>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default NewFooter;
