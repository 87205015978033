import React, { useEffect, useState } from "react";
import Skeleton from "@mui/material/Skeleton";
import {
  Container,
  Grid,
  Box,
  Typography,
  TextField,
  Button,
  Checkbox,
  FormControlLabel,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Pagination,
  useMediaQuery,
  useTheme,
  Drawer,
} from "@mui/material";
import PlaceOutlinedIcon from "@mui/icons-material/PlaceOutlined";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SearchIcon from "@mui/icons-material/Search";
import FmdGoodOutlinedIcon from "@mui/icons-material/FmdGoodOutlined";
import List from "@mui/icons-material/List";
import GridView from "@mui/icons-material/GridView";
import { FormControl, InputAdornment } from "@mui/material";
import Card from "@mui/material/Card";
import Autocomplete from "@mui/material/Autocomplete";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { Chip } from "@mui/material-next";
import useAxios from "../../../api/useAxios";
import { ROUTINGDATA } from "../../ROUTINGDATA";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import CloseIcon from "@mui/icons-material/Close";
import { useLocation } from "react-router-dom";
import SearchOffIcon from "@mui/icons-material/SearchOff";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const CustomMenu = styled(Menu)(({ theme }) => ({
  "& .MuiMenuItem-root": {
    color: "gray", // Option color
  },
  "& .MuiListItemIcon-root": {
    color: "purple", // Arrow color
  },
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 5),
  ...theme.mixins.toolbar,
  justifyContent: "space-around",
}));

function NewFindJobsPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const search = location.search;
  const params = new URLSearchParams(search);
  const [anchorEl, setAnchorEl] = useState(null);
  const axiosData = useAxios();
  const [selectedOption, setSelectedOption] = useState("Most relevant");
  const [filters, setfilters] = useState();
  const [loading, setloading] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState({});
  const [searchKeyword, setSearchKeyword] = useState("");
  const [searchLocation, setSearchLocation] = useState("");
  const [localKeyword, setLocalKeyword] = useState("");
  const [localLocation, setLocalLocation] = useState("");
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  function convertToLakhs(amount) {
    // Check if the amount is a number
    if (isNaN(amount)) {
      return "Invalid number";
    }

    // Convert the amount to lakhs
    const lakhs = amount / 100000;

    // Format the number with commas and two decimal places
    return (
      lakhs.toLocaleString("en-IN", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }) + " lakhs"
    );
  }
  const handleClose = (option) => {
    if (option) setSelectedOption(option);
    setAnchorEl(null);
  };
  let seekerDetails = JSON.parse(localStorage.getItem("seeker"));
  useEffect(() => {
    const getfilters = async () => {
      try {
        const response = await axiosData.get(`jobs/get/filterlist`, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        if (response.data) {
          console.log(response.data);
          setfilters(response.data);
        }
        if (response.status === 204) {
        }
      } catch (err) {
        // alert("No jobs for your search");

        console.log(err);
      }
    };
    getfilters();
  }, []);
  const accordionData = [
    {
      id: "location_type",
      title: "Type of Employment",
      options:
        filters &&
        filters.locationTypeCounts
          .filter((elements) => elements.location_type)
          .map((elements) => elements.location_type),
    },
    {
      id: "Categories",
      title: "Categories",
      options: [
        "Design",
        "Sales",
        "Marketing",
        "Business",
        "Human Resource",
        "Finance",
        "Engineering",
        "Technology",
      ],
    },
    {
      id: "job_type",
      title: "Job Type",
      options:
        filters && filters.jobTypeCounts.map((elements) => elements.job_type),
    },
    {
      id: "salaryRange",
      title: "Salary Range",
      options:
        filters &&
        filters.salaryRangeCounts.map((elements) => elements.salary_range),
    },
  ];

  // Initialize state with all panels expanded
  const [expanded, setExpanded] = useState([
    "location_type",
    "Categories",
    "job_type",
    "salaryRange",
  ]);

  // Checkbox style overrides
  const checkboxStyle = {
    color: "#D6DDEB",
    "&.Mui-checked": {
      color: "#7C00DE",
    },
  };

  const formControlLabelStyle = {
    color: "#515B6F",
  };

  const accordionSummaryStyle = {
    color: "#25324B",
    fontWeight: "bold",
  };

  // Function to handle accordion expansion
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(
      isExpanded ? [...expanded, panel] : expanded.filter((p) => p !== panel)
    );
  };

  // const jobListings = [
  //   // Existing job listings
  //   {
  //     job_job_title: "UI/UX Designer",
  //     company: "Google",
  //     location: "Bangalore, Karnataka, India",
  //     salary: "$1000 - $2000",
  //     logo: "https://via.placeholder.com/50",
  //   },
  //   {
  //     job_title: "UI/UX Designer",
  //     company: "TATA",
  //     location: "Bangalore, Karnataka, India",
  //     salary: "$1000 - $2000",
  //     logo: "https://via.placeholder.com/50",
  //   },
  //   {
  //     job_title: "UI/UX Designer",
  //     company: "Airtel",
  //     location: "Bangalore, Karnataka, India",
  //     salary: "$1000 - $2000",
  //     logo: "https://via.placeholder.com/50",
  //   },

  //   // Additional job listings
  //   {
  //     job_title: "Front-End Developer",
  //     company: "Facebook",
  //     location: "Bangalore, Karnataka, India",
  //     salary: "$1200 - $2500",
  //     logo: "https://via.placeholder.com/50",
  //   },
  //   {
  //     job_title: "Back-End Developer",
  //     company: "Amazon",
  //     location: "Bangalore, Karnataka, India",
  //     salary: "$1300 - $2700",
  //     logo: "https://via.placeholder.com/50",
  //   },
  //   {
  //     job_title: "Full Stack Developer",
  //     company: "Microsoft",
  //     location: "Bangalore, Karnataka, India",
  //     salary: "$1500 - $3000",
  //     logo: "https://via.placeholder.com/50",
  //   },
  //   {
  //     job_title: "Graphic Designer",
  //     company: "Adobe",
  //     location: "Bangalore, Karnataka, India",
  //     salary: "$1100 - $2300",
  //     logo: "https://via.placeholder.com/50",
  //   },
  //   {
  //     job_title: "Product Manager",
  //     company: "IBM",
  //     location: "Bangalore, Karnataka, India",
  //     salary: "$1800 - $3500",
  //     logo: "https://via.placeholder.com/50",
  //   },
  //   {
  //     job_title: "Data Scientist",
  //     company: "Oracle",
  //     location: "Bangalore, Karnataka, India",
  //     salary: "$1600 - $3200",
  //     logo: "https://via.placeholder.com/50",
  //   },
  //   {
  //     job_title: "DevOps Engineer",
  //     company: "Cisco",
  //     location: "Bangalore, Karnataka, India",
  //     salary: "$1400 - $2900",
  //     logo: "https://via.placeholder.com/50",
  //   },
  //   {
  //     job_title: "Software Engineer",
  //     company: "SAP",
  //     location: "Bangalore, Karnataka, India",
  //     salary: "$1200 - $2500",
  //     logo: "https://via.placeholder.com/50",
  //   },
  //   {
  //     job_title: "Digital Marketer",
  //     company: "HubSpot",
  //     location: "Bangalore, Karnataka, India",
  //     salary: "$1000 - $2200",
  //     logo: "https://via.placeholder.com/50",
  //   },
  //   {
  //     job_title: "Business Analyst",
  //     company: "Salesforce",
  //     location: "Bangalore, Karnataka, India",
  //     salary: "$1300 - $2700",
  //     logo: "https://via.placeholder.com/50",
  //   },
  //   {
  //     job_title: "Database Administrator",
  //     company: "Intel",
  //     location: "Bangalore, Karnataka, India",
  //     salary: "$1400 - $2800",
  //     logo: "https://via.placeholder.com/50",
  //   },
  //   {
  //     job_title: "Network Engineer",
  //     company: "Dell",
  //     location: "Bangalore, Karnataka, India",
  //     salary: "$1200 - $2600",
  //     logo: "https://via.placeholder.com/50",
  //   },
  //   {
  //     job_title: "Cybersecurity Analyst",
  //     company: "VMware",
  //     location: "Bangalore, Karnataka, India",
  //     salary: "$1500 - $3100",
  //     logo: "https://via.placeholder.com/50",
  //   },
  //   {
  //     job_title: "Systems Analyst",
  //     company: "Red Hat",
  //     location: "Bangalore, Karnataka, India",
  //     salary: "$1300 - $2700",
  //     logo: "https://via.placeholder.com/50",
  //   },
  //   {
  //     job_title: "Content Writer",
  //     company: "Contentful",
  //     location: "Bangalore, Karnataka, India",
  //     salary: "$1000 - $2200",
  //     logo: "https://via.placeholder.com/50",
  //   },
  //   {
  //     job_title: "Sales Executive",
  //     company: "Zoho",
  //     location: "Bangalore, Karnataka, India",
  //     salary: "$1100 - $2400",
  //     logo: "https://via.placeholder.com/50",
  //   },
  //   {
  //     job_title: "UI/UX Designer",
  //     company: "Google",
  //     location: "Bangalore, Karnataka, India",
  //     salary: "$1000 - $2000",
  //     logo: "https://via.placeholder.com/50",
  //   },
  //   {
  //     job_title: "UI/UX Designer",
  //     company: "TATA",
  //     location: "Bangalore, Karnataka, India",
  //     salary: "$1000 - $2000",
  //     logo: "https://via.placeholder.com/50",
  //   },
  //   {
  //     job_title: "UI/UX Designer",
  //     company: "Airtel",
  //     location: "Bangalore, Karnataka, India",
  //     salary: "$1000 - $2000",
  //     logo: "https://via.placeholder.com/50",
  //   },
  //   {
  //     job_title: "UI/UX Designer",
  //     company: "Google",
  //     location: "Bangalore, Karnataka, India",
  //     salary: "$1000 - $2000",
  //     logo: "https://via.placeholder.com/50",
  //   },
  //   {
  //     job_title: "UI/UX Designer",
  //     company: "TATA",
  //     location: "Bangalore, Karnataka, India",
  //     salary: "$1000 - $2000",
  //     logo: "https://via.placeholder.com/50",
  //   },
  //   {
  //     job_title: "UI/UX Designer",
  //     company: "Airtel",
  //     location: "Bangalore, Karnataka, India",
  //     salary: "$1000 - $2000",
  //     logo: "https://via.placeholder.com/50",
  //   },
  // ];
  const [jobListings, setjobListings] = useState([]);

  const cities = [
    { label: "New York" },
    { label: "Los Angeles" },
    { label: "Chicago" },
    { label: "Houston" },
    { label: "Phoenix" },
    // Add more cities as needed
  ];

  const [currentPage, setCurrentPage] = useState(1);
  const jobsPerPage = 10;
  const [opena, setopena] = useState(false);
  const indexOfLastJob = currentPage * jobsPerPage;
  const indexOfFirstJob = indexOfLastJob - jobsPerPage;

  // const currentJobs = jobListings.data.data;\
  const [currentJobs, setcurrentJobs] = useState([]);
  useEffect(
    () => {
      const obj = {};
      params.forEach((value, key) => {
        obj[key] = value;
      });
      const getjobs = async () => {
        setloading(false);
        // Convert URLSearchParams to a JavaScript object

        let jobtitle = obj.Categories ? obj.Categories : "";
        if (obj.keyword) {
          jobtitle = jobtitle + obj.keyword;
        }
        // const array = obj.salaryRange.split("-")

        try {
          const response = await axiosData.post(
            `jobs/search2`,
            {
              job_title: jobtitle ? jobtitle : "",
              page_number: obj.page ? obj.page : currentPage,
              limit: 10,
              location_type: obj.location_type ? obj.location_type : "",
              job_type: obj.job_type ? obj.job_type : "",
              location: obj.location ? obj.location : "",
              salaryRange: obj.salaryRange ? obj.salaryRange : "",
              location: obj.location ? obj.location : "",
            },
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          );
          if (response.data) {
            setjobListings(response.data);
            setcurrentJobs(response.data.data);
            setloading(true);
          }
          if (response.status === 204) {
            setcurrentJobs([]);
            setloading(true);
          }
        } catch (err) {
          // alert("No jobs for your search");

          console.log(err);
        }
      };
      getjobs();
      window.scroll(0, 0);
    },
    [currentPage, location.search],
    searchKeyword,
    searchLocation
  );
  const handlePageChange = (event, value) => {
    if (value > 3 && seekerDetails === null) {
      setopena(true);
    } else {
      setCurrentPage(value);
      updateUrl(selectedFilters, searchKeyword, searchLocation, value);
    }
  };

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const filters = {};

    query.forEach((value, key) => {
      if (key === "page") {
        setCurrentPage(parseInt(value, 10));
      } else if (key === "keyword") {
        setSearchKeyword(value);
      } else if (key === "location") {
        setSearchLocation(value);
      } else {
        filters[key] = value.split(",");
      }
    });
    setSelectedFilters(filters);
  }, [location.search]);
  function convertToLakhsfilters(rangeString) {
    let [start, end] = rangeString.split(" - ").map((str) => parseInt(str, 10)); // Split and convert to numbers

    if (isNaN(start) || isNaN(end)) {
      return "Invalid input"; // Return error message for invalid input
    }

    let startLakhs = Math.floor(start / 100000); // Convert start to lakhs
    let endLakhs = Math.floor(end / 100000); // Convert end to lakhs

    return `${startLakhs} lakhs - ${endLakhs} lakhs`;
  }
  const handleFilterChange = (category, option) => {
    const newFilters = { ...selectedFilters };
    if (newFilters[category]?.includes(option)) {
      newFilters[category] = newFilters[category].filter(
        (item) => item !== option
      );
    } else {
      if (!newFilters[category]) {
        newFilters[category] = [];
      }
      newFilters[category].push(option);
    }
    setSelectedFilters(newFilters);

    // Update the URL with filters and current search values
    updateUrl(newFilters, searchKeyword, searchLocation);
  };

  const handleSearchChange = (event) => {
    const { name, value } = event.target;
    if (name === "keyword") {
      setLocalKeyword(value);
    } else if (name === "location") {
      setLocalLocation(value);
    }
  };

  const handleSearchClick = () => {
    setSearchKeyword(localKeyword);
    setSearchLocation(localLocation);
    updateUrl(selectedFilters, localKeyword, localLocation);
  };

  const updateUrl = (filters, keyword = "", location = "", page = 1) => {
    const query = new URLSearchParams();

    // Add filters to query
    Object.keys(filters).forEach((key) => {
      if (filters[key].length) {
        query.set(key, filters[key].join(","));
      }
    });

    // Add search values to query
    if (keyword) {
      query.set("keyword", keyword);
    }
    if (location) {
      query.set("location", location);
    }

    // Add pagination to query
    query.set("page", page);

    // Update the URL with query parameters
    navigate({ search: query.toString() });
  };

  const generateQueryString = (filters, keyword, location, page) => {
    const query = new URLSearchParams();

    // Add filters to query
    Object.keys(filters).forEach((key) => {
      if (filters[key].length) {
        query.set(key, filters[key].join(","));
      }
    });

    // Add search values to query
    if (keyword) {
      query.set("keyword", keyword);
    }
    if (location) {
      query.set("location", location);
    }

    // Add pagination to query
    query.set("page", page);

    return query.toString();
  };
  function capitalizeFirstLetter(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
  const [mobileOpen, setMobileOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const drawerWidth = 220;
  if (loading) {
    return (
      <div
        style={{
          paddingTop: isMobile ? "7rem" : "8rem",
          paddingInline: isMobile ? "10px" : 0,
        }}
      >
        <Dialog
          TransitionComponent={Transition}
          open={opena}
          // onClose={() => setLogin(false)}
          sx={{ "& .MuiPaper-root": { color: "white", width: "400px" } }}
        >
          <DialogTitle
            bgcolor="#7c00de"
            sx={{ backgroundColor: "#7c00de", fontWeight: 600 }}
          >
            ALERT
          </DialogTitle>
          <DialogContent sx={{ mt: 5, mb: 2 }}>
            <DialogContentText sx={{ fontWeight: 600 }}>
              Please login to see more jobs !
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              color="error"
              variant="outlined"
              onClick={() => setopena(false)}
            >
              Cancel
            </Button>
            <Button
              sx={{
                fontWeight: "bold",
                fontSize: "13px",
                backgroundColor: "#7C00DE",
                textTransform: "capitalize",
                color: "white",
                borderColor: "#7C00DE",
                borderRadius: "6px",
                "&:hover": {
                  fontWeight: "bold",
                  textTransform: "capitalize",
                  color: "white",
                  backgroundColor: "#7C00DE",
                  borderColor: "#7C00DE",
                  fontSize: "13px",
                  borderRadius: "6px",
                },
              }}
              variant="contained"
              onClick={() => {
                navigate("/login");
                // setLogin(false);
              }}
            >
              Ok
            </Button>
          </DialogActions>
        </Dialog>
        <div
          style={{
            fontFamily: "Exo2Italic",
            color: "#434343",
            textAlign: "center",
          }}
        >
          <text
            style={{ fontSize: isMobile ? "24px" : "42px", fontWeight: "bold" }}
          >
            Find your <span style={{ color: "#7C00DE" }}>Dream Job</span>
          </text>
          <p style={{ color: "#515B6F" }}>
            Find your next career at companies like HubSpot, Nike, and Dropbox
          </p>
        </div>
        <Container maxWidth="lg" sx={{ py: 4 }}>
          <Card sx={{ mb: 4, p: 3 }}>
            <Grid container spacing={4}>
              <Grid item md={5.2} xs={12}>
                <FormControl fullWidth>
                  <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                    <SearchIcon sx={{ color: "#25324B", my: 0.5 }} />
                    <TextField
                      fullWidth
                      name="keyword"
                      value={localKeyword}
                      onChange={handleSearchChange}
                      placeholder="Job title or keyword"
                      variant="standard"
                      sx={{
                        mr: 2,
                        pl: 2,
                        "& .MuiInput-underline:before": {
                          borderBottomColor: "#D6DDEB", // color when input is not focused
                        },
                        "& .MuiInput-underline:hover:before": {
                          borderBottomColor: "#7C00DE", // color when input is hovered
                        },
                        "& .MuiInput-underline:after": {
                          borderBottomColor: "#7C00DE", // color when input is focused
                        },
                      }}
                    />
                  </Box>
                </FormControl>
              </Grid>
              <Grid item md={5.2} xs={12}>
                <FormControl fullWidth>
                  <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                    <FmdGoodOutlinedIcon sx={{ color: "#25324B", my: 0.5 }} />
                    {/* <Autocomplete
                      fullWidth
                      options={cities}
                      getOptionLabel={(option) => option.label}
                      value={
                        cities.find((city) => city.label === localLocation) ||
                        null
                      }
                      onChange={(event, newValue) => {
                        setLocalLocation(newValue?.label || "");
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          variant="standard"
                          placeholder="Location"
                          sx={{
                            mr: 2,
                            pl: 2,
                            "& .MuiInput-underline:before": {
                              borderBottomColor: "#D6DDEB", // color when input is not focused
                            },
                            "& .MuiInput-underline:hover:before": {
                              borderBottomColor: "#7C00DE", // color when input is hovered
                            },
                            "& .MuiInput-underline:after": {
                              borderBottomColor: "#7C00DE", // color when input is focused
                            },
                          }}
                        />
                      )}
                    /> */}
                    <TextField
                      fullWidth
                      name="location"
                      value={localLocation}
                      onChange={handleSearchChange}
                      placeholder="Location"
                      variant="standard"
                      sx={{
                        mr: 2,
                        pl: 2,
                        "& .MuiInput-underline:before": {
                          borderBottomColor: "#D6DDEB", // color when input is not focused
                        },
                        "& .MuiInput-underline:hover:before": {
                          borderBottomColor: "#7C00DE", // color when input is hovered
                        },
                        "& .MuiInput-underline:after": {
                          borderBottomColor: "#7C00DE", // color when input is focused
                        },
                      }}
                    />
                  </Box>
                </FormControl>
              </Grid>
              <Grid item md={1.6} xs={12}>
                <Button
                  variant="contained"
                  fullWidth
                  onClick={handleSearchClick}
                  sx={{
                    mt: -1.6,
                    py: 1.2,
                    fontWeight: "bold",
                    fontSize: "13px",
                    backgroundColor: "#7C00DE",
                    textTransform: "capitalize",
                    color: "white",
                    borderColor: "#7C00DE",
                    borderRadius: "6px",
                    "&:hover": {
                      fontWeight: "bold",
                      textTransform: "capitalize",
                      color: "white",
                      backgroundColor: "#7C00DE",
                      borderColor: "#7C00DE",
                      fontSize: "13px",
                      borderRadius: "6px",
                    },
                  }}
                >
                  Search
                </Button>
              </Grid>
            </Grid>
          </Card>

          {currentJobs.length > 0 ? (
            <Grid container spacing={4}>
              {isMobile ? (
                <>
                  <Drawer
                    anchor="left"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                      keepMounted: true,
                    }}
                    sx={{
                      width: drawerWidth,
                      flexShrink: 0,
                      "& .MuiDrawer-paper": {
                        width: drawerWidth,
                        boxSizing: "border-box",
                        backgroundColor: "white",
                      },
                    }}
                  >
                    <DrawerHeader
                      sx={{
                        backgroundColor: "#7C00DE",
                        color: "white",
                        fontSize: "18px",
                        fontWeight: "bold",
                      }}
                    >
                      <p>Filter</p>
                      <CloseIcon
                        sx={{ ml: 0.3 }}
                        onClick={handleDrawerToggle}
                      />
                    </DrawerHeader>
                    {accordionData.map(({ id, title, options }) => (
                      <Accordion
                        key={id}
                        expanded={expanded.includes(id)}
                        onChange={handleChange(id)}
                        sx={{
                          mb: 2,
                          backgroundColor: "transparent",
                          boxShadow: "none",
                        }}
                      >
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                          <Typography>{title}</Typography>
                        </AccordionSummary>
                        <AccordionDetails
                          sx={{ display: "flex", flexDirection: "column" }}
                        >
                          {options.map((option) => (
                            <FormControlLabel
                              key={option}
                              control={
                                <Checkbox
                                  checked={
                                    selectedFilters[id]?.includes(option) ||
                                    false
                                  }
                                  onChange={() =>
                                    handleFilterChange(id, option)
                                  }
                                />
                              } //salary_range
                              label={
                                title !== "Salary Range"
                                  ? capitalizeFirstLetter(option)
                                  : convertToLakhsfilters(option)
                              }
                            />
                          ))}
                        </AccordionDetails>
                      </Accordion>
                    ))}
                  </Drawer>
                </>
              ) : (
                <Grid
                  item
                  xs={12}
                  md={3}
                  sm={4}
                  className="custom-scroll"
                  sx={{
                    position: "sticky",
                    top: 10,
                    maxHeight: "200vh",
                    overflowY: "auto",
                    behavior: "smooth",
                    scrollbarWidth: "none",
                  }}
                >
                  {accordionData.map(({ id, title, options }) => (
                    <Accordion
                      key={id}
                      expanded={expanded.includes(id)}
                      onChange={handleChange(id)}
                      sx={{
                        mb: 2,
                        backgroundColor: "transparent",
                        boxShadow: "none",
                      }}
                    >
                      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography>{title}</Typography>
                      </AccordionSummary>
                      <AccordionDetails
                        sx={{ display: "flex", flexDirection: "column" }}
                      >
                        {options.map((option) => (
                          <FormControlLabel
                            key={option}
                            control={
                              <Checkbox
                                checked={
                                  selectedFilters[id]?.includes(option) || false
                                }
                                onChange={() => handleFilterChange(id, option)}
                              />
                            }
                            label={
                              title !== "Salary Range"
                                ? capitalizeFirstLetter(option)
                                : convertToLakhsfilters(option)
                            }
                          />
                        ))}
                      </AccordionDetails>
                    </Accordion>
                  ))}
                </Grid>
              )}

              <Grid item xs={12} sm={8} md={9}>
                <div
                  style={{
                    display: isMobile ? "flex" : "block",
                    justifyContent: "space-between",
                  }}
                >
                  <Box
                    sx={{
                      display: isMobile ? "block" : "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      mb: 2,
                    }}
                  >
                    <div>
                      <Typography
                        variant="h4"
                        sx={{ color: "#25324B", fontWeight: "bold" }}
                      >
                        All Jobs
                      </Typography>
                      <text style={{ color: "#7C8493", fontSize: "13px" }}>
                        Showing {jobListings.total_record} results
                      </text>
                    </div>
                    {/* <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Typography
                        variant="body2"
                        sx={{ mr: 1, color: "#7C8493" }}
                      >
                        Sort by:
                      </Typography>
                      <div>
                        <div
                          style={{ cursor: "pointer" }}
                          onClick={handleClick}
                        >
                          <text
                            style={{ color: "#25324B", fontWeight: "bold" }}
                          >
                            {selectedOption}
                          </text>
                          <IconButton
                            aria-controls="custom-menu"
                            aria-haspopup="true"
                          >
                            <KeyboardArrowDownIcon
                              style={{ color: "purple", mt: -1 }}
                            />
                          </IconButton>
                        </div>
                        <CustomMenu
                          id="custom-menu"
                          anchorEl={anchorEl}
                          open={Boolean(anchorEl)}
                          onClose={() => handleClose(null)}
                          PaperProps={{
                            style: {
                              border: "none", // Remove the border
                            },
                          }}
                        >
                          <MenuItem
                            onClick={() => handleClose("Most relevant")}
                          >
                            Most relevant
                          </MenuItem>
                          <MenuItem onClick={() => handleClose("Most recent")}>
                            Most recent
                          </MenuItem>
                        </CustomMenu> */}
                    {/* </div> */}
                    {/* <Button>
                  <List
                    sx={{
                      color: "#7C00DE",
                      borderRadius: "6px",
                      "&:hover": {
                        textTransform: "capitalize",
                        color: "white",
                        backgroundColor: "#7C00DE",
                        borderColor: "#7C00DE",
                        borderRadius: "6px",
                      },
                    }}
                  />
                </Button>
                <Button>
                  <GridView
                    sx={{
                      color: "#515B6F",
                      borderRadius: "6px",
                      "&:hover": {
                        textTransform: "capitalize",
                        color: "white",
                        backgroundColor: "#515B6F",
                        borderColor: "#515B6F",
                        borderRadius: "6px",
                      },
                    }}
                  />
                </Button> */}
                    {/* </Box> */}
                  </Box>
                  {isMobile && (
                    <IconButton
                      sx={{ mt: -10, color: "#7C00DE" }}
                      onClick={handleDrawerToggle}
                    >
                      Filter <FilterAltIcon />
                    </IconButton>
                  )}
                </div>
                <Grid container spacing={2}>
                  {currentJobs &&
                    currentJobs.map((job, index) => (
                      <Grid item xs={12} key={index}>
                        <Box
                          sx={{
                            display: isMobile ? "block" : "flex",
                            alignItems: "center",
                            borderBottom: "1px solid #e0e0e0",
                            py: 2,
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <Box
                              component="img"
                              src={job.logo}
                              alt="company logo"
                              sx={{ mr: 2, width: "150px", height: "100px" }}
                            />
                          </div>
                          <Box sx={{ flexGrow: 1 }}>
                            <div
                              style={{
                                display: isMobile ? "block" : "flex",
                                gap: "10px",
                              }}
                            >
                              <Typography
                                variant="h6"
                                sx={{ fontWeight: "bold" }}
                              >
                                {job.job_title}
                              </Typography>
                              <Chip
                                label={job.job_type}
                                color="primary"
                                sx={{
                                  bgcolor: "#C7FF8F",
                                  color: "#000000",
                                  padding: 0,
                                  "& .MuiChip-label": {
                                    fontSize: "11px",
                                  },
                                }}
                              />
                            </div>
                            <Typography
                              variant="body2"
                              sx={{ color: "#454545" }}
                            >
                              {job.company}
                            </Typography>
                            <Typography
                              variant="body2"
                              sx={{ color: "#454545" }}
                            >
                              <PlaceOutlinedIcon sx={{ mb: -0.7 }} />
                              {job.location ? job.location : job.location_type}
                            </Typography>
                            <Typography sx={{ fontWeight: "bold" }}>
                              {job.job_type === "contract"
                                ? `${job.salary} INR/Hour`
                                : `${convertToLakhs(
                                    job.min_charge
                                  )}-${convertToLakhs(job.max_charge)}`}
                            </Typography>
                          </Box>
                          <Button
                            variant="contained"
                            sx={{
                              padding: "10px 40px",
                              fontWeight: "bold",
                              fontSize: "13px",
                              backgroundColor: "#7C00DE",
                              textTransform: "capitalize",
                              color: "white",
                              borderColor: "#7C00DE",
                              borderRadius: "6px",
                              "&:hover": {
                                fontWeight: "bold",
                                textTransform: "capitalize",
                                color: "white",
                                backgroundColor: "#7C00DE",
                                borderColor: "#7C00DE",
                                fontSize: "13px",
                                borderRadius: "6px",
                              },
                            }}
                            onClick={() => {
                              const queryString = generateQueryString(
                                selectedFilters,
                                searchKeyword,
                                searchLocation,
                                currentPage
                              );
                              navigate(
                                `/${ROUTINGDATA.NewJobDetailsPage}?${
                                  "job_id=" + job.job_id + "&"
                                }${queryString}`
                              );
                              window.scrollTo(0, 0);
                            }}
                          >
                            Know more
                          </Button>
                        </Box>
                      </Grid>
                    ))}
                </Grid>
              </Grid>
            </Grid>
          ) : (
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              minHeight="60vh"
              textAlign="center"
              bgcolor="#f9f9f9"
              p={3}
              borderRadius={2}
              boxShadow={2}
            >
              <SearchOffIcon style={{ fontSize: 80, color: "#7C00DE" }} />
              <Typography
                variant="h4"
                component="div"
                color="textPrimary"
                gutterBottom
              >
                No Results Found
              </Typography>
              <Typography variant="body1" color="textSecondary" paragraph>
                We couldn't find any results matching your search. Please try
                again with different keywords.
              </Typography>
              <Button
                variant="contained"
                sx={{
                  fontWeight: "bold",
                  fontSize: "13px",
                  backgroundColor: "#7C00DE",
                  textTransform: "capitalize",
                  color: "white",
                  borderColor: "#7C00DE",
                  borderRadius: "6px",
                  "&:hover": {
                    fontWeight: "bold",
                    textTransform: "capitalize",
                    color: "white",
                    backgroundColor: "#7C00DE",
                    borderColor: "#7C00DE",
                    fontSize: "13px",
                    borderRadius: "6px",
                  },
                }}
              >
                Retry Search
              </Button>
            </Box>
          )}
          <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
            <Pagination
              count={Math.ceil(jobListings.total_record / jobsPerPage)}
              page={currentPage}
              onChange={handlePageChange}
              sx={{
                "& .MuiPaginationItem-root": {
                  color: "#7C00DE",
                },
                "& .MuiPaginationItem-page.Mui-selected": {
                  backgroundColor: "#7C00DE",
                  color: "#FFFFFF",
                },
                "& .MuiPaginationItem-ellipsis": {
                  color: "#7C00DE",
                },
              }}
            />
          </Box>
        </Container>
      </div>
    );
  } else {
    return (
      <div>
        {/* Header Section Skeleton */}
        <Box sx={{ padding: "2rem", textAlign: "center" }}>
          <Skeleton
            variant="text"
            sx={{ fontSize: "42px", fontWeight: "bold", mb: 1 }}
          />
          <Skeleton
            variant="text"
            sx={{ fontSize: "16px", width: "60%", margin: "0 auto" }}
          />
        </Box>

        {/* Search Section Skeleton */}
        <Container maxWidth="lg" sx={{ py: 4 }}>
          <Card sx={{ mb: 4, p: 3 }}>
            <Grid container spacing={4}>
              <Grid item md={5.2} xs={12}>
                <Skeleton
                  variant="rectangular"
                  width="100%"
                  height={56}
                  sx={{ display: "flex", alignItems: "center" }}
                />
              </Grid>
              <Grid item md={5.2} xs={12}>
                <Skeleton
                  variant="rectangular"
                  width="100%"
                  height={56}
                  sx={{ display: "flex", alignItems: "center" }}
                />
              </Grid>
              <Grid item md={1.6} xs={12}>
                <Skeleton
                  variant="rectangular"
                  width="100%"
                  height={56}
                  sx={{ display: "flex", alignItems: "center" }}
                />
              </Grid>
            </Grid>
          </Card>
        </Container>

        {/* Filter Section Skeleton */}
        <Grid item xs={12} md={3} sm={4}>
          <Box sx={{ maxHeight: "200vh", overflowY: "auto" }}>
            {Array.from(new Array(5)).map((_, index) => (
              <Accordion
                key={index}
                sx={{
                  mb: 2,
                  backgroundColor: "transparent",
                  boxShadow: "none",
                }}
              >
                <AccordionSummary>
                  <Skeleton variant="text" width="40%" height={32} />
                </AccordionSummary>
                <AccordionDetails>
                  {Array.from(new Array(4)).map((_, i) => (
                    <Skeleton
                      key={i}
                      variant="text"
                      width="100%"
                      height={24}
                      sx={{ mb: 1 }}
                    />
                  ))}
                </AccordionDetails>
              </Accordion>
            ))}
          </Box>
        </Grid>

        {/* Job Listings Skeleton */}
        <Grid container spacing={2}>
          {Array.from(new Array(3)).map((_, index) => (
            <Grid item xs={12} key={index}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  borderBottom: "1px solid #e0e0e0",
                  py: 2,
                  cursor: "pointer",
                }}
              >
                <Skeleton
                  variant="rectangular"
                  width={150}
                  height={100}
                  sx={{ mr: 2 }}
                />
                <Box sx={{ flexGrow: 1 }}>
                  <Skeleton variant="text" sx={{ fontSize: "1rem", mb: 1 }} />
                  <Skeleton
                    variant="text"
                    sx={{ fontSize: "0.875rem", mb: 1 }}
                  />
                  <Skeleton
                    variant="text"
                    sx={{ fontSize: "0.875rem", mb: 1 }}
                  />
                  <Skeleton variant="text" sx={{ fontSize: "0.875rem" }} />
                </Box>
                <Skeleton variant="rectangular" width={100} height={40} />
              </Box>
            </Grid>
          ))}
        </Grid>

        {/* Pagination Skeleton */}
        <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
          <Skeleton
            variant="rectangular"
            width={200}
            height={40}
            sx={{ display: "flex", alignItems: "center" }}
          />
        </Box>
      </div>
    );
  }
}

export default NewFindJobsPage;
