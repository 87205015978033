import React, { useEffect, useRef, useState } from "react";
import {
  Card,
  Avatar,
  Button,
  Typography,
  Divider,
  useMediaQuery,
  Dialog,
  Box,
  TextField,
  IconButton,
  InputAdornment,
  FormHelperText,
  Menu,
  MenuItem,
  CircularProgress,
  FormControl,
  FormLabel,
  Radio,
  RadioGroup,
  FormControlLabel,
} from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import profile from "../img/profile.jpeg";
import google from "../img/googleicon.png";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import SmartphoneOutlinedIcon from "@mui/icons-material/SmartphoneOutlined";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
import CloseIcon from "@mui/icons-material/Close";
import { display, Stack } from "@mui/system";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import { useFormik } from "formik";
import * as Yup from "yup";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import useAxios from "../api/useAxios";
import LogoutIcon from "@mui/icons-material/Logout";
import { useNavigate } from "react-router-dom";
import { ROUTINGDATA } from "./ROUTINGDATA";
import { removeImage, uploadImage } from "../../src/utility/Helperfunction";
import { ListItem } from "@mui/material-next";
import { List } from "@mui/icons-material";
import Slide from "@mui/material/Slide";
import LoadingButton from "./LoadingButton";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const Profile = () => {
  const MAX_FILE_SIZE = 1048576 * 2;
  const axiosData = useAxios();
  const [loading, setloading] = useState();
  const navigate = useNavigate();
  const [opena2, setopena2] = useState(false);
  const [opena, setopena] = useState(false);
  const [opena3, setopena3] = useState(false);
  const [opena4, setopena4] = useState(false);
  const [deleteindex, setdeleteindex] = useState();
  const isMobile = useMediaQuery("(max-width:600px)");
  const [showAllExperiences, setShowAllExperiences] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = React.useState(false);
  const [openAddExperience, setOpenAddExperience] = useState(false);
  const [avatarSrc, setAvatarSrc] = useState("");
  const [seeker, setSeeker] = useState({});
  const [logoutDialog, setLogoutDialog] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const fileInputField = useRef(null);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [experienceLevel, setExperienceLevel] = useState("");
  const [currentlyWorking, setCurrentlyWorking] = useState("");

  const handleExperienceChange = (event) => {
    if (event.target.value === "experienced") {
      formik.setFieldValue("work_status", event.target.value);
      setCurrentlyWorking("no");
    } else {
      formik.setFieldValue("work_status", event.target.value);
      formik.setFieldValue("currentlyWorking", "no");
      formik.setFieldValue("currentPosition", "");
      formik.setFieldValue("currentCompany", "");
      setCurrentlyWorking("no");
    }
    setExperienceLevel(event.target.value);
  };
  const handleCurrentlyWorkingChange = (event) => {
    formik.setFieldValue("currentlyWorking", event.target.value);
    console.log(event.target.value);
    setCurrentlyWorking(event.target.value);
  };
  const jobExperiences = [
    {
      title: "Product Designer",
      company: "google",
      type: "Full-Time",
      duration: "Jun 2019 - Present (1y 1m)",
      location: "Manchester, UK",
      description:
        "Developed digital marketing strategies, activation plans, proposals, contests and promotions for client initiatives. Developed digital marketing strategies, activation plans, proposals, contests and promotions for client initiatives.",
    },
    // Add more job experiences here if needed
  ];

  // Repeat the job experience 5 times
  const repeatedJobExperiences = Array(5).fill(jobExperiences[0]);

  const initialExperienceCount = 2;
  const visibleExperiences = showAllExperiences
    ? repeatedJobExperiences
    : repeatedJobExperiences.slice(0, initialExperienceCount);

  const handleClose = () => {
    setOpen(false);
  };
  const handleClickOpenAddExperience = () => {
    setOpenAddExperience(true);
  };
  const handleCloseAddExperience = () => {
    setOpenAddExperience(false);
  };
  const validationSchema = Yup.object({
    fullName: Yup.string().required("Full Name is required"),
    // email: Yup.string()
    //   .email("Invalid email address")
    //   .required("Email is required"),
    // phone: Yup.string().required("Phone Number is required"),
    currentlyWorking:
      experienceLevel === "experienced"
        ? Yup.string().required(
            "currently Working is required when experience is true"
          )
        : Yup.string(),
    currentPosition:
      currentlyWorking === "yes"
        ? Yup.string().required("Position is required when experience is true")
        : Yup.string(),
    currentCompany:
      currentlyWorking === "yes"
        ? Yup.string().required("Company is required when experience is true")
        : Yup.string(),
    currentLocation: Yup.string().required("Location is required"),
    // about: Yup.string().required("About is required"),
  });
  const validationSchema1 = Yup.object({
    position: Yup.string().required("Position is required"),
    company: Yup.string().required("Company is required"),
    emptype: Yup.string().required("Employment Type is required"),
    start: Yup.string().required("Start Date is required"),
    end: Yup.string().required(" End Date is required"),
    location: Yup.string().required("Location is required"),
    desc: Yup.string().required("Description is required"),
  });
  const [anchorEl1, setAnchorEl1] = useState(null);
  const open1 = Boolean(anchorEl1);
  const handleCameraClick = (event) => {
    setAnchorEl1(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl1(null);
  };

  const handleEditImage = () => {
    document.getElementById("upload-image-input").click();
    handleMenuClose();
  };

  const handleRemoveImage = () => {
    setIsEdit(true);
    setAvatarSrc("");
    handleMenuClose();
  };

  const handleImageChange = (event, setFieldValue) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setFieldValue("profileUri", file);
        setIsEdit(true);
        setAvatarSrc(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };
  const [resumelist, setResumelist] = useState([]);
  const [resume, setResume] = useState();
  const handleFileChange = (e) => {
    if (e.target.files[0].size < MAX_FILE_SIZE) {
      console.log("resume", e.target.files[0].size);
      setResume(e.target.files);
      setopena2(true);
    } else {
      setopena(true);
    }
  };
  const uploadResume = async () => {
    setloading(true);
    const fileUrl = await uploadImage("resume", resume[0], false);
    resumelist.push(fileUrl);
    setResumelist(resumelist);
    await axiosData.put(`seeker/email/${seeker.email_id}`, {
      resumelink: resumelist.join(","),
    });
    fetchData();
    setopena2(false);
  };
  const formik = useFormik({
    initialValues: {
      fullName: "",
      // email: "",
      // phone: "",
      currentlyWorking: "",
      currentPosition: "",
      currentCompany: "",
      currentLocation: "",
      about: "",
      profileUri: "",
      work_status: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setButtonLoading(true);
      try {
        let profileUrl;
        if (isEdit) {
          if (seeker.profileUri && seeker.profileUri !== "") {
            await removeImage("profile", seeker.profileUri);
            profileUrl = "";
          }
          if (values.profileUri) {
            profileUrl = await uploadImage("profile", values.profileUri, false);
          }
        }
        await axiosData.put(`seeker/email/${seeker.email_id}`, {
          full_name: values.fullName,
          currentPosition: values.currentPosition,
          currentCompany: values.currentCompany,
          currentLocation: values.currentLocation,
          ProfileSummary: values.about,
          profileUri: profileUrl,
          currentlyWorking: values.currentlyWorking,
          work_status: values.work_status,
        });
        setOpen(false);
        setIsEdit(false);
        fetchData();
      } catch (error) {
        console.log("Error", error);
      }
      setButtonLoading(false);
    },
  });
  const formik1 = useFormik({
    initialValues: {
      position: "",
      company: "",
      emptype: "",
      start: null,
      end: null,
      location: "",
      desc: "",
      profileUri: "",
    },
    validationSchema: validationSchema1,
    onSubmit: (values) => {
      console.log("Submitted values:", values);
    },
  });

  const getEmail = JSON.parse(localStorage.getItem("seeker"));

  const fetchData = async () => {
    const getSeeker = await axiosData.get(`seekers/email/${getEmail.emailId}`);
    setSeeker(getSeeker.data);
    setExperienceLevel(getSeeker.data.work_status);
    setCurrentlyWorking(getSeeker.data.currentlyWorking);
    setResumelist(getSeeker.data.resumelink.split(","));
  };

  useEffect(() => {
    if (getEmail) {
      fetchData();
    } else {
      navigate(ROUTINGDATA.LAND);
    }
  }, []);
  // useEffect(
  //   () => {
  //     if (open) {
  //       formik.resetForm();
  //     }
  //   },
  //   //eslint-disable-next-line react-hooks/exhaustive-deps
  //   [open]
  // );
  // useEffect(
  //   () => {
  //     if (openAddExperience) {
  //       formik.resetForm();
  //     }
  //   },
  //   //eslint-disable-next-line react-hooks/exhaustive-deps
  //   [openAddExperience]
  // );
  const deleteresume = async (index) => {
    const respone = await removeImage("resume", resumelist[index]);
    console.log(respone);
    const blanceResume = resumelist.splice(index, 1);
    await axiosData.put(`seeker/email/${seeker.email_id}`, {
      resumelink: resumelist.join(","),
    });
    setopena3(false);
  };
  const handleClickOpen = (seeker) => {
    const updatedValues = {
      fullName: seeker.full_name,
      currentPosition: seeker.currentPosition,
      currentCompany: seeker.currentCompany,
      currentLocation: seeker.currentLocation,
      about: seeker.ProfileSummary,
      currentlyWorking: seeker.currentlyWorking,
      work_status: seeker.work_status,
    };
    formik.setValues(updatedValues);
    setAvatarSrc(seeker.profileUri);
    setOpen(true);
  };

  const handleLogout = () => {
    localStorage.removeItem("seeker");
    navigate(ROUTINGDATA.LAND);
  };
  const handleMenuClick = (event) => {
    setAnchorEl1(event.currentTarget);
  };

  const handleMenuClose1 = () => {
    setAnchorEl1(null);
  };
  return (
    <div style={{ paddingTop: isMobile ? "3.5rem" : "3.8rem" }}>
      <div
        style={{
          paddingRight: isMobile ? "10px" : "150px",
          paddingLeft: isMobile ? "10px" : "150px",
        }}
      >
        <Card>
          <img src={profile} alt="profile" height="150px" width="100%" />
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: "-60px",
              paddingRight: "32px",
              paddingLeft: "32px",
            }}
          >
            <Avatar
              src={seeker.profileUri}
              sx={{
                width: 120,
                height: 120,
              }}
            />
            <IconButton
              onClick={handleMenuClick}
              style={{
                color: "black",
                paddingTop: "40px",
              }}
            >
              <MoreVertOutlinedIcon />
            </IconButton>
            <Menu
              anchorEl={anchorEl1}
              open={open1}
              onClose={handleMenuClose}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
            >
              <MenuItem
                onClick={() => {
                  handleClickOpen(seeker);
                  handleMenuClose1();
                }}
              >
                <BorderColorOutlinedIcon
                  style={{ marginRight: "8px", color: "#7C00DE" }}
                />
                Edit Profile
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setLogoutDialog(true);
                  handleMenuClose1();
                }}
              >
                <LogoutIcon style={{ marginRight: "8px", color: "red" }} />
                Logout
              </MenuItem>
            </Menu>
          </div>
          <div
            style={{
              paddingRight: isMobile ? "16px" : "32px",
              paddingLeft: isMobile ? "16px" : "65px",
              paddingTop: "17px",
            }}
          >
            <Typography
              style={{
                font: "Epilogue",
                fontSize: "24px",
                fontWeight: "600",
                lineHeight: "38.4px",
              }}
            >
              {seeker.full_name}
            </Typography>
            {seeker.currentPosition && seeker.currentCompany ? (
              <Typography
                style={{
                  font: "Epilogue",
                  fontSize: "18px",
                  fontWeight: "500",
                  lineHeight: "38.4px",
                  color: "#7C8493",
                }}
              >
                {seeker.currentPosition} at{" "}
                <span
                  style={{
                    font: "Epilogue",
                    fontSize: "18px",
                    fontWeight: "500",
                    color: "#25324B",
                  }}
                >
                  {seeker.currentCompany}
                </span>
              </Typography>
            ) : (
              <Typography
                style={{
                  font: "Epilogue",
                  fontSize: "18px",
                  fontWeight: "500",
                  lineHeight: "38.4px",
                  color: "#7C8493",
                }}
              >
                Not yet updated
              </Typography>
            )}
            <Stack direction="row" spacing={1}>
              <LocationOnOutlinedIcon style={{ color: "#7C8493" }} />
              {seeker.currentLocation ? (
                <Typography
                  style={{
                    font: "Epilogue",
                    fontSize: "18px",
                    fontWeight: "500",
                    color: "#7C8493",
                  }}
                >
                  {seeker.currentLocation}
                </Typography>
              ) : (
                <Typography
                  style={{
                    font: "Epilogue",
                    fontSize: "18px",
                    fontWeight: "500",
                    color: "#7C8493",
                  }}
                >
                  Not yet updated
                </Typography>
              )}
            </Stack>
            <br />
            <Stack direction="row" spacing={1}>
              <EmailOutlinedIcon style={{ color: "#7C8493" }} />
              <Typography
                style={{
                  font: "Epilogue",
                  fontSize: "18px",
                  fontWeight: "500",
                  color: "#7C8493",
                }}
              >
                Email
              </Typography>
            </Stack>
            {seeker.email_id ? (
              <Typography
                style={{
                  font: "Epilogue",
                  fontSize: "18px",
                  fontWeight: "500",
                  color: "#25324B",
                  paddingLeft: "29px",
                }}
              >
                {seeker.email_id}
              </Typography>
            ) : (
              <Typography
                style={{
                  font: "Epilogue",
                  fontSize: "18px",
                  fontWeight: "500",
                  color: "#25324B",
                  paddingLeft: "29px",
                }}
              >
                Not yet updated
              </Typography>
            )}
            <br />
            <Stack direction="row" spacing={1}>
              <SmartphoneOutlinedIcon style={{ color: "#7C8493" }} />
              <Typography
                style={{
                  font: "Epilogue",
                  fontSize: "18px",
                  fontWeight: "500",
                  color: "#7C8493",
                }}
              >
                Phone
              </Typography>
            </Stack>
            {seeker.mobile_number ? (
              <Typography
                style={{
                  font: "Epilogue",
                  fontSize: "18px",
                  fontWeight: "500",
                  color: "#25324B",
                  paddingLeft: "29px",
                }}
              >
                {seeker.mobile_number}
              </Typography>
            ) : (
              <Typography
                style={{
                  font: "Epilogue",
                  fontSize: "18px",
                  fontWeight: "500",
                  color: "#25324B",
                  paddingLeft: "29px",
                }}
              >
                Not yet updated
              </Typography>
            )}
          </div>
          <br />
          <br />
          <Divider
            variant="middle"
            style={{
              borderRadius: 2,
              border: "1px solid D6DDEB",
              paddingLeft: "20px",
              paddingRight: "20px",
            }}
          />
          <br />
          <div
            style={{
              paddingRight: isMobile ? "16px" : "32px",
              paddingLeft: isMobile ? "16px" : "65px",
              paddingTop: "17px",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography
                style={{
                  font: "Epilogue",
                  fontsSize: "20px",
                  fontWeight: "600",
                  lineHeight: "24px",
                }}
              >
                About Me
              </Typography>
            </div>
            {seeker.ProfileSummary ? (
              <Typography
                style={{
                  font: "Epilogue",
                  fontSize: "16px",
                  fontWeight: "400",
                  lineHeight: "25.6px",
                  paddingTop: "10px",
                  color: "#515B6F",
                }}
              >
                {seeker.ProfileSummary}
              </Typography>
            ) : (
              <Typography
                style={{
                  font: "Epilogue",
                  fontSize: "16px",
                  fontWeight: "400",
                  lineHeight: "25.6px",
                  paddingTop: "10px",
                  color: "#515B6F",
                }}
              >
                Not yet updated
              </Typography>
            )}
            <br />
            <br />
          </div>
          <Divider
            variant="middle"
            style={{
              borderRadius: 2,
              border: "1px solid D6DDEB",
              paddingLeft: "10px",
              paddingRight: "10px",
            }}
          />
          <br />
          <div
            style={{
              paddingRight: isMobile ? "16px" : "32px",
              paddingLeft: isMobile ? "16px" : "65px",
              paddingTop: "17px",
            }}
          >
            <Button
              variant="contained"
              sx={{
                fontWeight: "bold",
                fontSize: "13px",
                backgroundColor: "#7C00DE",
                textTransform: "capitalize",
                color: "white",
                borderColor: "#7C00DE",
                borderRadius: "6px",
                "&:hover": {
                  fontWeight: "bold",
                  textTransform: "capitalize",
                  color: "white",
                  backgroundColor: "#7C00DE",
                  borderColor: "#7C00DE",
                  fontSize: "13px",
                  borderRadius: "6px",
                },
              }}
              onClick={() => {
                if (resumelist.length >= 3) {
                  setopena4(true);
                } else {
                  fileInputField.current.click();
                }
              }}
            >
              Upload
            </Button>
            {resumelist.length !== 0 &&
              resumelist.map((element, index) => {
                return (
                  <>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        style={{
                          font: "Epilogue",
                          fontsSize: "20px",
                          fontWeight: "600",
                          lineHeight: "24px",
                        }}
                      >
                        Resume{index + 1}
                      </Typography>
                    </div>
                    {element ? (
                      <div
                        style={{
                          display: "flex",
                          width: "100%",
                          justifyContent: "space-between",
                        }}
                      >
                        <div>
                          <Typography
                            sx={{
                              width: "400px",
                              wordWrap: "break-word",
                              lineHeight: "2em",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                              transition: "0.5s",
                              padding: "0px 5px",
                              borderRadius: "5px",
                              fontSize: "0.875rem",
                              fontFamily: "Barlow-Regular",
                              ":hover": {
                                width: "100%",
                              },
                            }}
                            //   className="nameTitleServiceProvider"
                          >
                            {element}
                          </Typography>
                        </div>
                        <div style={{ display: "flex", gap: "10px" }}>
                          <Button
                            style={{
                              marginLeft: "auto",
                              marginTop: "auto",
                              borderRadius: "4px",
                              border: "1px solid #CCCCF5",
                              color: "#7C00DE",
                              textTransform: "capitalize",
                            }}
                            href={element}
                          >
                            View
                          </Button>
                          <Button
                            variant="contained"
                            sx={{
                              fontWeight: "bold",
                              fontSize: "13px",
                              backgroundColor: "#7C00DE",
                              textTransform: "capitalize",
                              color: "white",
                              borderColor: "#7C00DE",
                              borderRadius: "6px",
                              "&:hover": {
                                fontWeight: "bold",
                                textTransform: "capitalize",
                                color: "white",
                                backgroundColor: "#7C00DE",
                                borderColor: "#7C00DE",
                                fontSize: "13px",
                                borderRadius: "6px",
                              },
                            }}
                            onClick={() => {
                              setopena3(true);
                              setdeleteindex(index);
                            }}
                          >
                            Delete
                          </Button>
                        </div>
                      </div>
                    ) : (
                      <Typography
                        style={{
                          font: "Epilogue",
                          fontSize: "16px",
                          fontWeight: "400",
                          lineHeight: "25.6px",
                          paddingTop: "10px",
                          color: "#515B6F",
                        }}
                      >
                        Not yet updated
                      </Typography>
                    )}
                    <br />
                    <br />
                  </>
                );
              })}
          </div>
          <Divider
            variant="middle"
            style={{
              borderRadius: 2,
              border: "1px solid D6DDEB",
              paddingLeft: "10px",
              paddingRight: "10px",
            }}
          />
          {/* <br /> */}
          {/* <div
            style={{
              paddingRight: isMobile ? "16px" : "32px",
              paddingLeft: isMobile ? "16px" : "65px",
              paddingTop: "17px",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography
                style={{
                  fontFamily: "Epilogue",
                  fontSize: "20px",
                  fontWeight: "600",
                  lineHeight: "24px",
                }}
              >
                Experiences
              </Typography>
              {isMobile ? (
                <AddBoxOutlinedIcon style={{ color: "#7C00DE" }} />
              ) : (
                <Button
                  onClick={handleClickOpenAddExperience}
                  style={{
                    marginLeft: "auto",
                    marginTop: "auto",
                    borderRadius: "4px",
                    border: "1px solid #D6DDEB",
                  }}
                >
                  <AddBoxOutlinedIcon style={{ color: "#7C00DE" }} />
                </Button>
              )}
            </div>
            {visibleExperiences.map((experience, index) => (
              <div key={index}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: isMobile ? "column" : "row",
                    paddingLeft: "10px",
                    paddingTop: "15px",
                    gap: "15px",
                  }}
                >
                  <div>
                    <img src={google} alt="google" />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <Stack direction="column" style={{ flexGrow: 1 }}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          style={{
                            fontFamily: "Epilogue",
                            fontSize: "18px",
                            fontWeight: "600",
                            color: "#25324B",
                          }}
                        >
                          {experience.title}
                        </Typography>

                        {isMobile ? (
                          <BorderColorOutlinedIcon
                            style={{ color: "#7C00DE" }}
                          />
                        ) : (
                          <Button
                            style={{
                              marginLeft: "auto",
                              marginTop: "auto",
                              borderRadius: "4px",
                              border: "1px solid #D6DDEB",
                            }}
                          >
                            <BorderColorOutlinedIcon
                              style={{ color: "#7C00DE" }}
                            />
                          </Button>
                        )}
                      </div>
                      <Stack direction="row" spacing={1}>
                        <Typography
                          style={{
                            fontFamily: "Epilogue",
                            fontSize: "16px",
                            fontWeight: "500",
                            color: "#25324B",
                          }}
                        >
                          {experience.company}
                        </Typography>
                        <Typography
                          style={{
                            fontFamily: "Epilogue",
                            fontSize: "16px",
                            fontWeight: "400",
                            color: "#515B6F",
                          }}
                        >
                          •
                        </Typography>
                        <Typography
                          style={{
                            fontFamily: "Epilogue",
                            fontSize: "16px",
                            fontWeight: "400",
                            color: "#515B6F",
                          }}
                        >
                          {experience.type}
                        </Typography>
                        <Typography
                          style={{
                            fontFamily: "Epilogue",
                            fontSize: "16px",
                            fontWeight: "400",
                            color: "#515B6F",
                          }}
                        >
                          •
                        </Typography>
                        <Typography
                          style={{
                            fontFamily: "Epilogue",
                            fontSize: "16px",
                            fontWeight: "400",
                            color: "#515B6F",
                          }}
                        >
                          {experience.duration}
                        </Typography>
                      </Stack>
                      <Typography
                        style={{
                          fontFamily: "Epilogue",
                          fontSize: "16px",
                          fontWeight: "400",
                          color: "#7C8493",
                          paddingTop: "7px",
                        }}
                      >
                        {experience.location}
                      </Typography>
                      <Typography
                        style={{
                          fontFamily: "Epilogue",
                          fontSize: "16px",
                          fontWeight: "400",
                          color: "#515B6F",
                          paddingTop: "7px",
                        }}
                      >
                        {experience.description}
                      </Typography>
                    </Stack>
                  </div>
                </div>
                <br />
                {index < visibleExperiences.length - 1 && (
                  <Divider
                    variant="middle"
                    style={{
                      marginTop: "15px",
                      marginBottom: "15px",
                      borderRadius: 2,
                      border: "1px solid #D6DDEB",
                    }}
                  />
                )}
                <br />
              </div>
            ))}
            {!showAllExperiences && (
              <div style={{ textAlign: "center", justifyContent: "center" }}>
                <Button
                  onClick={() => setShowAllExperiences(true)}
                  style={{
                    textTransform: "none",
                    fontFamily: "Epilogue",
                    fontSize: "16px",
                    fontWeight: "600",
                    color: "#7C00DE",
                  }}
                >
                  Show 3 more experiences
                </Button>
              </div>
            )}
            <br />
          </div> */}
        </Card>
        <br />
      </div>
      <Dialog
        onClose={handleClose}
        open={open}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "500px",
              padding: "16px",
              position: "relative",
              borderRadius: "16px",
            },
          },
        }}
      >
        <input
          id="upload-image-input"
          type="file"
          accept="image/*"
          style={{ display: "none" }}
          onChange={(event) => handleImageChange(event, formik.setFieldValue)}
        />
        <IconButton
          onClick={handleClose}
          sx={{
            position: "absolute",
            top: 8,
            right: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
        <form onSubmit={formik.handleSubmit}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              paddingTop: "25px",
            }}
          >
            <Box position="relative" display="inline-block">
              <Avatar
                src={avatarSrc}
                sx={{
                  width: 120,
                  height: 120,
                }}
              />
              <IconButton
                onClick={handleCameraClick}
                sx={{
                  position: "absolute",
                  bottom: 0,
                  right: 0,
                  bgcolor: "#7C00DE",
                  borderRadius: "50%",
                  p: 0.5,
                  fontSize: 24,
                  color: "white",
                }}
              >
                <CameraAltIcon />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
              >
                <MenuItem onClick={handleEditImage}>
                  {avatarSrc ? "Edit Image" : "Upload Image"}
                </MenuItem>
                {avatarSrc && (
                  <MenuItem
                    onClick={() => {
                      handleRemoveImage();
                    }}
                  >
                    Remove Image
                  </MenuItem>
                )}
              </Menu>
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              padding: "0 16px",
            }}
          >
            <Typography
              sx={{
                color: "#000000",
                fontWeight: "500",
                fontFamily: "Inter",
                fontSize: "16px",
                lineHeight: "19.36px",
              }}
            >
              Name
              <Typography sx={{ color: "red" }} component="span">
                *
              </Typography>
            </Typography>
            <TextField
              variant="outlined"
              fullWidth
              placeholder="Enter your full name"
              name="fullName"
              value={formik.values.fullName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <FormHelperText>
              {formik.touched.fullName && formik.errors.fullName ? (
                <Typography sx={{ color: "red", fontSize: "13px" }}>
                  {formik.errors.fullName}
                </Typography>
              ) : (
                <Typography sx={{ fontSize: "13px", visibility: "hidden" }}>
                  none
                </Typography>
              )}
            </FormHelperText>
            <Typography
              sx={{
                color: "#000000",
                fontWeight: "500",
                fontFamily: "Inter",
                fontSize: "16px",
                lineHeight: "19.36px",
              }}
            >
              Experience
            </Typography>
            <FormControl>
              <RadioGroup
                aria-labelledby="experience-radio-buttons-group-label"
                name="experience"
                value={experienceLevel}
                onChange={handleExperienceChange}
              >
                <Stack direction="row">
                  <FormControlLabel
                    value="fresher"
                    control={<Radio />}
                    label="Fresher"
                  />
                  <FormControlLabel
                    value="experienced"
                    control={<Radio />}
                    label="Experienced"
                  />
                </Stack>
              </RadioGroup>
            </FormControl>

            {experienceLevel === "experienced" && (
              <>
                <Typography
                  sx={{
                    color: "#000000",
                    fontWeight: "500",
                    fontFamily: "Inter",
                    fontSize: "16px",
                    lineHeight: "19.36px",
                  }}
                >
                  Currently working in company
                </Typography>
                <FormControl>
                  <RadioGroup
                    aria-labelledby="currently-working-radio-group-label"
                    name="currentlyWorking"
                    value={currentlyWorking}
                    onChange={handleCurrentlyWorkingChange}
                  >
                    <Stack direction="row">
                      <FormControlLabel
                        value="yes"
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value="no"
                        control={<Radio />}
                        label="No"
                      />
                    </Stack>
                  </RadioGroup>
                </FormControl>
                <FormHelperText>
                  {formik.errors.currentlyWorking ? (
                    <Typography sx={{ color: "red", fontSize: "13px" }}>
                      {formik.errors.currentlyWorking}
                    </Typography>
                  ) : (
                    <Typography sx={{ fontSize: "13px", visibility: "hidden" }}>
                      none
                    </Typography>
                  )}
                </FormHelperText>
                {currentlyWorking === "yes" && (
                  <>
                    <Typography
                      sx={{
                        color: "#000000",
                        fontWeight: "500",
                        fontFamily: "Inter",
                        fontSize: "16px",
                        lineHeight: "19.36px",
                      }}
                    >
                      Company
                      <Typography sx={{ color: "red" }} component="span">
                        *
                      </Typography>
                    </Typography>
                    <TextField
                      sx={{ paddingTop: "2px" }}
                      variant="outlined"
                      fullWidth
                      placeholder="Enter your Company"
                      name="currentCompany"
                      value={formik.values.currentCompany}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    <FormHelperText>
                      {formik.touched.currentCompany &&
                      formik.errors.currentCompany ? (
                        <Typography sx={{ color: "red", fontSize: "13px" }}>
                          {formik.errors.currentCompany}
                        </Typography>
                      ) : (
                        <Typography
                          sx={{ fontSize: "13px", visibility: "hidden" }}
                        >
                          none
                        </Typography>
                      )}
                    </FormHelperText>
                    <Typography
                      sx={{
                        color: "#000000",
                        fontWeight: "500",
                        fontFamily: "Inter",
                        fontSize: "16px",
                        lineHeight: "19.36px",
                      }}
                    >
                      Position
                      <Typography sx={{ color: "red" }} component="span">
                        *
                      </Typography>
                    </Typography>
                    <TextField
                      sx={{ paddingTop: "2px" }}
                      variant="outlined"
                      fullWidth
                      placeholder="Enter your Position"
                      name="currentPosition"
                      value={formik.values.currentPosition}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    <FormHelperText>
                      {formik.touched.currentPosition &&
                      formik.errors.currentPosition ? (
                        <Typography sx={{ color: "red", fontSize: "13px" }}>
                          {formik.errors.currentPosition}
                        </Typography>
                      ) : (
                        <Typography
                          sx={{ fontSize: "13px", visibility: "hidden" }}
                        >
                          none
                        </Typography>
                      )}
                    </FormHelperText>
                  </>
                )}
              </>
            )}
            {/* <Typography
              sx={{
                color: "#000000",
                fontWeight: "500",
                fontFamily: "Inter",
                fontSize: "16px",
                lineHeight: "19.36px",
              }}
            >
              Position
            </Typography>
            <TextField
              sx={{ paddingTop: "2px" }}
              variant="outlined"
              fullWidth
              placeholder="Enter your Position"
              name="currentPosition"
              value={formik.values.currentPosition}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            /> */}
            {/* <FormHelperText>
              {formik.touched.currentPosition &&
              formik.errors.currentPosition ? (
                <Typography sx={{ color: "red", fontSize: "13px" }}>
                  {formik.errors.currentPosition}
                </Typography>
              ) : (
                <Typography sx={{ fontSize: "13px", visibility: "hidden" }}>
                  none
                </Typography>
              )}
            </FormHelperText> */}
            {/* <Typography
              sx={{
                color: "#000000",
                fontWeight: "500",
                fontFamily: "Inter",
                fontSize: "16px",
                lineHeight: "19.36px",
              }}
            >
              Company
            </Typography>
            <TextField
              sx={{ paddingTop: "2px" }}
              variant="outlined"
              fullWidth
              placeholder="Enter your Company"
              name="currentCompany"
              value={formik.values.currentCompany}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <br /> */}
            {/* <FormHelperText>
              {formik.touched.currentCompany && formik.errors.currentCompany ? (
                <Typography sx={{ color: "red", fontSize: "13px" }}>
                  {formik.errors.currentCompany}
                </Typography>
              ) : (
                <Typography sx={{ fontSize: "13px", visibility: "hidden" }}>
                  none
                </Typography>
              )}
            </FormHelperText> */}

            <Typography
              sx={{
                color: "#000000",
                fontWeight: "500",
                fontFamily: "Inter",
                fontSize: "16px",
                lineHeight: "19.36px",
              }}
            >
              Location
              <Typography sx={{ color: "red" }} component="span">
                *
              </Typography>
            </Typography>
            <TextField
              variant="outlined"
              fullWidth
              placeholder="Enter your Location"
              name="currentLocation"
              value={formik.values.currentLocation}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <FormHelperText>
              {formik.touched.currentLocation &&
              formik.errors.currentLocation ? (
                <Typography sx={{ color: "red", fontSize: "13px" }}>
                  {formik.errors.currentLocation}
                </Typography>
              ) : (
                <Typography sx={{ fontSize: "13px", visibility: "hidden" }}>
                  none
                </Typography>
              )}
            </FormHelperText>

            {/* <Typography
              sx={{
                color: "#000000",
                fontWeight: "500",
                fontFamily: "Inter",
                fontSize: "16px",
                lineHeight: "19.36px",
              }}
            >
              Mail Id
              <Typography sx={{ color: "red" }} component="span">
                *
              </Typography>
            </Typography>
            <TextField
              variant="outlined"
              placeholder="Enter your mail id"
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <EmailOutlinedIcon />
                  </InputAdornment>
                ),
              }}
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            /> */}
            {/* <FormHelperText>
              {formik.touched.email && formik.errors.email ? (
                <Typography sx={{ color: "red", fontSize: "13px" }}>
                  {formik.errors.email}
                </Typography>
              ) : (
                <Typography sx={{ fontSize: "13px", visibility: "hidden" }}>
                  none
                </Typography>
              )}
            </FormHelperText>
            <Typography
              sx={{
                color: "#000000",
                fontWeight: "500",
                fontFamily: "Inter",
                fontSize: "16px",
                lineHeight: "19.36px",
              }}
            >
              Phone Number
              <Typography sx={{ color: "red" }} component="span">
                *
              </Typography>
            </Typography>
            <TextField
              variant="outlined"
              placeholder="Enter your phone number"
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LocalPhoneOutlinedIcon />
                  </InputAdornment>
                ),
              }}
              name="phone"
              value={formik.values.phone}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <FormHelperText>
              {formik.touched.phone && formik.errors.phone ? (
                <Typography sx={{ color: "red", fontSize: "13px" }}>
                  {formik.errors.phone}
                </Typography>
              ) : (
                <Typography sx={{ fontSize: "13px", visibility: "hidden" }}>
                  none
                </Typography>
              )}
            </FormHelperText> */}
            <Typography
              sx={{
                color: "#000000",
                fontWeight: "500",
                fontFamily: "Inter",
                fontSize: "16px",
                lineHeight: "19.36px",
              }}
            >
              About Me
            </Typography>
            <TextField
              sx={{ paddingTop: "2px" }}
              variant="outlined"
              placeholder="Describe..."
              fullWidth
              multiline
              name="about"
              value={formik.values.about}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              rows={4}
            />
          </Box>
          <br />
          <Box display="flex" flexDirection="row-reverse">
            <Button
              style={{
                marginLeft: isMobile ? "17px" : "",
                marginRight: "15px",
                border: "1px solid #CCCCF5",
                backgroundColor: "#7C00DE",
                textTransform: "capitalize",
                color: "white",
              }}
              type="submit"
            >
              Save <LoadingButton loading={buttonLoading} />
            </Button>
          </Box>
        </form>
      </Dialog>

      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Dialog
          open={openAddExperience}
          onClose={handleCloseAddExperience}
          PaperProps={{
            sx: {
              maxWidth: "500px",
              width: "100%",
              borderRadius: "16px",
              padding: "24px",
            },
          }}
        >
          <IconButton
            onClick={handleCloseAddExperience}
            sx={{ position: "absolute", top: 8, right: 8 }}
          >
            <CloseIcon />
          </IconButton>
          <br />
          <form onSubmit={formik1.handleSubmit}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                padding: "0 16px",
              }}
            >
              <Typography
                sx={{
                  color: "#000000",
                  fontWeight: "500",
                  fontFamily: "Inter",
                  fontSize: "16px",
                  lineHeight: "19.36px",
                }}
              >
                Position
                <Typography sx={{ color: "red" }} component="span">
                  *
                </Typography>
              </Typography>
              <TextField
                variant="outlined"
                fullWidth
                placeholder="Enter your Position"
                name="position"
                value={formik1.values.position}
                onChange={formik1.handleChange}
                onBlur={formik1.handleBlur}
              />
              <FormHelperText>
                {formik1.touched.position && formik1.errors.position ? (
                  <Typography sx={{ color: "red", fontSize: "13px" }}>
                    {formik1.errors.position}
                  </Typography>
                ) : (
                  <Typography sx={{ fontSize: "13px", visibility: "hidden" }}>
                    none
                  </Typography>
                )}
              </FormHelperText>
              <Typography
                sx={{
                  color: "#000000",
                  fontWeight: "500",
                  fontFamily: "Inter",
                  fontSize: "16px",
                  lineHeight: "19.36px",
                }}
              >
                Company
                <Typography sx={{ color: "red" }} component="span">
                  *
                </Typography>
              </Typography>
              <TextField
                variant="outlined"
                fullWidth
                placeholder="Enter your Company"
                name="company"
                value={formik1.values.company}
                onChange={formik1.handleChange}
                onBlur={formik1.handleBlur}
              />
              <FormHelperText>
                {formik1.touched.company && formik1.errors.company ? (
                  <Typography sx={{ color: "red", fontSize: "13px" }}>
                    {formik1.errors.company}
                  </Typography>
                ) : (
                  <Typography sx={{ fontSize: "13px", visibility: "hidden" }}>
                    none
                  </Typography>
                )}
              </FormHelperText>
              <Typography
                sx={{
                  color: "#000000",
                  fontWeight: "500",
                  fontFamily: "Inter",
                  fontSize: "16px",
                  lineHeight: "19.36px",
                }}
              >
                Employment Type
                <Typography sx={{ color: "red" }} component="span">
                  *
                </Typography>
              </Typography>
              <TextField
                variant="outlined"
                fullWidth
                placeholder="Enter your employment type"
                name="emptype"
                value={formik1.values.emptype}
                onChange={formik1.handleChange}
                onBlur={formik1.handleBlur}
              />
              <FormHelperText>
                {formik1.touched.emptype && formik1.errors.emptype ? (
                  <Typography sx={{ color: "red", fontSize: "13px" }}>
                    {formik1.errors.emptype}
                  </Typography>
                ) : (
                  <Typography sx={{ fontSize: "13px", visibility: "hidden" }}>
                    none
                  </Typography>
                )}
              </FormHelperText>
              <Typography
                sx={{
                  color: "#000000",
                  fontWeight: "500",
                  fontFamily: "Inter",
                  fontSize: "16px",
                  lineHeight: "19.36px",
                }}
              >
                Start Date
                <Typography sx={{ color: "red" }} component="span">
                  *
                </Typography>
              </Typography>
              <DatePicker
                value={formik1.values.start}
                onChange={(value) => formik1.setFieldValue("start", value)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="start"
                    onBlur={formik1.handleBlur}
                    error={
                      formik1.touched.start && Boolean(formik1.errors.start)
                    }
                    helperText={formik1.touched.start && formik1.errors.start}
                  />
                )}
              />
              <FormHelperText>
                {formik1.touched.start && formik1.errors.start ? (
                  <Typography sx={{ color: "red", fontSize: "13px" }}>
                    {formik1.errors.start}
                  </Typography>
                ) : (
                  <Typography sx={{ fontSize: "13px", visibility: "hidden" }}>
                    none
                  </Typography>
                )}
              </FormHelperText>
              <Typography
                sx={{
                  color: "#000000",
                  fontWeight: "500",
                  fontFamily: "Inter",
                  fontSize: "16px",
                  lineHeight: "19.36px",
                }}
              >
                End Date
                <Typography sx={{ color: "red" }} component="span">
                  *
                </Typography>
              </Typography>
              <DatePicker
                value={formik1.values.end}
                onChange={(value) => formik1.setFieldValue("end", value)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="end"
                    onBlur={formik1.handleBlur}
                    error={formik1.touched.end && Boolean(formik1.errors.end)}
                    helperText={formik1.touched.end && formik1.errors.end}
                  />
                )}
              />
              <FormHelperText>
                {formik1.touched.end && formik1.errors.end ? (
                  <Typography sx={{ color: "red", fontSize: "13px" }}>
                    {formik1.errors.end}
                  </Typography>
                ) : (
                  <Typography sx={{ fontSize: "13px", visibility: "hidden" }}>
                    none
                  </Typography>
                )}
              </FormHelperText>
              <Typography
                sx={{
                  color: "#000000",
                  fontWeight: "500",
                  fontFamily: "Inter",
                  fontSize: "16px",
                  lineHeight: "19.36px",
                }}
              >
                Location
                <Typography sx={{ color: "red" }} component="span">
                  *
                </Typography>
              </Typography>
              <TextField
                variant="outlined"
                fullWidth
                placeholder="Enter your job location"
                name="location"
                value={formik1.values.location}
                onChange={formik1.handleChange}
                onBlur={formik1.handleBlur}
              />
              <FormHelperText>
                {formik1.touched.location && formik1.errors.location ? (
                  <Typography sx={{ color: "red", fontSize: "13px" }}>
                    {formik1.errors.location}
                  </Typography>
                ) : (
                  <Typography sx={{ fontSize: "13px", visibility: "hidden" }}>
                    none
                  </Typography>
                )}
              </FormHelperText>
              <Typography
                sx={{
                  color: "#000000",
                  fontWeight: "500",
                  fontFamily: "Inter",
                  fontSize: "16px",
                  lineHeight: "19.36px",
                }}
              >
                Description
              </Typography>
              <TextField
                sx={{ paddingTop: "2px" }}
                variant="outlined"
                placeholder="Describe..."
                fullWidth
                multiline
                rows={2}
              />
            </Box>
            <br />
            <Box display="flex" flexDirection="row-reverse">
              <Button
                style={{
                  marginLeft: isMobile ? "17px" : "",
                  marginRight: "15px",
                  border: "1px solid #CCCCF5",
                  backgroundColor: "#7C00DE",
                  textTransform: "capitalize",
                  color: "white",
                }}
                type="submit"
              >
                Save
              </Button>
            </Box>
          </form>
        </Dialog>
      </LocalizationProvider>

      <Dialog
        open={logoutDialog}
        onClose={() => setLogoutDialog(false)}
        sx={{
          "& .MuiPaper-root": { color: "white", width: "400px" },
        }}
      >
        <DialogTitle
          sx={{
            backgroundColor: "#7C00DE",
            fontWeight: 600,
          }}
        >
          Logout
        </DialogTitle>
        <DialogContent sx={{ mt: 3 }}>
          <DialogContentText sx={{ fontWeight: 600 }}>
            Are you sure to logout?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            color="secondary"
            sx={{
              color: "#7C00DE",
              borderColor: "#7C00DE",
              "&:hover": {
                color: "#7C00DE",
                borderColor: "#7C00DE",
              },
            }}
            onClick={() => setLogoutDialog(false)}
          >
            Cancel
          </Button>
          <Button
            color="secondary"
            variant="outlined"
            sx={{
              color: "#7C00DE",
              borderColor: "#7C00DE",
              "&:hover": {
                color: "white",
                backgroundColor: "#7C00DE",
                borderColor: "#7C00DE",
              },
            }}
            onClick={() => {
              setLogoutDialog(false);
              handleLogout();
            }}
          >
            Logout
          </Button>
        </DialogActions>
      </Dialog>
      <input
        id="file-upload"
        type="file"
        accept=".docx,.doc,.pdf,"
        style={{ display: "none" }}
        onChange={handleFileChange}
        ref={fileInputField}
        required
      />
      <Dialog
        TransitionComponent={Transition}
        open={opena2}
        sx={{ "& .MuiPaper-root": { color: "white", width: "400px" } }}
      >
        <DialogTitle
          bgcolor="#7c00de"
          sx={{ backgroundColor: "#7c00de", fontWeight: 600 }}
        >
          Upload Resume
        </DialogTitle>
        <DialogContent sx={{ mt: 5, mb: 2 }}>
          <DialogContentText sx={{ fontWeight: 600 }}>
            Please verify the following information before Upload:
          </DialogContentText>

          <p style={{ color: "black" }}>
            Resume name:
            {resume && resume[0].name}
          </p>
        </DialogContent>
        <DialogActions>
          <Button
            color="error"
            variant="outlined"
            onClick={() => setopena2(false)}
          >
            Cancel
          </Button>
          <Button
            sx={{
              fontWeight: "bold",
              fontSize: "13px",
              backgroundColor: "#7C00DE",
              textTransform: "capitalize",
              color: "white",
              borderColor: "#7C00DE",
              borderRadius: "6px",
              "&:hover": {
                fontWeight: "bold",
                textTransform: "capitalize",
                color: "white",
                backgroundColor: "#7C00DE",
                borderColor: "#7C00DE",
                fontSize: "13px",
                borderRadius: "6px",
              },
            }}
            variant="contained"
            onClick={uploadResume}
            disabled={loading}
          >
            {loading ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              "Confirm"
            )}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        TransitionComponent={Transition}
        open={opena}
        sx={{ "& .MuiPaper-root": { color: "white", width: "400px" } }}
      >
        <DialogTitle
          bgcolor="#7c00de"
          sx={{ backgroundColor: "#7c00de", fontWeight: 600 }}
        >
          Upload Resume
        </DialogTitle>
        <DialogContent sx={{ mt: 5, mb: 2 }}>
          <DialogContentText sx={{ fontWeight: 600, color: "red" }}>
            Alert
          </DialogContentText>

          <p style={{ color: "black" }}>
            The file is too large. Please upload a file that is less than 2 MB
          </p>
        </DialogContent>
        <DialogActions>
          <Button
            sx={{
              fontWeight: "bold",
              fontSize: "13px",
              backgroundColor: "#7C00DE",
              textTransform: "capitalize",
              color: "white",
              borderColor: "#7C00DE",
              borderRadius: "6px",
              "&:hover": {
                fontWeight: "bold",
                textTransform: "capitalize",
                color: "white",
                backgroundColor: "#7C00DE",
                borderColor: "#7C00DE",
                fontSize: "13px",
                borderRadius: "6px",
              },
            }}
            variant="contained"
            // href={selectedJob && selectedJob.job_referral_url}
            onClick={() => {
              setopena(false);
            }}
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        TransitionComponent={Transition}
        open={opena3}
        sx={{ "& .MuiPaper-root": { color: "white", width: "400px" } }}
      >
        <DialogTitle
          bgcolor="#7c00de"
          sx={{ backgroundColor: "#7c00de", fontWeight: 600 }}
        >
          Delete Resume
        </DialogTitle>
        <DialogContent sx={{ mt: 5, mb: 2 }}>
          <DialogContentText sx={{ fontWeight: 600, color: "red" }}>
            Confirmation
          </DialogContentText>

          <p style={{ color: "black" }}>
            Are you sure want to delete Resume{deleteindex + 1}
          </p>
        </DialogContent>
        <DialogActions>
          <Button
            sx={{
              fontWeight: "bold",
              fontSize: "13px",
              backgroundColor: "#7C00DE",
              textTransform: "capitalize",
              color: "white",
              borderColor: "#7C00DE",
              borderRadius: "6px",
              "&:hover": {
                fontWeight: "bold",
                textTransform: "capitalize",
                color: "white",
                backgroundColor: "#7C00DE",
                borderColor: "#7C00DE",
                fontSize: "13px",
                borderRadius: "6px",
              },
            }}
            variant="contained"
            // href={selectedJob && selectedJob.job_referral_url}
            onClick={() => {
              setopena3(false);
            }}
          >
            cancel
          </Button>
          <Button
            sx={{
              fontWeight: "bold",
              fontSize: "13px",
              backgroundColor: "#7C00DE",
              textTransform: "capitalize",
              color: "white",
              borderColor: "#7C00DE",
              borderRadius: "6px",
              "&:hover": {
                fontWeight: "bold",
                textTransform: "capitalize",
                color: "white",
                backgroundColor: "#7C00DE",
                borderColor: "#7C00DE",
                fontSize: "13px",
                borderRadius: "6px",
              },
            }}
            variant="contained"
            // href={selectedJob && selectedJob.job_referral_url}
            onClick={() => {
              deleteresume(deleteindex);
            }}
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        TransitionComponent={Transition}
        open={opena4}
        sx={{ "& .MuiPaper-root": { color: "white", width: "400px" } }}
      >
        <DialogTitle
          bgcolor="#7c00de"
          sx={{ backgroundColor: "#7c00de", fontWeight: 600 }}
        >
          Upload Resume
        </DialogTitle>
        <DialogContent sx={{ mt: 5, mb: 2 }}>
          <DialogContentText sx={{ fontWeight: 600, color: "red" }}>
            Alert
          </DialogContentText>

          <p style={{ color: "black" }}>
            Maximum number of resume upload is reached
          </p>
        </DialogContent>
        <DialogActions>
          <Button
            sx={{
              fontWeight: "bold",
              fontSize: "13px",
              backgroundColor: "#7C00DE",
              textTransform: "capitalize",
              color: "white",
              borderColor: "#7C00DE",
              borderRadius: "6px",
              "&:hover": {
                fontWeight: "bold",
                textTransform: "capitalize",
                color: "white",
                backgroundColor: "#7C00DE",
                borderColor: "#7C00DE",
                fontSize: "13px",
                borderRadius: "6px",
              },
            }}
            variant="contained"
            // href={selectedJob && selectedJob.job_referral_url}
            onClick={() => {
              setopena4(false);
            }}
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Profile;
