// JobListing.js
import React, { useEffect, useState } from "react";
import DOMPurify from "dompurify"; // For sanitizing HTML
import Skeleton from "@mui/material/Skeleton";
import he from "he"; // For decoding HTML entities
import PlaceOutlinedIcon from "@mui/icons-material/PlaceOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  Button,
  Chip,
  Box,
  Divider,
  TextField,
  Autocomplete,
  Pagination,
  Checkbox,
  useMediaQuery,
  useTheme,
  Container,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormHelperText,
  InputAdornment,
} from "@mui/material";
import WorkIcon from "@mui/icons-material/Work";
import {
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  List,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FmdGoodOutlinedIcon from "@mui/icons-material/FmdGoodOutlined";
import { Stack } from "@mui/system";
import JobsUpdatePopup from "./JobsUpdatePopup";
import { useLocation, useNavigate } from "react-router-dom";
import useAxios from "../../../api/useAxios";
import { ROUTINGDATA } from "../../ROUTINGDATA";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { ListItem } from "@mui/material-next";
import LoadingButton from "../../LoadingButton";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const cities = [
  { label: "New York" },
  { label: "Los Angeles" },
  { label: "Chicago" },
  { label: "Houston" },
  { label: "Phoenix" },
  // Add more cities as needed
];

function convertToLakhs(amount) {
  // Check if the amount is a number
  if (isNaN(amount)) {
    return "Invalid number";
  }

  // Convert the amount to lakhs
  const lakhs = amount / 100000;

  // Format the number with commas and two decimal places
  return (
    lakhs.toLocaleString("en-IN", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }) + " lakhs"
  );
}
const NewJobDetailsPage = () => {
  const [loading, setloading] = useState(true);
  const [buttonLoading, setButtonLoading] = useState(false);
  const renderParagraphs = (description) => {
    const decodedHtml = he.decode(description);
    // Sanitize the decoded HTML to avoid XSS attacks
    const sanitizedHtml = DOMPurify.sanitize(decodedHtml);
    return sanitizedHtml;
  };
  let seekerDetails = JSON.parse(localStorage.getItem("seeker"));
  const [opena, setopena] = useState(false);
  const [opena2, setopena2] = useState(false);
  const [selectedJob, setSelectedJob] = useState();
  const location = useLocation();
  const axiosData = useAxios();
  const [open, setOpen] = useState(false);
  const search = location.search;
  const params = new URLSearchParams(search);
  // Aryan changes from here
  const [selectedResume, setSelectedResume] = useState("");
  const [error, setError] = useState(false);
  const [resumeLinks, setresumeLinks] = useState([]);
  // const resumeLinks = [
  //   "https://example.com/resume1.pdf",
  //   "https://example.com/resume2.pdf",
  //   "https://example.com/resume3.pdf",
  // ];

  const handleResumeSelection = (event) => {
    setSelectedResume(event.target.value);
    setError(false);
  };

  const handleConfirm = () => {
    if (!selectedResume) {
      setError(true);
    } else {
      handleCreate();
      // You can pass the selected resume to the function or do something with it
    }
  };
  // to here
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const [currentPage, setCurrentPage] = useState(1);
  const [jobListings, setjobListings] = useState([]);
  const [currentJobs, setcurrentJobs] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [searchLocation, setSearchLocation] = useState("");
  const [localKeyword, setLocalKeyword] = useState("");
  const [localLocation, setLocalLocation] = useState("");
  const [seekerProfileDetails, setSeekerProfileDetails] = useState();
  const handleJobClick = (job) => {
    setSelectedJob(job);
  };
  const getSeekerDetail = async () => {
    try {
      const response = await axiosData.get(
        `seekers/email/${seekerDetails.emailId}`,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.data) {
        setSeekerProfileDetails(response.data);
        console.log(response.data.resumelink.split(","));
        setresumeLinks(response.data.resumelink.split(","));
      }
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    getSeekerDetail();
  }, []);
  useEffect(
    () => {
      const getjobs = async () => {
        setloading(false);
        // Convert URLSearchParams to a JavaScript object
        const obj = {};
        params.forEach((value, key) => {
          obj[key] = value;
        });
        let jobtitle = obj.Categories ? obj.Categories : "";
        if (obj.keyword) {
          jobtitle = jobtitle + obj.keyword;
        }
        // const array = obj.salaryRange.split("-")
        console.log(obj);
        try {
          const response = await axiosData.post(
            `jobs/search2`,
            {
              job_id: obj.job_id ? obj.job_id : "",
              job_title: jobtitle ? jobtitle : "",
              page_number: obj.page ? obj.page : currentPage,
              limit: 7,
              location_type: obj.location_type ? obj.location_type : "",
              job_type: obj.job_type ? obj.job_type : "",
              location: obj.location ? obj.location : "",
              salaryRange: obj.salaryRange ? obj.salaryRange : "",
              location: obj.location ? obj.location : "",
            },
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          );
          if (response.data) {
            //response.data.data.unshift(response.data.slectedjob[0])
            setjobListings(response.data);
            setcurrentJobs(response.data.data);
            setSelectedJob(response.data.slectedjob[0]);
            // setloading(true);
            setloading(true);
          }
          if (response.status === 204) {
          }
        } catch (err) {
          // alert("No jobs for your search");

          console.log(err);
        }
      };
      getjobs();
    },
    [currentPage, location.search],
    searchKeyword,
    searchLocation
  );
  // Function to handle accordion expansion
  const jobsPerPage = 7;

  // Calculate the indices for slicing the jobListings array
  const indexOfLastJob = currentPage * jobsPerPage;
  const indexOfFirstJob = indexOfLastJob - jobsPerPage;

  // Slice the jobListings array to get the jobs for the current page
  // const currentJobs = jobListings.slice(indexOfFirstJob, indexOfLastJob);

  // Handle page changes
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
    updateUrl("", "", value);
  };

  const [selectedOptions, setSelectedOptions] = React.useState({});

  const handleChange = (id) => (event, value) => {
    setSelectedOptions((prev) => ({
      ...prev,
      [id]: value,
    }));
  };

  const handleDeleteChip = (id, chip) => () => {
    setSelectedOptions((prev) => ({
      ...prev,
      [id]: prev[id].filter((option) => option !== chip),
    }));
  };

  const navigate = useNavigate();

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    query.forEach((value, key) => {
      if (key === "page") {
        setCurrentPage(parseInt(value, 10));
      } else if (key === "keyword") {
        setSearchKeyword(value);
      } else {
        setSearchLocation(value);
      }
    });
  }, [location.search]);

  const handleSearchChange = (event) => {
    const { name, value } = event.target;
    if (name === "keyword") {
      setLocalKeyword(value);
    } else if (name === "location") {
      setLocalLocation(value);
    }
  };

  const handleSearchClick = () => {
    setSearchKeyword(localKeyword);
    setSearchLocation(localLocation);
    updateUrl(localKeyword, localLocation);
  };

  const updateUrl = (keyword = "", location = "", page = 1) => {
    const query = new URLSearchParams();

    // Add filters to query

    // Add search values to query
    if (keyword) {
      query.set("keyword", keyword);
    }
    if (location) {
      query.set("location", location);
    }

    // Add pagination to query
    query.set("page", page);

    // Update the URL with query parameters
    navigate(`/${ROUTINGDATA.NewFindJobsPage}?${query}`);
  };
  const handleCreate = async () => {
    setButtonLoading(true);
    try {
      const res = await fetch(
        "https://partnerapi.optimhire.com/v1/partner/candidate",
        {
          method: "POST",
          headers: {
            Authorization:
              "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwczpcL1wvcGFydG5lcmFwaS5vcHRpbWhpcmUuY29tXC8iLCJhdWQiOiJodHRwczpcL1wvcGFydG5lcmFwaS5vcHRpbWhpcmUuY29tXC8iLCJpYXQiOjE3MjE4OTUwMDMsIm5iZiI6MTcyMTg5NTAwMywiZGF0YSI6eyJyZWZfdXNlcl9pZCI6IjQyMDY1OCIsInJlZl91c2VyX2lwIjoiMTEwLjIyNC44My4yMzYiLCJyZWZfZmlyc3RfbmFtZSI6IlVTIEdMT0JBTCAiLCJyZWZfbGFzdF9uYW1lIjoiU09MVVRJT05TIiwicmVmX3VzZXJfZW1haWwiOiJjZW9AdXNnbG9iYWxzb2x1dGlvbnMuY29tIiwicmVmX2ltYWdlIjpudWxsLCJyZWZfdXNlcl90eXBlIjoib3RoZXIifX0.K2P-lhIGCgcqA2V0CsCfsBXTBco_Vnbrl7xH8fSDtaM", // Replace with your token
            Cookie: "ci_session=kat7urco4blrkm8u9p4r7nkeoe1une2v",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            first_name: seekerProfileDetails.full_name, // Replace with the actual first name
            last_name: "", // Replace with the actual last name
            resume: seekerProfileDetails.resumelink
              ? seekerProfileDetails.resumelink
              : `https://velaivendum.com/p-services/seekers/resume/${seekerProfileDetails.mobile_number}`, // Replace with the actual resume link
            mobile: String(seekerProfileDetails.mobile_number), // Replace with the actual mobile number
            mobile_country_code: 91, // Replace with the actual mobile code
            email: seekerProfileDetails.email_id, // Replace with the actual email
          }),
        }
      );

      const data = await res.json();
      window.open(selectedJob.job_referral_url, "_blank");
    } catch (error) {
      console.error("Error:", error);
    }
    setButtonLoading(false);
  };

  const apply = () => {
    window.open(selectedJob.job_referral_url, "_blank");
    // if (seekerDetails) {
    //   setopena2(true);
    // } else {
    //   setopena(true);
    // }
  };
  const Applyjob = async (job) => {
    let res;
    handleCreate();
    try {
      res = await axiosData.post(
        `jobapply/`,
        {
          job_id: job._id,
          employer_id: job.user_id,
          seeker_id: seekerDetails.id,
          seeker_name: seekerProfileDetails.full_name,
          seeker_email: seekerProfileDetails.email_id,
          seeker_mobile_number: seekerProfileDetails.mobile_number,
          contact_email_id: job.contact_email_id,
          job_title: job.job_title,
          contact_mobile_number: job.phone_number,
          source_from: `Jobpost-${job._id}`,
          fileName: seekerProfileDetails.fileUpload.name,
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (res.status === 201) {
      }
    } catch (error) {
      if (error.response.status === 403) {
        localStorage.removeItem("seeker");
      }
    }
  };
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const uniqueSkills = Array.from(
    new Set(selectedJob?.skills.map((skill) => skill.skill_name))
  );
  const uniquePositions = Array.from(
    new Set(selectedJob?.positions.map((position) => position.position_name))
  );

  if (loading) {
    return (
      <div
        style={{
          paddingTop: isMobile ? "7rem" : "8rem",
          paddingInline: isMobile ? "10px" : 0,
        }}
      >
        <div
          style={{
            fontFamily: "Exo2Italic",
            color: "#434343",
            textAlign: "center",
          }}
        >
          <text
            style={{ fontSize: isMobile ? "24px" : "42px", fontWeight: "bold" }}
          >
            Find your <span style={{ color: "#7C00DE" }}>Dream Job</span>
          </text>
          <p style={{ color: "#515B6F" }}>
            Find your next career at companies like HubSpot, Nike, and Dropbox
          </p>
        </div>
        <Container maxWidth="lg" sx={{ py: 4 }}>
          <Card sx={{ mb: 4, p: 3 }}>
            <Grid container spacing={4}>
              <Grid item md={5.2} xs={12}>
                <FormControl fullWidth>
                  <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                    <SearchIcon sx={{ color: "#25324B", my: 0.5 }} />
                    <TextField
                      fullWidth
                      name="keyword"
                      value={localKeyword}
                      onChange={handleSearchChange}
                      placeholder="Job title or keyword"
                      variant="standard"
                      sx={{
                        mr: 2,
                        pl: 2,
                        "& .MuiInput-underline:before": {
                          borderBottomColor: "#D6DDEB", // color when input is not focused
                        },
                        "& .MuiInput-underline:hover:before": {
                          borderBottomColor: "#7C00DE", // color when input is hovered
                        },
                        "& .MuiInput-underline:after": {
                          borderBottomColor: "#7C00DE", // color when input is focused
                        },
                      }}
                    />
                  </Box>
                </FormControl>
              </Grid>
              <Grid item md={5.2} xs={12}>
                <FormControl fullWidth>
                  <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                    <FmdGoodOutlinedIcon sx={{ color: "#25324B", my: 0.5 }} />
                    <TextField
                      fullWidth
                      name="location"
                      value={localLocation}
                      onChange={handleSearchChange}
                      placeholder="Location"
                      variant="standard"
                      sx={{
                        mr: 2,
                        pl: 2,
                        "& .MuiInput-underline:before": {
                          borderBottomColor: "#D6DDEB", // color when input is not focused
                        },
                        "& .MuiInput-underline:hover:before": {
                          borderBottomColor: "#7C00DE", // color when input is hovered
                        },
                        "& .MuiInput-underline:after": {
                          borderBottomColor: "#7C00DE", // color when input is focused
                        },
                      }}
                    />
                  </Box>
                </FormControl>
              </Grid>
              <Grid item md={1.6} xs={12}>
                <Button
                  variant="contained"
                  fullWidth
                  onClick={handleSearchClick}
                  sx={{
                    mt: -1.6,
                    py: 1.2,
                    fontWeight: "bold",
                    fontSize: "13px",
                    backgroundColor: "#7C00DE",
                    textTransform: "capitalize",
                    color: "white",
                    borderColor: "#7C00DE",
                    borderRadius: "6px",
                    "&:hover": {
                      fontWeight: "bold",
                      textTransform: "capitalize",
                      color: "white",
                      backgroundColor: "#7C00DE",
                      borderColor: "#7C00DE",
                      fontSize: "13px",
                      borderRadius: "6px",
                    },
                  }}
                >
                  Search
                </Button>
              </Grid>
            </Grid>
          </Card>

          <Grid
            container
            spacing={2}
            sx={{ border: "1px solid #D6DDEB", mt: 2 }}
          >
            <Grid
              item
              xs={12}
              md={5}
              sx={{
                borderRight: "2px solid #D6DDEB",
                p: 3,
                display: !isMobile ? "block" : "none",
              }}
            >
              {currentJobs &&
                currentJobs.map((job) => (
                  <Card
                    key={job.job_id}
                    onClick={() => handleJobClick(job)}
                    sx={{
                      mb: 2,
                      cursor: "pointer",
                      border:
                        selectedJob.job_id === job.job_id
                          ? "1px solid #7C00DE"
                          : "1px solid #D6D6D6",
                      "&:hover": {
                        border: "1px solid #a13fef",
                      },
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        p: 2,
                      }}
                    >
                      <Box
                        component="img"
                        src={job.logo}
                        alt="company logo"
                        sx={{ mr: 2, height: "50px", width: "70px" }}
                      />
                      <Box sx={{ flexGrow: 1 }}>
                        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                          {job.job_title}
                        </Typography>
                        <Typography variant="body2" sx={{ color: "#454545" }}>
                          <PlaceOutlinedIcon sx={{ mb: -0.7 }} />
                          {job.location ? job.location : job.location_type}
                        </Typography>

                        <Chip
                          label={job.job_type}
                          color="primary"
                          sx={{
                            bgcolor: "#C7FF8F",
                            color: "#000000",
                            padding: 0,
                            "& .MuiChip-label": {
                              fontSize: "11px",
                            },
                          }}
                        />
                        <Typography sx={{ fontWeight: "bold" }}>
                          {job.job_type === "contract"
                            ? `${job.salary} INR/Hour`
                            : `${convertToLakhs(
                                job.min_charge
                              )}-${convertToLakhs(job.max_charge)}`}
                        </Typography>
                      </Box>
                    </Box>
                  </Card>
                ))}
            </Grid>
            {selectedJob && (
              <Grid item xs={12} md={7}>
                <Box sx={{ p: 2 }}>
                  <Box
                    sx={{
                      display: !isMobile ? "flex" : "block",
                      alignItems: "center",
                      border: "1px solid #D6DDEB",
                      p: 3,
                    }}
                  >
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <Box
                        component="img"
                        src={selectedJob.logo}
                        alt="company logo"
                        sx={{ mr: 2 }}
                      />
                    </div>
                    <Box sx={{ flexGrow: 1 }}>
                      <div style={{ display: "flex", gap: "10px" }}>
                        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                          {selectedJob.job_title}
                        </Typography>
                        <Chip
                          label={selectedJob.job_type}
                          color="primary"
                          sx={{
                            bgcolor: "#C7FF8F",
                            color: "#000000",
                            padding: 0,
                            "& .MuiChip-label": {
                              fontSize: "11px",
                            },
                          }}
                        />
                      </div>
                      <Typography variant="body2" sx={{ color: "#454545" }}>
                        <PlaceOutlinedIcon sx={{ mb: -0.7 }} />
                        {selectedJob.location
                          ? selectedJob.location
                          : selectedJob.location_type}
                      </Typography>

                      <Typography sx={{ fontWeight: "bold" }}>
                        {selectedJob.job_type === "contract"
                          ? `${selectedJob.salary} INR/Hour`
                          : `${convertToLakhs(
                              selectedJob.min_charge
                            )}-${convertToLakhs(selectedJob.max_charge)}`}
                      </Typography>
                    </Box>
                    <Button
                      onClick={apply}
                      variant="contained"
                      sx={{
                        padding: "10px 40px",
                        fontWeight: "bold",
                        fontSize: "13px",
                        backgroundColor: "#7C00DE",
                        textTransform: "capitalize",
                        color: "white",
                        borderColor: "#7C00DE",
                        borderRadius: "6px",
                        "&:hover": {
                          fontWeight: "bold",
                          textTransform: "capitalize",
                          color: "white",
                          backgroundColor: "#7C00DE",
                          borderColor: "#7C00DE",
                          fontSize: "13px",
                          borderRadius: "6px",
                        },
                      }}
                    >
                      Apply
                    </Button>
                  </Box>
                  <Divider sx={{ mb: 2 }} />

                  <div className="customScroll">
                    <div>
                      <h3>Skills:</h3>
                      <ul>
                        {uniqueSkills?.map((skill, index) => (
                          <li key={index}>{skill}</li>
                        ))}
                      </ul>
                    </div>

                    <div>
                      <h3>Positions:</h3>
                      <ul>
                        {uniquePositions?.map((position, index) => (
                          <li key={index}>{position}</li>
                        ))}
                      </ul>
                    </div>
                    <div
                      className="custom-list"
                      dangerouslySetInnerHTML={{
                        __html: renderParagraphs(selectedJob.description),
                      }}
                    ></div>
                  </div>
                </Box>
              </Grid>
            )}
          </Grid>
          <Box
            sx={{
              display: isMobile ? "none" : "flex",
              justifyContent: "center",
              mt: 4,
            }}
          >
            <Pagination
              count={Math.ceil(
                jobListings && jobListings.total_record / jobsPerPage
              )}
              page={currentPage}
              onChange={handlePageChange}
              sx={{
                "& .MuiPaginationItem-root": {
                  color: "#7C00DE",
                },
                "& .MuiPaginationItem-page.Mui-selected": {
                  backgroundColor: "#7C00DE",
                  color: "#FFFFFF",
                },
                "& .MuiPaginationItem-ellipsis": {
                  color: "#7C00DE",
                },
              }}
            />
          </Box>
        </Container>
        <JobsUpdatePopup open={open} onClose={handleClose} />
        <Dialog
          TransitionComponent={Transition}
          open={opena}
          // onClose={() => setLogin(false)}
          sx={{ "& .MuiPaper-root": { color: "white", width: "400px" } }}
        >
          <DialogTitle
            bgcolor="#7c00de"
            sx={{ backgroundColor: "#7c00de", fontWeight: 600 }}
          >
            Login
          </DialogTitle>
          <DialogContent sx={{ mt: 5, mb: 2 }}>
            <DialogContentText sx={{ fontWeight: 600 }}>
              Please login first to apply job !
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              color="error"
              variant="outlined"
              onClick={() => setopena(false)}
            >
              Cancel
            </Button>
            <Button
              sx={{
                fontWeight: "bold",
                fontSize: "13px",
                backgroundColor: "#7C00DE",
                textTransform: "capitalize",
                color: "white",
                borderColor: "#7C00DE",
                borderRadius: "6px",
                "&:hover": {
                  fontWeight: "bold",
                  textTransform: "capitalize",
                  color: "white",
                  backgroundColor: "#7C00DE",
                  borderColor: "#7C00DE",
                  fontSize: "13px",
                  borderRadius: "6px",
                },
              }}
              variant="contained"
              onClick={() => {
                navigate("/login");
                // setLogin(false);
              }}
            >
              Ok
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={opena2}
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "100%",
                borderRadius: "16px",
              },
            },
          }}
        >
          <DialogTitle
            bgcolor="#7c00de"
            sx={{ backgroundColor: "#7c00de", fontWeight: 600, color: "white" }}
          >
            Apply for Job
          </DialogTitle>
          <DialogContent>
            <br />
            <Typography sx={{ color: "black" }}>Mail Id</Typography>
            <TextField
              fullWidth
              value={seekerProfileDetails && seekerProfileDetails.email_id}
              InputProps={{
                readOnly: true,
                startAdornment: (
                  <InputAdornment position="start">
                    <EmailOutlinedIcon />
                  </InputAdornment>
                ),
              }}
            />
            <br />
            <br />
            <Typography sx={{ color: "black" }}>Phone No.</Typography>
            <TextField
              fullWidth
              value={seekerProfileDetails && seekerProfileDetails.mobile_number}
              InputProps={{
                readOnly: true,
                startAdornment: (
                  <InputAdornment position="start">
                    <LocalPhoneOutlinedIcon />
                  </InputAdornment>
                ),
              }}
            />
            <br />
            <br />
            <Typography sx={{ color: "black" }}>
              Please select a resume link
              <Typography sx={{ color: "red" }} component="span">
                *
              </Typography>
            </Typography>
            <RadioGroup value={selectedResume} onChange={handleResumeSelection}>
              {resumeLinks &&
                resumeLinks.map((link, index) => {
                  const isSelected = selectedResume === link;
                  return (
                    <Box
                      key={index}
                      sx={{
                        border: `2px solid ${isSelected ? "#7C00DE" : "#ccc"}`,
                        borderRadius: "5px",
                        padding: "10px",
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "10px",
                        transition: "0.3s",
                      }}
                    >
                      <FormControlLabel
                        value={link}
                        control={
                          <Radio
                            sx={{
                              color: isSelected ? "#7C00DE" : "default",
                              "&.Mui-checked": {
                                color: "#7C00DE",
                              },
                            }}
                          />
                        }
                        label={
                          <Typography
                            sx={{
                              width: "300px",
                              wordWrap: "break-word",
                              lineHeight: "2em",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                              transition: "0.5s",
                              padding: "0px 5px",
                              borderRadius: "5px",
                              fontSize: "1rem",
                              fontFamily: "Barlow-Regular",
                              cursor: "pointer",
                              ":hover": {
                                width: "100%",
                              },
                            }}
                            onClick={() => {
                              window.open(
                                link,
                                "_blank",
                                "noopener,noreferrer"
                              );
                            }}
                          >
                            {link}
                          </Typography>
                        }
                        sx={{ flexGrow: 1 }}
                      />
                    </Box>
                  );
                })}
            </RadioGroup>
            {error && (
              <FormHelperText error>
                Please select a resume link before proceeding.
              </FormHelperText>
            )}
          </DialogContent>
          <DialogActions>
            <Button
              color="error"
              variant="outlined"
              onClick={() => setopena2(false)}
            >
              Cancel
            </Button>
            <Button
              sx={{
                fontWeight: "bold",
                fontSize: "13px",
                backgroundColor: "#7C00DE",
                textTransform: "capitalize",
                color: "white",
                borderColor: "#7C00DE",
                borderRadius: "6px",
                "&:hover": {
                  fontWeight: "bold",
                  textTransform: "capitalize",
                  color: "white",
                  backgroundColor: "#7C00DE",
                  borderColor: "#7C00DE",
                  fontSize: "13px",
                  borderRadius: "6px",
                },
              }}
              variant="contained"
              onClick={handleConfirm}
            >
              Confirm <LoadingButton loading={buttonLoading} />
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  } else {
    return (
      <div
        style={{
          paddingTop: isMobile ? "7rem" : "8rem",
          paddingInline: isMobile ? "10px" : 0,
        }}
      >
        {/* Header Skeleton */}
        <div
          style={{
            fontFamily: "Exo2Italic",
            color: "#434343",
            textAlign: "center",
          }}
        >
          <Skeleton
            variant="text"
            sx={{ fontSize: isMobile ? "24px" : "42px", fontWeight: "bold" }}
          />
          <Skeleton
            variant="text"
            sx={{
              fontSize: "16px",
              width: isMobile ? "100%" : "60%",
              margin: "0 auto",
            }}
          />
        </div>

        {/* Search Section Skeleton */}
        <Container maxWidth="lg" sx={{ py: 4 }}>
          <Card sx={{ mb: 4, p: 3 }}>
            <Grid container spacing={4}>
              <Grid item md={5.2} xs={12}>
                <Skeleton variant="rectangular" width="100%" height={56} />
              </Grid>
              <Grid item md={5.2} xs={12}>
                <Skeleton variant="rectangular" width="100%" height={56} />
              </Grid>
              <Grid item md={1.6} xs={12}>
                <Skeleton variant="rectangular" width="100%" height={56} />
              </Grid>
            </Grid>
          </Card>

          {/* Job Listings Skeleton */}
          <Grid
            container
            spacing={2}
            sx={{ border: "1px solid #D6DDEB", mt: 2 }}
          >
            <Grid
              item
              xs={12}
              md={5}
              sx={{
                borderRight: "2px solid #D6DDEB",
                p: 3,
                display: !isMobile ? "block" : "none",
              }}
            >
              {Array.from(new Array(3)).map((_, index) => (
                <Card key={index} sx={{ mb: 2 }}>
                  <Box sx={{ display: "flex", alignItems: "center", p: 2 }}>
                    <Skeleton
                      variant="rectangular"
                      width={70}
                      height={50}
                      sx={{ mr: 2 }}
                    />
                    <Box sx={{ flexGrow: 1 }}>
                      <Skeleton
                        variant="text"
                        sx={{ fontSize: "1rem", mb: 1 }}
                      />
                      <Skeleton
                        variant="text"
                        sx={{ fontSize: "0.875rem", mb: 1 }}
                      />
                      <Skeleton
                        variant="text"
                        sx={{ fontSize: "0.875rem", mb: 1 }}
                      />
                      <Skeleton variant="text" sx={{ fontSize: "0.875rem" }} />
                    </Box>
                  </Box>
                </Card>
              ))}
            </Grid>

            {/* Job Details Skeleton */}
            <Grid item xs={12} md={7}>
              <Box sx={{ p: 2 }}>
                <Box
                  sx={{
                    display: !isMobile ? "flex" : "block",
                    alignItems: "center",
                    border: "1px solid #D6DDEB",
                    p: 3,
                  }}
                >
                  <Skeleton
                    variant="rectangular"
                    width={70}
                    height={50}
                    sx={{ mr: 2 }}
                  />
                  <Box sx={{ flexGrow: 1 }}>
                    <Skeleton variant="text" sx={{ fontSize: "1rem", mb: 1 }} />
                    <Skeleton
                      variant="text"
                      sx={{ fontSize: "0.875rem", mb: 1 }}
                    />
                    <Skeleton variant="text" sx={{ fontSize: "0.875rem" }} />
                  </Box>
                  <Skeleton variant="rectangular" width={100} height={40} />
                </Box>
                <Divider sx={{ mb: 2 }} />
                <Skeleton variant="text" sx={{ mb: 2 }} />
                <Skeleton variant="text" sx={{ mb: 2 }} />
                <Skeleton variant="text" sx={{ mb: 2 }} />
              </Box>
            </Grid>
          </Grid>

          {/* Pagination Skeleton */}
          <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
            <Skeleton variant="rectangular" width={200} height={40} />
          </Box>
        </Container>
      </div>
    );
  }
};

export default NewJobDetailsPage;
