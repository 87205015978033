import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  AppBar,
  Box,
  Toolbar,
  Button,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  useTheme,
  useMediaQuery,
  Tooltip,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import logo from "../../../img/New Design Images/career365 logo1.png";
import { CurrentStateContext } from "../../../App";
import { ROUTINGDATA } from "../../ROUTINGDATA";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import "../Landingpage.css";

export default function NewHeader(props) {
  const navigate = useNavigate();
  const [headericon, setHeadericon] = useState("35px");
  const [headerPadding, setHeaderPadding] = useState("5px");
  const [headerBackground, setHeaderBackground] = useState("transparent");
  const [headerbtn, setHeaderbtn] = useState("whitesmoke");
  const [drawerOpen, setDrawerOpen] = useState(false);
  const { t, ready } = useTranslation();
  const { languagetranslator } = useContext(CurrentStateContext);
  const location = useLocation();
  const currentUrl = location.pathname;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPosition =
        document.documentElement.scrollTop || document.body.scrollTop;
      if (currentScrollPosition > 0) {
        setHeaderBackground("whitesmoke");
        setHeaderbtn("#0675a2");
        setHeadericon("0");
        setHeaderPadding("0");
      } else {
        setHeaderBackground("transparent");
        setHeaderbtn("white");
        setHeadericon("35px");
        setHeaderPadding("5px");
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleChangeLanguage = (val) => {
    props.setlanguagetranslator(val);
  };

  useEffect(() => {
    handleChangeLanguage();
  }, [languagetranslator]);

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const [isClicked, setIsClicked] = useState(false);

  const handleClick = () => {
    setIsClicked(true);
    navigate(ROUTINGDATA.NewFindJobsPage);
    window.scrollTo(0, 0);
  };
  const handleClickApplied = () => {
    // setIsClicked(true);
    navigate(ROUTINGDATA.AppliedJobs);
  };

  const handleClickContact = () => {
    // setIsClicked(true);
    navigate(ROUTINGDATA.LANDPAGECONTACTUS);
    window.scrollTo(0, 0);
  };
  const isCurrentPage0 = location.pathname.split()[0] === "/";
  const isCurrentPage = location.pathname.includes(ROUTINGDATA.NewFindJobsPage);
  const isCurrentPage1 = location.pathname.includes(
    ROUTINGDATA.NewJobDetailsPage
  );
  const isCurrentPage2 = location.pathname.includes(ROUTINGDATA.AppliedJobs);
  const isCurrentPage3 = location.pathname.includes(
    ROUTINGDATA.LANDPAGECONTACTUS
  );
  if (!ready) {
    return (
      <div
        style={{
          height: "100vh",
          display: "grid",
          placeItems: "center",
        }}
      >
        <div className="dots"></div>
      </div>
    );
  }

  const getEmail = JSON.parse(localStorage.getItem("seeker"));
  return (
    <Box sx={{ flexGrow: 1, overflowX: "hidden" }}>
      {/* <div className="headericon">
        <div
          className="headericonspace"
          style={{
            height: headericon,
            transition: "0.2s",
            padding: headerPadding,
          }}
        >
          {!isMobile ? (
            <div>
              <span
                style={{
                  marginTop: "5px",
                  marginRight: "5px",
                  fontWeight: "550",
                  color: "#626262",
                }}
              >
                Are you looking to hire?
              </span>
              <Button
                onClick={() => {
                  navigate(ROUTINGDATA.SignUp);
                  window.scrollTo(0, 0);
                }}
                variant="contained"
                sx={{
                  fontWeight: "bold",
                  fontSize: "13px",
                  backgroundColor: "#7C00DE",
                  textTransform: "capitalize",
                  color: "white",
                  borderColor: "#7C00DE",
                  borderRadius: "6px",
                  "&:hover": {
                    fontWeight: "bold",
                    textTransform: "capitalize",
                    color: "white",
                    backgroundColor: "#7C00DE",
                    borderColor: "#7C00DE",
                    fontSize: "13px",
                    borderRadius: "6px",
                  },
                }}
              >
                Sign Up as Client
              </Button>
            </div>
          ) : (
            <Button
              onClick={() => {
                navigate(ROUTINGDATA.SignUp);
                window.scrollTo(0, 0);
              }}
              variant="text"
              sx={{
                fontWeight: "bold",
                fontSize: "13px",
                textTransform: "capitalize",
                color: "#7C00DE",
                "&:hover": {
                  fontWeight: "bold",
                  textTransform: "capitalize",
                  color: "#7C00DE",
                  fontSize: "13px",
                },
              }}
            >
              Sign Up as Client
            </Button>
          )}
        </div>
      </div> */}
      <AppBar
        className="boxshadowlanding"
        position="static"
        sx={{
          backgroundColor: "#F3E3FF",
          transition: "0.3s",
          position: "sticky",
          top: 0,
        }}
      >
        <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
          <div>
            <img
              style={{ cursor: "pointer", height: "50px", width: "100%" }}
              onClick={() => {
                navigate(ROUTINGDATA.LAND);
                window.scrollTo(0, 0);
              }}
              src={logo}
              alt="logo"
            />
          </div>

          <div style={{ display: "flex", gap: "2rem" }}>
            {!isMobile && (
              <p
                style={{
                  display: { xs: "none", md: "block" },
                  color: isCurrentPage0 ? "#7C00DE" : "#434343",
                  fontWeight: 550,
                  cursor: "pointer",
                  borderBottom: isCurrentPage0 ? "2px solid #7C00DE" : "none",
                }}
                onClick={() => {
                  navigate(ROUTINGDATA.LAND);
                  window.scrollTo(0, 0);
                }}
              >
                Home
              </p>
            )}
            {!isMobile && (
              <p
                style={{
                  display: { xs: "none", md: "block" },
                  color:
                    isCurrentPage || isCurrentPage1 ? "#7C00DE" : "#434343",
                  fontWeight: 550,
                  cursor: "pointer",
                  borderBottom:
                    isCurrentPage || isCurrentPage1
                      ? "2px solid #7C00DE"
                      : "none",
                }}
                onClick={handleClick}
              >
                Find Jobs
              </p>
            )}
            {/* {!isMobile && (
              <p
                style={{
                  display: { xs: "none", md: "block" },
                  color: isCurrentPage2 ? "#7C00DE" : "#434343",
                  fontWeight: 550,
                  cursor: "pointer",
                  borderBottom: isCurrentPage2 ? "2px solid #7C00DE" : "none",
                }}
                onClick={handleClickApplied}
              >
                Applied jobs
              </p>
            )} */}

            {!isMobile && (
              <p
                style={{
                  display: { xs: "none", md: "block" },
                  color: isCurrentPage3 ? "#7C00DE" : "#434343",
                  fontWeight: 550,
                  cursor: "pointer",
                  borderBottom: isCurrentPage3 ? "2px solid #7C00DE" : "none",
                }}
                onClick={handleClickContact}
              >
                Contact Us
              </p>
            )}
          </div>

          {!isMobile && (
            <div style={{ display: "flex", gap: "10px" }}>
              {!getEmail && (
                <>
                  <Button
                    onClick={() => {
                      navigate(ROUTINGDATA.LOGIN);
                      window.scrollTo(0, 0);
                    }}
                    variant="text"
                    sx={{
                      fontWeight: "bold",
                      fontSize: "13px",
                      textTransform: "capitalize",
                      color: "#AA5DE9",
                      "&:hover": {
                        fontWeight: "bold",
                        textTransform: "capitalize",
                        color: "#AA5DE9",
                      },
                    }}
                  >
                    Login
                  </Button>

                  <Button
                    onClick={() => {
                      navigate(ROUTINGDATA.JobSeekerSignUp);
                      window.scrollTo(0, 0);
                    }}
                    variant="contained"
                    sx={{
                      fontWeight: "bold",
                      fontSize: "13px",
                      backgroundColor: "#7C00DE",
                      textTransform: "capitalize",
                      color: "white",
                      borderColor: "#7C00DE",
                      borderRadius: "6px",
                      "&:hover": {
                        fontWeight: "bold",
                        textTransform: "capitalize",
                        color: "white",
                        backgroundColor: "#7C00DE",
                        borderColor: "#7C00DE",
                        fontSize: "13px",
                        borderRadius: "6px",
                      },
                    }}
                  >
                    Sign Up
                  </Button>
                </>
              )}
              {getEmail && (
                <Tooltip title="Profile">
                  <IconButton
                    onClick={() => {
                      navigate(ROUTINGDATA.Profile);
                      window.scrollTo(0, 0);
                    }}
                  >
                    <AccountCircleIcon
                      sx={{ fontSize: 30, color: "#7C00DE" }}
                    />
                  </IconButton>
                </Tooltip>
                // <Button
                //   onClick={() => {
                //     navigate(ROUTINGDATA.Profile);
                //     window.scrollTo(0, 0);
                //   }}
                //   variant="contained"
                //   sx={{
                //     fontWeight: "bold",
                //     fontSize: "13px",
                //     backgroundColor: "#7C00DE",
                //     textTransform: "capitalize",
                //     color: "white",
                //     borderColor: "#7C00DE",
                //     borderRadius: "6px",
                //     "&:hover": {
                //       fontWeight: "bold",
                //       textTransform: "capitalize",
                //       color: "white",
                //       backgroundColor: "#7C00DE",
                //       borderColor: "#7C00DE",
                //       fontSize: "13px",
                //       borderRadius: "6px",
                //     },
                //   }}
                // >
                //   Profile
                // </Button>
              )}
            </div>
          )}
          {isMobile && (
            <IconButton
              edge="start"
              aria-label="menu"
              onClick={handleDrawerToggle}
            >
              <MenuIcon sx={{ color: "#7C00DE" }} />
            </IconButton>
          )}
        </Toolbar>
      </AppBar>
      <Drawer anchor="top" open={drawerOpen} onClose={handleDrawerToggle}>
        <List>
          <ListItem
            button
            onClick={() => {
              navigate(ROUTINGDATA.NewFindJobsPage);
              setDrawerOpen(false);
            }}
          >
            <ListItemText
              sx={{
                fontWeight: "bold",
                color: "#7C00DE",
                textAlign: "center",
                fontSize: "14px",
              }}
              primary="Find Jobs"
            />
          </ListItem>
          {/* <ListItem
            button
            onClick={() => {
              navigate(ROUTINGDATA.AppliedJobs);
              setDrawerOpen(false);
            }}
          >
            <ListItemText
              sx={{
                fontWeight: "bold",
                color: "#7C00DE",
                textAlign: "center",
                fontSize: "14px",
              }}
              primary="Applied Jobs"
            />
          </ListItem> */}
          <ListItem
            button
            onClick={() => {
              navigate(ROUTINGDATA.LANDPAGECONTACTUS);
              setDrawerOpen(false);
            }}
          >
            <ListItemText
              sx={{
                fontWeight: "bold",
                color: "#7C00DE",
                textAlign: "center",
                fontSize: "14px",
              }}
              primary="Contact Us"
            />
          </ListItem>
          {!getEmail && (
            <>
              <ListItem
                sx={{ display: "flex", justifyContent: "center" }}
                onClick={() => {
                  // navigate(ROUTINGDATA.LAND);
                  setDrawerOpen(false);
                }}
              >
                <Button
                  onClick={() => {
                    navigate(ROUTINGDATA.LOGIN);
                    window.scrollTo(0, 0);
                  }}
                  variant="outlined"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "14px",
                    textTransform: "capitalize",
                    color: "#AA5DE9",
                    borderColor: "#7C00DE",
                    "&:hover": {
                      fontWeight: "bold",
                      textTransform: "capitalize",
                      color: "#AA5DE9",
                      borderColor: "#7C00DE",
                    },
                  }}
                >
                  Login
                </Button>
              </ListItem>
              <ListItem
                sx={{ display: "flex", justifyContent: "center" }}
                onClick={() => {
                  // navigate(ROUTINGDATA.LAND);
                  setDrawerOpen(false);
                }}
              >
                <Button
                  onClick={() => {
                    navigate(ROUTINGDATA.JobSeekerSignUp);
                    window.scrollTo(0, 0);
                  }}
                  variant="contained"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "13px",
                    backgroundColor: "#7C00DE",
                    textTransform: "capitalize",
                    color: "white",
                    borderColor: "#7C00DE",
                    borderRadius: "6px",
                    "&:hover": {
                      fontWeight: "bold",
                      textTransform: "capitalize",
                      color: "white",
                      backgroundColor: "#7C00DE",
                      borderColor: "#7C00DE",
                      fontSize: "13px",
                      borderRadius: "6px",
                    },
                  }}
                >
                  Sign Up
                </Button>
              </ListItem>
            </>
          )}
          <ListItem
            sx={{ display: "flex", justifyContent: "center" }}
            onClick={() => {
              // navigate(ROUTINGDATA.LAND);
              setDrawerOpen(false);
            }}
          >
            {getEmail && (
              <Button
                onClick={() => {
                  navigate(ROUTINGDATA.Profile);
                  window.scrollTo(0, 0);
                }}
                variant="contained"
                sx={{
                  fontWeight: "bold",
                  fontSize: "13px",
                  backgroundColor: "#7C00DE",
                  textTransform: "capitalize",
                  color: "white",
                  borderColor: "#7C00DE",
                  borderRadius: "6px",
                  "&:hover": {
                    fontWeight: "bold",
                    textTransform: "capitalize",
                    color: "white",
                    backgroundColor: "#7C00DE",
                    borderColor: "#7C00DE",
                    fontSize: "13px",
                    borderRadius: "6px",
                  },
                }}
              >
                Profile
              </Button>
            )}
          </ListItem>
        </List>
      </Drawer>
    </Box>
  );
}
