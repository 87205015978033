import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router";
import { ROUTINGDATA } from "../../ROUTINGDATA";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import EmailIcon from "@mui/icons-material/Email";
import SmartphoneIcon from "@mui/icons-material/Smartphone";
import useAxios from "../../../api/useAxios";
import TextField from "@mui/material/TextField";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { useContext } from "react";
import { CurrentStateContext } from "../../../App";
import { Stack, Box, IconButton, Typography } from "@mui/material";
import { useTheme, useMediaQuery } from "@mui/material";

const LandingpageContactus = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [open, setopen] = useState();
  const [state, setState] = React.useState({
    open: false,
    vertical: "bottom",
    horizontal: "center",
  });
  const [focusEmail, setFocusEmail] = useState(false);

  const handleClick = (newState) => () => {
    setopen(true);
    setState({ ...newState });
  };
  const axiosData = useAxios();
  const { languagetranslator } = useContext(CurrentStateContext);
  const { t, i18n, ready } = useTranslation();

  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      // mobile_number: "",
      full_name: "",
      email_id: "",
      // subject: "",
      message: "",
    },
    validationSchema: Yup.object({
      full_name: Yup.string()
        .max(50, t("Must_be_50_characters_or_less"))
        .required(t("FullName is required")),
      // mobile_number: Yup.number()
      //   .min(1000000000, t("Not_Valid_Mobile_Number!"))
      //   .max(9999999999, t("Not_Valid_Mobile_Number!"))
      //   .required(t("Mobile_Number_must_be_Required")),
      email_id: Yup.string()
        .matches(
          /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
          t("Invalid_email_format")
        )
        .required(t("Email is required")),
      //subject: Yup.string().required(t("subject_must_be_Required")),
      message: Yup.string().required(t("message is requried")),
    }),
    onSubmit: async (values) => {
      const response = await axiosData.post("General/contactus", values, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (response.data.status === 200) {
        setopen(true);
        handleClick({ vertical: "bottom", horizontal: "center" });
      }
      formik.resetForm();
    },
  });

  const changeLanguage = (languagetranslator) => {
    i18n.changeLanguage(languagetranslator);
  };
  useEffect(
    () => {
      changeLanguage(languagetranslator);
    },
    //eslint-disable-next-line react-hooks/exhaustive-deps
    [languagetranslator]
  );

  if (ready) {
    return (
      <div style={{ paddingTop: "3rem" }}>
        {/* <div className="contactusbackpage">
          <div className="contactusbackpagefonts">
            <span
              className="backbtn"
              onClick={() => navigate(ROUTINGDATA.LAND)}
              style={{ color: "#135c82" }}
            >
              {t("Home")}
            </span>
            /{" "}
            <span style={{ color: "black" }}>
              {t("Call-To-Action")}
              {t("us")}
            </span>
          </div>
        </div> */}

        {/* <div className="contactpage-map">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3886.6143837830923!2d80.19310057398044!3d13.060199912931836!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a5266b6e31bec61%3A0x3279e4cbf4f17af3!2sUS%20Global%20Solutions!5e0!3m2!1sen!2sin!4v1688541507709!5m2!1sen!2sin"
            width="100%"
            height="350"
            style={{ border: 0 }}
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </div> */}
        <br />
        <form onSubmit={formik.handleSubmit}>
          <div className="contactusform">
            <div className="contactusformdetails">
              <div
                className="detailscontact"
                style={{ backgroundColor: "#EAD0FF" }}
              >
                {/* <div>
                    <LocationOnIcon
                      sx={{
                        fontSize: "25px",
                        border: "1px solid #ffffff",
                        padding: "7px",
                        borderRadius: "50%",
                      }}
                    />
                  </div> */}
                {/* <div>
                    <h4>{t("Location")}:</h4>
                    <p>{t("address")}</p>
                  </div> */}

                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3886.6143837830923!2d80.19310057398044!3d13.060199912931836!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a5266b6e31bec61%3A0x3279e4cbf4f17af3!2sUS%20Global%20Solutions!5e0!3m2!1sen!2sin!4v1688541507709!5m2!1sen!2sin"
                  width="100%"
                  height="350"
                  style={{ border: 0 }}
                  allowfullscreen=""
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
                ></iframe>
                <Stack
                  direction="row"
                  spacing={2}
                  sx={{ paddingTop: "7px", mt: 2 }}
                >
                  <IconButton>
                    {" "}
                    <SmartphoneIcon
                      sx={{
                        fontSize: isMobile ? "20px" : "25px",
                        padding: "7px",
                        borderRadius: "50%",
                        border: "1px solid #7C00DE",
                        color: "#7C00DE",
                        marginTop: "-14px",
                      }}
                    />
                  </IconButton>
                  <p
                    style={{
                      fontFamily: "Poppins-Medium",
                      fontSize: isMobile ? "14px" : "16px",
                      fontWeight: "bold",
                    }}
                  >
                    +91 9080400330
                  </p>
                </Stack>
                <Stack direction="row" spacing={2} sx={{ paddingTop: "9px" }}>
                  <IconButton>
                    <EmailIcon
                      sx={{
                        fontSize: isMobile ? "20px" : "25px",
                        padding: "7px",
                        borderRadius: "50%",
                        border: "1px solid #7C00DE",
                        color: "#7C00DE",
                        marginTop: "-14px",
                      }}
                    />
                  </IconButton>
                  <p
                    style={{
                      fontFamily: "Poppins-Medium",
                      fontSize: isMobile ? "14px" : "16px",
                      fontWeight: "bold",
                    }}
                  >
                    contactus@usglobalsolutions.com
                  </p>
                </Stack>
                <Stack direction="row" spacing={2} sx={{ paddingTop: "7px" }}>
                  <IconButton>
                    <LocationOnIcon
                      sx={{
                        fontSize: isMobile ? "20px" : "25px",
                        padding: "7px",
                        borderRadius: "50%",
                        border: "1px solid #7C00DE",
                        color: "#7C00DE",
                        marginTop: "-14px",
                      }}
                    />
                  </IconButton>
                  <p
                    style={{
                      fontFamily: "Poppins-Medium",
                      fontSize: isMobile ? "14px" : "16px",
                      fontWeight: "bold",
                    }}
                  >
                    #33/13, Second floor, Kaliamman Koil Street, SBI/Canara bank
                    building, Chinmaya Nagar, Chennai, Tamil Nadu - 600092
                  </p>
                </Stack>

                {/* <div className="contacticonbox">
                  <div>
                    <EmailIcon
                      sx={{
                        fontSize: "25px",
                        border: "1px solid #ffffff",
                        padding: "7px",
                        borderRadius: "50%",
                      }}
                    />
                  </div>
                  <div>
                    <h4>{t("Email")}:</h4>
                    <p>contactus@usglobalsolutions.com</p>
                  </div>
                </div> */}
                {/* <div className="contacticonbox">
                  <div>
                    <SmartphoneIcon
                      sx={{
                        fontSize: "25px",
                        border: "1px solid #ffffff",
                        padding: "7px",
                        borderRadius: "50%",
                      }}
                    />
                  </div>
                  <div>
                    <h4>{t("call")}:</h4>
                    <p>+91 9080400330</p>
                  </div>
                </div> */}
                {/* <div className="contacticonbox">
                  <div>
                    <AccessTimeIcon
                      sx={{
                        fontSize: "25px",
                        border: "1px solid #ffffff",
                        padding: "7px",
                        borderRadius: "50%",
                      }}
                    />
                  </div>
                  <div>
                    <h4>{t("open_hours")}:</h4>
                    <p>{t("Mon_Sat")}</p>
                  </div>
                </div> */}
              </div>
              <div className="inputsforcontactdetails">
                <div className="centerpace">
                  <div>
                    <Typography
                      sx={{
                        fontFamily: "Poppins-Medium",
                        fontSize: isMobile ? "30px" : "52px",
                      }}
                    >
                      <span style={{ borderBottom: " 4px solid #A52A2A" }}>
                        Get
                      </span>{" "}
                      in touch
                    </Typography>
                    {/* <Box
                      sx={{
                        width: "100px",
                        height: "4px",
                        backgroundColor: "#A52A2A",
                        marginTop: "-14px",
                      }}
                    /> */}
                  </div>
                  <div className="spacet0p">
                    <TextField
                      id="outlined-basic"
                      label={t("your_name")}
                      variant="outlined"
                      name="full_name"
                      error={
                        formik.touched.full_name && formik.errors.full_name
                      }
                      onBlur={formik.handleBlur}
                      onChange={(e) => {
                        if (e) {
                          const name = e.target.value.replace(
                            /[^a-z'. ]/gi,
                            ""
                          );
                          formik.setFieldValue("full_name", name);
                        }
                      }}
                      value={formik.values.full_name}
                      helperText={
                        formik.touched.full_name && formik.errors.full_name
                          ? formik.errors.full_name
                          : null
                      }
                      fullWidth
                    />
                  </div>
                  <div className="spaced-1-1">
                    <TextField
                      id="filled-basic"
                      label={t("Email")}
                      variant="outlined"
                      error={
                        formik.touched.email_id &&
                        formik.errors.email_id &&
                        focusEmail
                      }
                      name="email_id"
                      onBlur={formik.handleBlur}
                      onBlurCapture={() => {
                        setFocusEmail(true);
                      }}
                      onChange={(e) => {
                        setFocusEmail(false);
                        const value = e.target.value.replace(
                          /[^a-z0-9@.]/gi,
                          ""
                        );
                        formik.setFieldValue("email_id", value);
                      }}
                      value={formik.values.email_id}
                      helperText={
                        formik.touched.email_id &&
                        formik.errors.email_id &&
                        focusEmail
                          ? formik.errors.email_id
                          : null
                      }
                      fullWidth
                    />
                  </div>

                  <div className="space-2" style={{ paddingTop: "20px" }}>
                    <TextField
                      id="outlined-multiline-static"
                      label={t("message")}
                      multiline
                      error={formik.touched.message && formik.errors.message}
                      name="message"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.message}
                      fullWidth
                      helperText={
                        formik.touched.message && formik.errors.message
                          ? formik.errors.message
                          : null
                      }
                      style={{
                        color:
                          formik.touched.message && formik.errors.message
                            ? "red"
                            : "inherit",
                      }}
                      rows={5}
                    />
                  </div>
                  <div className="space-3">
                    <Button
                      sx={{
                        backgroundColor: "#7C00DE",
                        "&:hover": {
                          backgroundColor: "#7C00DE",
                        },
                      }}
                      variant="contained"
                      type="submit"
                      disabled={formik.isValid && formik.dirty ? false : true}
                    >
                      {t("send_message")}
                    </Button>
                  </div>
                  <div
                    className={
                      open ? "backsideofcenterpace" : "backsideofcenterpace-1"
                    }
                  >
                    <div class="backsideofcenterpace-text">
                      <text
                        style={{
                          padding: "10px",
                          fontFamily: "Barlow-Bold",
                          fontWeight: "600",
                          color: "black",
                          fontSize: "25px",
                          textAlign: "center  ",
                        }}
                      >
                        {t("Thanks_for_contacting_us")}
                        <br></br>
                        {t("We_will_be_in_touch_with_you_shortly")}
                      </text>
                      <Button
                        sx={{
                          bgcolor: "#7C00DE",
                          "&:hover": {
                            backgroundColor: "#7C00DE",
                          },
                        }}
                        variant="contained"
                        onClick={() => {
                          setopen(false);
                        }}
                      >
                        {t("ok")}
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  } else {
    return (
      <div
        style={{
          height: "100vh",
          display: "grid",
          placeItems: "center",
          // transform: "translate(0,50vh)",
        }}
      >
        <div class="dots"></div>
      </div>
    );
  }
};

export default LandingpageContactus;
