import React from "react";
import { Card, Typography, useMediaQuery, Stack, Divider } from "@mui/material";
import google from "../../../img/googleicon.png";

const jobData = [
  {
    title: "UI/UX Designer",
    company: "Google",
    location: "Bangalore, Karnataka, India",
    salary: "$1000 - $2000",
    timeApplied: "Applied 2 hours ago",
    image: google,
  },
  {
    title: "UI/UX Designer",
    company: "Google",
    location: "Bangalore, Karnataka, India",
    salary: "$1000 - $2000",
    timeApplied: "Applied 2 hours ago",
    image: google,
  },
  {
    title: "UI/UX Designer",
    company: "Google",
    location: "Bangalore, Karnataka, India",
    salary: "$1000 - $2000",
    timeApplied: "Applied 2 hours ago",
    image: google,
  },
  {
    title: "UI/UX Designer",
    company: "Google",
    location: "Bangalore, Karnataka, India",
    salary: "$1000 - $2000",
    timeApplied: "Applied 2 hours ago",
    image: google,
  },
];

const NewAppliedJobs = () => {
  const isMobile = useMediaQuery("(max-width:600px)");

  return (
    <div style={{ paddingTop: isMobile ? "6.5rem" : "6.5rem" }}>
      <div
        style={{
          paddingRight: isMobile ? "10px" : "150px",
          paddingLeft: isMobile ? "10px" : "150px",
        }}
      >
        <Card>
          <div style={{ justifyContent: "center", textAlign: "center" }}>
            <Typography
              sx={{
                font: "Epilogue",
                fontSize: "24px",
                fontWeight: "600",
                lineHeight: "38.4px",
              }}
            >
              Applied jobs
            </Typography>
          </div>
          <div
            style={{
              padding: isMobile ? "0px" : "80px 80px 0 80px",
              paddingTop: isMobile ? "20px" : "0px",
            }}
          >
            {jobData.map((job, index) => (
              <div key={index}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: isMobile ? "row" : "row",
                    gap: "15px",
                    marginBottom: "20px",
                  }}
                >
                  <div>
                    <img src={job.image} alt={job.company} />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <Stack direction="column" style={{ flexGrow: 1 }}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <Stack direction="column">
                          <Typography
                            style={{
                              fontFamily: "Epilogue",
                              fontSize: "18px",
                              fontWeight: "600",
                              color: "#25324B",
                            }}
                          >
                            {job.title}
                          </Typography>
                          <Typography
                            style={{
                              fontFamily: "Epilogue",
                              fontSize: "16px",
                              fontWeight: "500",
                              color: "#25324B",
                            }}
                          >
                            {job.company}
                          </Typography>
                          <Typography
                            style={{
                              fontFamily: "Epilogue",
                              fontSize: "14px",
                              fontWeight: "400",
                              color: "#454545",
                            }}
                          >
                            {job.location}
                          </Typography>
                          <Typography
                            style={{
                              fontFamily: "Inter",
                              fontSize: "14px",
                              fontWeight: "600",
                              color: "#000000",
                            }}
                          >
                            {job.salary}
                          </Typography>
                        </Stack>
                        <Typography
                          style={{
                            fontFamily: "Inter",
                            fontSize: "18px",
                            fontWeight: "400",
                            color: "#454545",
                          }}
                        >
                          {job.timeApplied}
                        </Typography>
                      </div>
                    </Stack>
                  </div>
                </div>
                {index < jobData.length - 1 && (
                  <Divider style={{ margin: "20px 0" }} />
                )}
              </div>
            ))}
          </div>
        </Card>
      </div>
    </div>
  );
};

export default NewAppliedJobs;
