import React, { useState } from "react";
import newcareerlogo from "../../../img/newcareerlogo.png";
import newemployer from "../../../img/newemployer.png";
import BusinessCenterOutlinedIcon from "@mui/icons-material/BusinessCenterOutlined";
import BusinessOutlinedIcon from "@mui/icons-material/BusinessOutlined";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import "../../login/Login.css";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  Button,
  FormControl,
  TextField,
  Tooltip,
  Typography,
  Card,
  MenuItem,
  Select,
  InputAdornment,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
} from "@mui/material";
import { Link } from "react-router-dom";
import { Stack } from "@mui/system";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useFormik } from "formik";
import * as Yup from "yup";
import { FormHelperText } from "@mui/material-next";
import { useNavigate } from "react-router-dom";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import { ROUTINGDATA } from "../../ROUTINGDATA";
import LoadingButton from "../../LoadingButton";

const NewSignup = () => {
  const navigate = useNavigate();
  const [selectValue, setSelectValue] = React.useState("Employers");
  const [showPassword, setShowPassword] = useState(false);
  const [showConPassword, setShowConPassword] = useState(false);
  const [open, setOpen] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const handleClickShowPassword = () => {
    setShowPassword((prev) => !prev);
  };

  const handleClickShowConPassword = () => {
    setShowConPassword((prev) => !prev);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleSelectChange = (event) => {
    setSelectValue(event.target.value);
    if (event.target.value === "JobSeeker") {
      navigate("/JobSeekerSignUp");
    }
  };
  const validationSchema = Yup.object({
    fullName: Yup.string().required("Full Name is required"),
    userName: Yup.string().required("User Name is required"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    password: Yup.string()
      .min(8, "Password should be of minimum 8 characters length")
      .required("Password is required"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Confirm Password is required"),
    terms: Yup.boolean()
      .required("You must accept the terms and conditions")
      .oneOf([true], "You must accept the terms and conditions"),
  });

  const formik = useFormik({
    initialValues: {
      fullName: "",
      userName: "",
      email: "",
      password: "",
      confirmPassword: "",
      terms: false,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setButtonLoading(true);
      console.log(values);
      setOpen(true);
      setButtonLoading(false);
    },
  });
  const handleClose = () => {
    setOpen(false);
    navigate("/");
  };

  return (
    <>
      <div
        style={{
          height: "100vh",
          width: "100%",
          display: "flex",
          flexDirection: "row",
          overflow: "hidden",
        }}
      >
        <div
          className="first-div"
          style={{
            height: "100vh",
            width: "50%",
            padding: "80px",
            paddingTop: "27px",
            overflowY: "auto",
          }}
        >
          <Link to="/">
            <IconButton>
              <ArrowBackIcon />
            </IconButton>
          </Link>
          <div
            style={{
              paddingTop: "10px",
              flexDirection: "row",
            }}
          >
            <img src={newcareerlogo} alt="logo" />
            <div
              style={{ display: "flex", flexDirection: "row", width: "90%" }}
            >
              <div>
                <Typography
                  style={{
                    fontFamily: "Inter",
                    fontSize: "27.78px",
                    fontWeight: "500",
                    lineHeight: "34.72px",
                    paddingTop: "20px",
                  }}
                >
                  Create account
                </Typography>
                <Typography
                  style={{
                    fontFamily: "Inter",
                    fontSize: "13.89px",
                    fontWeight: "400",
                    lineHeight: "20.83px",
                    color: "#5E6670",
                    paddingTop: "10px",
                  }}
                >
                  Already have an account?
                  <Link
                    to="/Login"
                    style={{
                      color: "#0A65CC",
                      fontFamily: "Inter",
                      fontWeight: "500",
                      paddingLeft: "3px",
                    }}
                  >
                    Log In
                  </Link>
                </Typography>
              </div>

              <div style={{ marginLeft: "auto" }}>
                <FormControl
                  variant="outlined"
                  style={{ width: "160%", paddingTop: "30px" }}
                >
                  <Select
                    size="small"
                    value={selectValue}
                    onChange={handleSelectChange}
                    displayEmpty
                    sx={{
                      fontFamily: "Inter",
                      fontSize: "15.17px",
                      fontWeight: 400,
                      lineHeight: "17.39px",
                      textAlign: "left",
                    }}
                  >
                    <MenuItem value="Employers">Employer</MenuItem>
                    <MenuItem value="JobSeeker">Job Seeker</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </div>

            <br />
            <form
              onSubmit={formik.handleSubmit}
              style={{
                height: "85vh",
                overflowY: "auto",
              }}
            >
              <Stack direction="row" spacing={4}>
                <FormControl style={{ width: "50%" }}>
                  <Tooltip title="Enter your Full Name" arrow>
                    <TextField
                      id="fullName"
                      variant="outlined"
                      placeholder="Full Name"
                      name="fullName"
                      value={formik.values.fullName}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                  </Tooltip>
                  <FormHelperText>
                    {formik.touched.fullName && formik.errors.fullName ? (
                      <Typography sx={{ color: "red", fontSize: "13px" }}>
                        {formik.errors.fullName}
                      </Typography>
                    ) : (
                      <Typography
                        sx={{ fontSize: "13px", visibility: "hidden" }}
                      >
                        none
                      </Typography>
                    )}
                  </FormHelperText>
                </FormControl>

                <FormControl style={{ width: "50%" }}>
                  <Tooltip title="Enter your User Name" arrow>
                    <TextField
                      id="userName"
                      variant="outlined"
                      placeholder="User Name"
                      name="userName"
                      value={formik.values.userName}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                  </Tooltip>
                  <FormHelperText>
                    {formik.touched.userName && formik.errors.userName ? (
                      <Typography sx={{ color: "red", fontSize: "13px" }}>
                        {formik.errors.userName}
                      </Typography>
                    ) : (
                      <Typography
                        sx={{ fontSize: "13px", visibility: "hidden" }}
                      >
                        none
                      </Typography>
                    )}
                  </FormHelperText>
                </FormControl>
              </Stack>
              <Tooltip title="Enter your Email Address" arrow>
                <TextField
                  style={{ paddingTop: "5px" }}
                  id="email"
                  placeholder="Email address"
                  variant="outlined"
                  name="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  fullWidth
                />
              </Tooltip>
              <FormHelperText>
                {formik.touched.email && formik.errors.email ? (
                  <Typography sx={{ color: "red", fontSize: "13px" }}>
                    {formik.errors.email}
                  </Typography>
                ) : (
                  <Typography sx={{ fontSize: "13px", visibility: "hidden" }}>
                    none
                  </Typography>
                )}
              </FormHelperText>
              <Tooltip title="Enter your Password" arrow>
                <TextField
                  type={showPassword ? "text" : "password"}
                  id="password"
                  placeholder="Password"
                  variant="outlined"
                  name="password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Tooltip>
              <FormHelperText>
                {formik.touched.password && formik.errors.password ? (
                  <Typography sx={{ color: "red", fontSize: "13px" }}>
                    {formik.errors.password}
                  </Typography>
                ) : (
                  <Typography sx={{ fontSize: "13px", visibility: "hidden" }}>
                    none
                  </Typography>
                )}
              </FormHelperText>
              <Tooltip title="Re-enter your Password" arrow>
                <TextField
                  type={showConPassword ? "text" : "password"}
                  id="confirmPassword"
                  placeholder="Confirm Password"
                  variant="outlined"
                  name="confirmPassword"
                  value={formik.values.confirmPassword}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleClickShowConPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showConPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Tooltip>
              <FormHelperText>
                {formik.touched.confirmPassword &&
                formik.errors.confirmPassword ? (
                  <Typography sx={{ color: "red", fontSize: "13px" }}>
                    {formik.errors.confirmPassword}
                  </Typography>
                ) : (
                  <Typography sx={{ fontSize: "13px", visibility: "hidden" }}>
                    none
                  </Typography>
                )}
              </FormHelperText>
              <div
                style={{
                  display: "flex",

                  marginTop: "-13px",
                  flexDirection: "column",
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Checkbox
                    name="terms"
                    checked={formik.values.terms}
                    onChange={formik.handleChange}
                  />
                  <p>
                    I've read and agree with your{" "}
                    <span
                      style={{
                        font: "Inter",
                        fontSize: "16px",
                        color: "#0A65CC",
                        cursor: "pointer",
                        fontWeight: "500",
                        lineHeight: "17.36px",
                      }}
                      onClick={() => {
                        navigate(`/${ROUTINGDATA.TERMSANDCONDITION}`);
                        window.scrollTo(0, 0);
                      }}
                    >
                      {" "}
                      Terms of Services
                    </span>
                  </p>
                </div>

                <FormHelperText>
                  {formik.touched.terms && formik.errors.terms ? (
                    <Typography
                      sx={{
                        color: "red",
                        fontSize: "13px",
                        marginTop: "-10px",
                      }}
                    >
                      {formik.errors.terms}
                    </Typography>
                  ) : (
                    <Typography sx={{ fontSize: "13px", visibility: "hidden" }}>
                      none
                    </Typography>
                  )}
                </FormHelperText>
              </div>

              <div>
                <Tooltip title="Enter your details after then Create account Successfully">
                  <Button
                    style={{
                      backgroundColor: "#7C00DE",
                      color: "white",
                      textTransform: "capitalize",
                      height: "48.78px",
                      width: "100%",
                    }}
                    fullWidth
                    type="submit"
                  >
                    Create account → <LoadingButton loading={buttonLoading} />
                  </Button>
                </Tooltip>
              </div>
            </form>
          </div>
        </div>

        <div
          className="second-div"
          style={{
            height: "100vh",
            width: "50%",
            position: "relative",
            overflow: "hidden",
          }}
        >
          <div
            style={{
              height: "100%",
              width: "100%",
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              overflow: "hidden",
            }}
          >
            <img
              src={newemployer}
              alt="login"
              style={{
                height: "110%",
                width: "110%",
                objectFit: "cover",
                transform: "rotate(4deg)",
                position: "absolute",
                top: "-5%",
                right: "-12%",
                zIndex: 1,
              }}
            />
            <div
              style={{
                height: "100%",
                width: "100%",
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                background:
                  "linear-gradient(180deg, rgba(122, 96, 142, 0.345) 0%, rgba(59, 7, 100, 0.69) 100%)",
                zIndex: 2,
                pointerEvents: "none",
              }}
            />
          </div>
          <div
            style={{
              position: "absolute",
              bottom: "20%",
              left: "10%",
              color: "white",
              fontSize: "1.5em",
              fontWeight: "bold",
              textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)",
              zIndex: 3,
            }}
          >
            <Typography
              style={{
                font: "Inter",
                fontSize: "30px",
                fontWeight: "500",
                lineHeight: "36px",
              }}
            >
              Over 1,75,324 candidates
            </Typography>
            <Typography
              style={{
                font: "Inter",
                fontSize: "30px",
                fontWeight: "500",
                lineHeight: "36px",
              }}
            >
              waiting for good employers.
            </Typography>
            <div
              style={{
                display: "flex",
                gap: "50px", // Space between the cards
                marginTop: "20px", // Space between text and cards
                alignItems: "center",
              }}
            >
              <div style={{ textAlign: "center" }}>
                <Card
                  style={{
                    backgroundColor: "rgba(255, 255, 255, 0.2)",
                    color: "white",
                    width: "60px",
                    height: "48px",
                    padding: "12px 0px 0px 0px",
                    borderRadius: "6px",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <BusinessCenterOutlinedIcon style={{ fontSize: "40px" }} />
                </Card>
                <Typography
                  style={{
                    font: "Inter",
                    fontSize: "15px",
                    fontWeight: "500",
                    lineHeight: "16px",
                    marginTop: "8px",
                    color: "white",
                  }}
                >
                  1,75,324
                </Typography>
                <Typography
                  style={{
                    font: "Inter",
                    fontSize: "15px",
                    fontWeight: "500",
                    lineHeight: "16px",
                    marginTop: "8px",
                    color: "white",
                  }}
                >
                  Live Jobs
                </Typography>
              </div>
              <div style={{ textAlign: "center" }}>
                <Card
                  style={{
                    backgroundColor: "rgba(255, 255, 255, 0.2)",
                    color: "white",
                    width: "60px",
                    height: "48px",
                    padding: "12px 0px 0px 0px",
                    borderRadius: "6px",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <BusinessOutlinedIcon style={{ fontSize: "40px" }} />
                </Card>
                <Typography
                  style={{
                    font: "Inter",
                    fontSize: "12px",
                    fontWeight: "500",
                    lineHeight: "16px",
                    marginTop: "8px",
                    color: "white",
                  }}
                >
                  97,354
                </Typography>
                <Typography
                  style={{
                    font: "Inter",
                    fontSize: "15px",
                    fontWeight: "500",
                    lineHeight: "16px",
                    marginTop: "8px",
                    color: "white",
                  }}
                >
                  Companies
                </Typography>
              </div>
              <div style={{ textAlign: "center" }}>
                <Card
                  style={{
                    backgroundColor: "rgba(255, 255, 255, 0.2)",
                    color: "white",
                    width: "60px",
                    height: "48px",
                    padding: "12px 0px 0px 0px",
                    borderRadius: "6px",

                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <BusinessCenterOutlinedIcon style={{ fontSize: "40px" }} />
                </Card>
                <Typography
                  style={{
                    font: "Inter",
                    fontSize: "12px",
                    fontWeight: "500",
                    lineHeight: "16px",
                    marginTop: "8px",
                    color: "white",
                  }}
                >
                  7,532
                </Typography>
                <Typography
                  style={{
                    font: "Inter",
                    fontSize: "15px",
                    fontWeight: "500",
                    lineHeight: "16px",
                    marginTop: "8px",
                    color: "white",
                  }}
                >
                  New Jobs
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",

              position: "relative",
              borderRadius: "16px",
              textAlign: "center",
              alignItems: "center",
            },
          },
        }}
      >
        <TaskAltIcon
          sx={{ fontSize: "70px", paddingTop: "25px", color: "#27AE60" }}
        />
        <DialogContent>
          <Typography
            style={{
              font0: "Roboto",
              fontSize: "30.43px",
              fontWeight: "700",
              lineHeight: "50.9px",
              color: "#27AE60",
            }}
          >
            Account Created Successfully!
          </Typography>
          <Typography
            style={{
              font0: "Roboto",
              fontSize: "25.43px",
              fontWeight: "600",
              lineHeight: "50.9px",
              color: "#4A4A4A",
            }}
          >
            Welcome to our Career365 portal!
          </Typography>
          <Typography
            style={{
              font0: "Roboto",
              fontSize: "22.43px",
              fontWeight: "300",
              color: "#4A4A4A",
            }}
          >
            Discover top talent and build your dream team. Start exploring the
            best candidates to drive your business forward.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            style={{
              backgroundColor: "#27AE60",
              color: "white",
              textTransform: "capitalize",
              height: "48.78px",
              paddingRight: "40px",
              paddingLeft: "40px",
              font: "Roboto",
              fontWeight: "600",
              fontSize: "23px",
            }}
            type="submit"
          >
            Continue
          </Button>
        </DialogActions>
        <br />
      </Dialog>
    </>
  );
};

export default NewSignup;
