import React, { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  Stepper,
  Step,
  StepLabel,
  Button,
  TextField,
  FormControl,
  Autocomplete,
  FormHelperText,
  InputAdornment,
  Box,
  Grid,
  IconButton,
} from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import { styled } from "@mui/material/styles";
import { FormLabel } from "@mui/material-next";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import WorkOutlineOutlinedIcon from "@mui/icons-material/WorkOutlineOutlined";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";

const steps = ["General Info", "Job Detail", "Resume"];

const validationSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email format").required("Required"),
  phone: Yup.string()
    .matches(/^[0-9]{10}$/, "Must be a 10-digit phone number")
    .required("Required"),
  preferredJob: Yup.string().required("Required"),
  experience: Yup.number().required("Required"),
  Skills: Yup.string().required("Required"),
  CurrentCTC: Yup.string().required("Required"),
  ExpectedCTC: Yup.string().required("Required"),
  availability: Yup.string().required("Required"),
  CvName: Yup.string().required("Required"),
  file: Yup.mixed()
    .required("A file is required")
    .test(
      "fileSize",
      "File too large",
      (value) => value && value.size <= 2 * 1024 * 1024
    ) // 2 MB
    .test(
      "fileFormat",
      "Unsupported Format",
      (value) => value && ["application/pdf"].includes(value.type)
    ),
});

const CustomStepper = styled(Stepper)(({ theme }) => ({
  padding: "24px 0",
  "& .MuiStep-root": {
    "& .MuiStepLabel-root": {
      backgroundColor: "white",
      "& .MuiStepIcon-root": {
        color: "#C0C0C0", // Default icon color
        "&.Mui-active": {
          color: "#7C00DE", // Active step icon color
          boxShadow: "0px 0px 4px 4px rgba(124, 0, 222, 0.3)",
          border: "2px solid #7C00DE",
          borderRadius: "50%",
          backgroundColor: "white",
        },
        "&.Mui-completed": {
          color: "#7C00DE", // Completed step icon color
          boxShadow: "0px 0px 4px 4px rgba(124, 0, 222, 0.3)",
          border: "2px solid #7C00DE",
          borderRadius: "50%",
        },
      },
      "& .MuiStepLabel-label": {
        color: "#000000", // Label color
        "&.Mui-active": {
          color: "#000000",
          fontWeight: "bold",
        },
        "&.Mui-completed": {
          color: "#000000",
          fontWeight: "bold",
        },
      },
    },
  },
}));

const JobsUpdatePopup = ({ open, onClose }) => {
  const [activeStep, setActiveStep] = useState(0);

  const formik = useFormik({
    initialValues: {
      email: "",
      phone: "",
      preferredJob: "",
      experience: "",
      Skills: "",
      CurrentCTC: "",
      ExpectedCTC: "",
      availability: "",
      CvName: "",
      file: null,
    },
    validationSchema,
    onSubmit: (values) => {
      alert(JSON.stringify(values, null, 2));
    },
  });

  const handleFileChange = (event) => {
    const file = event.currentTarget.files[0];
    formik.setFieldValue("file", file);
  };

  const handleNext = () => {
    if (activeStep < steps.length - 1) {
      setActiveStep(activeStep + 1);
    }
  };

  const handleBack = () => {
    if (activeStep > 0) {
      setActiveStep(activeStep - 1);
    }
  };

  const experienceOptions = [
    { label: "1 Year", value: 1 },
    { label: "2 Years", value: 2 },
    { label: "3 Years", value: 3 },
    // Add more options as needed
  ];

  const renderStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <>
            <FormControl fullWidth sx={{ mt: 2 }}>
              <FormLabel sx={{ color: "black" }}>
                Email<span style={{ color: "red" }}>*</span>
              </FormLabel>
              <TextField
                name="email"
                type="email"
                size="small"
                placeholder="Email"
                fullWidth
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
                required
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <EmailOutlinedIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </FormControl>
            <FormControl fullWidth sx={{ mt: 2 }}>
              <FormLabel sx={{ color: "black" }}>
                Phone<span style={{ color: "red" }}>*</span>
              </FormLabel>
              <TextField
                size="small"
                name="phone"
                type="tel"
                fullWidth
                placeholder="Phone"
                value={formik.values.phone}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.phone && Boolean(formik.errors.phone)}
                helperText={formik.touched.phone && formik.errors.phone}
                required
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LocalPhoneOutlinedIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </FormControl>
            <FormControl fullWidth sx={{ mt: 2 }}>
              <FormLabel sx={{ color: "black" }}>
                Preferred Job<span style={{ color: "red" }}>*</span>
              </FormLabel>
              <Autocomplete
                size="small"
                fullWidth
                options={[]} // Add your preferred job options here
                freeSolo
                inputValue={formik.values.preferredJob}
                onInputChange={(event, newValue) => {
                  formik.setFieldValue("preferredJob", newValue);
                }}
                onBlur={formik.handleBlur}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Preferred Job"
                    error={
                      formik.touched.preferredJob &&
                      Boolean(formik.errors.preferredJob)
                    }
                    helperText={
                      formik.touched.preferredJob && formik.errors.preferredJob
                    }
                    required
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <WorkOutlineOutlinedIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </FormControl>
            <FormControl fullWidth sx={{ mt: 2 }}>
              <FormLabel sx={{ color: "black" }}>
                Experience<span style={{ color: "red" }}>*</span>
              </FormLabel>
              <Autocomplete
                size="small"
                options={experienceOptions}
                getOptionLabel={(option) => option.label}
                value={
                  experienceOptions.find(
                    (option) => option.value === formik.values.experience
                  ) || null
                }
                onChange={(event, newValue) => {
                  formik.setFieldValue(
                    "experience",
                    newValue ? newValue.value : null
                  );
                }}
                onBlur={formik.handleBlur}
                renderInput={(params) => (
                  <TextField
                    size="small"
                    {...params}
                    placeholder="Experience"
                    error={
                      formik.touched.experience &&
                      Boolean(formik.errors.experience)
                    }
                    helperText={
                      formik.touched.experience && formik.errors.experience
                    }
                  />
                )}
              />
              {formik.touched.experience && formik.errors.experience && (
                <FormHelperText>{formik.errors.experience}</FormHelperText>
              )}
            </FormControl>
          </>
        );
      case 1:
        return (
          <>
            <FormControl fullWidth sx={{ mt: 2 }}>
              <FormLabel sx={{ color: "black" }}>
                Preferred Job<span style={{ color: "red" }}>*</span>
              </FormLabel>
              <Autocomplete
                size="small"
                fullWidth
                options={[]} // Add your preferred job options here
                freeSolo
                inputValue={formik.values.preferredJob}
                onInputChange={(event, newValue) => {
                  formik.setFieldValue("preferredJob", newValue);
                }}
                onBlur={formik.handleBlur}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Preferred Job"
                    error={
                      formik.touched.preferredJob &&
                      Boolean(formik.errors.preferredJob)
                    }
                    helperText={
                      formik.touched.preferredJob && formik.errors.preferredJob
                    }
                    required
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <WorkOutlineOutlinedIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </FormControl>
            <FormControl fullWidth sx={{ mt: 2 }}>
              <FormLabel sx={{ color: "black" }}>
                Skills<span style={{ color: "red" }}>*</span>
              </FormLabel>
              <TextField
                name="Skills"
                type="Skills"
                size="small"
                placeholder="Skills"
                fullWidth
                value={formik.values.Skills}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.Skills && Boolean(formik.errors.Skills)}
                helperText={formik.touched.Skills && formik.errors.Skills}
                required
              />
            </FormControl>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth sx={{ mt: 2 }}>
                  <FormLabel sx={{ color: "black" }}>
                    Current CTC<span style={{ color: "red" }}>*</span>
                  </FormLabel>
                  <TextField
                    size="small"
                    name="CurrentCTC"
                    type="text"
                    fullWidth
                    placeholder="Current CTC"
                    value={formik.values.CurrentCTC}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.CurrentCTC &&
                      Boolean(formik.errors.CurrentCTC)
                    }
                    helperText={
                      formik.touched.CurrentCTC && formik.errors.CurrentCTC
                    }
                    required
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth sx={{ mt: 2 }}>
                  <FormLabel sx={{ color: "black" }}>
                    Expected CTC<span style={{ color: "red" }}>*</span>
                  </FormLabel>
                  <TextField
                    size="small"
                    name="ExpectedCTC"
                    type="text"
                    fullWidth
                    placeholder="Expected CTC"
                    value={formik.values.ExpectedCTC}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.ExpectedCTC &&
                      Boolean(formik.errors.ExpectedCTC)
                    }
                    helperText={
                      formik.touched.ExpectedCTC && formik.errors.ExpectedCTC
                    }
                    required
                  />
                </FormControl>
              </Grid>
            </Grid>
            <FormControl sx={{ mt: 2 }}>
              <FormLabel id="demo-radio-buttons-group-label">
                Availability<span style={{ color: "red" }}>*</span>
              </FormLabel>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="female"
                name="radio-buttons-group"
              >
                <FormControlLabel
                  value="Immediate"
                  control={
                    <Radio sx={{ "&.Mui-checked": { color: "#7C00DE" } }} />
                  }
                  label="Immediate"
                />
                <FormControlLabel
                  value="15 Days"
                  control={
                    <Radio sx={{ "&.Mui-checked": { color: "#7C00DE" } }} />
                  }
                  label="15 Days"
                />
                <FormControlLabel
                  value="1 month"
                  control={
                    <Radio sx={{ "&.Mui-checked": { color: "#7C00DE" } }} />
                  }
                  label="1 month"
                />
              </RadioGroup>
            </FormControl>
          </>
        );
      case 2:
        return (
          <>
            <FormControl fullWidth sx={{ mt: 2 }}>
              <FormLabel sx={{ color: "black" }}>
                Cv/Resume Name<span style={{ color: "red" }}>*</span>
              </FormLabel>
              <TextField
                name="CvName"
                type="CvName"
                size="small"
                placeholder="Cv/Resume Name"
                fullWidth
                value={formik.values.CvName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.CvName && Boolean(formik.errors.CvName)}
                helperText={formik.touched.CvName && formik.errors.CvName}
                required
              />
            </FormControl>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                textAlign: "center",
                marginTop: "2rem",
                border: "2px dashed #C8CCD1",
                padding: "1rem",
              }}
            >
              <div>
                <input
                  accept=".pdf"
                  id="file-upload"
                  type="file"
                  style={{ display: "none" }}
                  onChange={handleFileChange}
                />
                <label htmlFor="file-upload">
                  <IconButton component="span">
                    <CloudUploadOutlinedIcon sx={{ fontSize: 50 }} />
                  </IconButton>
                </label>
                <p>
                  Browse File{" "}
                  <span style={{ color: "#474C54" }}>or drop here</span>
                </p>
                <text style={{ color: "#5E6670" }}>
                  Only PDF format available. Max file size 12 MB.
                </text>

                {formik.errors.file && formik.touched.file && (
                  <FormHelperText error>{formik.errors.file}</FormHelperText>
                )}
              </div>
            </div>
          </>
        );
      default:
        return <div>Unknown step</div>;
    }
  };

  return (
    <Dialog open={open} fullWidth sx={{ px: 6 }}>
      <DialogContent sx={{ px: 8 }}>
        <CustomStepper activeStep={activeStep} alternativeLabel>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </CustomStepper>
        <form onSubmit={formik.handleSubmit}>
          {renderStepContent(activeStep)}
        </form>
      </DialogContent>
      <DialogActions
        sx={{ px: 8, py: 2, display: "flex", justifyContent: "space-between" }}
      >
        <Box>
          {activeStep !== 0 && (
            <Button
              onClick={handleBack}
              variant="outlined"
              sx={{
                fontWeight: "bold",
                fontSize: "13px",
                backgroundColor: "white",
                textTransform: "capitalize",
                color: "#7C00DE",
                borderColor: "#7C00DE",
                "&:hover": {
                  fontWeight: "bold",
                  textTransform: "capitalize",
                  color: "#7C00DE",
                  backgroundColor: "white",
                  borderColor: "#7C00DE",
                  fontSize: "13px",
                },
              }}
            >
              Back
            </Button>
          )}
        </Box>
        <Box sx={{ display: "flex", gap: 2 }}>
          <Button
            onClick={onClose}
            variant="contained"
            sx={{
              fontWeight: "bold",
              fontSize: "13px",
              backgroundColor: "#E9E9E9",
              textTransform: "capitalize",
              color: "#888282",
              borderColor: "#E9E9E9",
              "&:hover": {
                fontWeight: "bold",
                textTransform: "capitalize",
                color: "#888282",
                backgroundColor: "#E9E9E9",
                borderColor: "#E9E9E9",
                fontSize: "13px",
              },
            }}
          >
            Cancel
          </Button>
          {activeStep < steps.length - 1 ? (
            <Button
              onClick={handleNext}
              variant="contained"
              sx={{
                fontWeight: "bold",
                fontSize: "13px",
                backgroundColor: "#7C00DE",
                textTransform: "capitalize",
                color: "white",
                borderColor: "#7C00DE",
                "&:hover": {
                  fontWeight: "bold",
                  textTransform: "capitalize",
                  color: "white",
                  backgroundColor: "#7C00DE",
                  borderColor: "#7C00DE",
                  fontSize: "13px",
                },
              }}
            >
              Next
            </Button>
          ) : (
            <Button
              type="submit"
              variant="contained"
              sx={{
                fontWeight: "bold",
                fontSize: "13px",
                backgroundColor: "#7C00DE",
                textTransform: "capitalize",
                color: "white",
                borderColor: "#7C00DE",
                "&:hover": {
                  fontWeight: "bold",
                  textTransform: "capitalize",
                  color: "white",
                  backgroundColor: "#7C00DE",
                  borderColor: "#7C00DE",
                  fontSize: "13px",
                },
              }}
            >
              Submit
            </Button>
          )}
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default JobsUpdatePopup;
